import { useState } from "react";
import { DateTime } from "../../../../utils";
import { DayRecordedData } from "../context/MonthRecordsTableContext";

export function useSelectedDay(monthDaysInfo: DayRecordedData[] | undefined){
    const [selectedDay, setSelectedDay] = useState<DayRecordedData | null>(null);
    const selectDay = (date: DateTime) => {
        if(!monthDaysInfo) return;
        const newSelectedDay = monthDaysInfo.find(day => day.date.equalTo(date));
        if(newSelectedDay !== undefined) {
            setSelectedDay(newSelectedDay);
        }
    }
    const resetSelecedDay = () => {
        setSelectedDay(null);
    }

    const selectNextDay = () => {
        if(!monthDaysInfo || monthDaysInfo.length < 1) return;
        if(selectedDay === null) {
            selectDay(monthDaysInfo[0].date);
            return;
        }
        const currIndex = monthDaysInfo.findIndex(day => day.date.equalTo(selectedDay.date));
        if (currIndex === -1 || currIndex === monthDaysInfo.length - 1){
            return;
        }
        const nextRecord = monthDaysInfo[currIndex + 1];
        selectDay(nextRecord.date);
    }

    const selectPrevDay = () => {
        if(!monthDaysInfo || monthDaysInfo.length < 1) return;
        if(selectedDay === null) {
            selectDay(monthDaysInfo[0].date);
            return;
        }
        const currIndex = monthDaysInfo.findIndex(day => day.date.equalTo(selectedDay.date));
        if (currIndex === -1 || currIndex === 0){
            return;
        }
        const nextRecord = monthDaysInfo[currIndex - 1];
        selectDay(nextRecord.date);
    }
    return [selectedDay, selectDay, resetSelecedDay, selectNextDay, selectPrevDay] as const;
}