import { ReactNode, useRef } from "react";
import WidgetCard from "../../../components/WidgetCard";
import Header from "./components/Header";
import SummaryTable from "./components/SummaryTable";
import { SummaryProvider } from "./context/SummaryContext";
import { DateTime } from "../../../utils";
import { Day, Month, Year } from "../../../utils/TimeTypes";
import "./MembersWorktimeSummaryTable.scss";

type MembersWorktimeSummaryTableProps = {
  date: DateTime;
  setDate: (day: DateTime) => void;
};

export function MembersWorktimeSummaryTable({date, setDate}: MembersWorktimeSummaryTableProps) {
  // const MWSummaryProvider = useRef(({ children }: { children: ReactNode }) => (
  //   <SummaryProvider
  //     // member={member}
  //     // setMember={setMember}
  //     date={date}
  //     setDate={setDate}
  //     children={children}
  //   />
  // ));
  const MWSummaryProvider = ({ children }: { children: ReactNode }) => (
    <SummaryProvider
      // member={member}
      // setMember={setMember}
      date={date}
      setDate={setDate}
      children={children}
    />
  );
  return (
    <WidgetCard className="MembersWorktimeSummaryTable" ContextProvider={MWSummaryProvider}>
      <WidgetCard.Header>
        <Header />
      </WidgetCard.Header>
      <WidgetCard.Body>
        <SummaryTable />
      </WidgetCard.Body>
    </WidgetCard>
  );
}
