import { ReactNode, useRef } from "react";
import WidgetCard from "../../../components/WidgetCard";
import { DateTime } from "../../../utils";
import { Day, Month, Year } from "../../../utils/TimeTypes";
import { User } from "../../../utils/Users";
import { DayRecordsProvider } from "./context/DayRecordsTableContext";
import Header from "./components/Header";
import RecordsTable from "./components/RecordsTable";
import "./MemberDayTimeRecordTable.scss";
import ExpandButton from "../../../components/ExpandButton";
import ViewSummaryButton from "../../../components/ViewSummaryButton";
import apiUrls from "../../../data/api.json";
import { createSearchParams, generatePath, To } from "react-router-dom";
import CardFooter from "./components/CardFooter";

type MemberDayTimeRecordTableProps = {
  member: User;
  setMember: (newMember: User) => void;
  date: DateTime;
  setDate?: (date: DateTime) => void;
  onlyMonth?: DateTime;
  embeded?: boolean;
  onContentLoad?: ()=>void;
};

export function MemberDayTimeRecordTable({
  member,
  setMember,
  date,
  setDate,
  onlyMonth,
  embeded = false,
  onContentLoad = ()=>{}
}: MemberDayTimeRecordTableProps) {
  // const DRProvider = useRef(({ children }: { children: ReactNode }) => (
  //   <DayRecordsProvider
  //     member={member}
  //     setMember={setMember}
  //     date={date}
  //     setDate={setDate}
  //     onlyMonth={onlyMonth}
  //     embeded={embeded}
  //     children={children}
  //     onContentLoad={onContentLoad}
  //   />
  // ));
  const DRProvider = ({ children }: { children: ReactNode }) => (
    <DayRecordsProvider
      member={member}
      setMember={setMember}
      date={date}
      setDate={setDate}
      onlyMonth={onlyMonth}
      embeded={embeded}
      children={children}
      onContentLoad={onContentLoad}
    />
  );

  const getDayRecordsLink = (date: DateTime): To => {
    const dayValNum = date.getLocDay();
    const dayVal = dayValNum < 10 ? "0" + dayValNum : "" + dayValNum;
    const monthValNum = date.getLocMonth();
    const monthVal = monthValNum < 10 ? "0" + monthValNum : "" + monthValNum;
    const queryParams = createSearchParams({
      date: dayVal + "." + monthVal + "." + date.getLocYear(),
    }).toString();
    return {
      pathname: generatePath(apiUrls.account.children.admin.children.memberDay, {
        userId: member.id,
      }),
      search: queryParams,
    };
  };

  const getMonthSummaryLink = (date: DateTime): To => {
    const month = date.getStartOfMonth();
    const monthValNum = month.getLocMonth();
    const monthVal = monthValNum.toString().padStart(2, "0");
    const queryParams = createSearchParams({
      month: monthVal + "." + month.getLocYear(),
    }).toString();
    return {
      pathname: generatePath(
        apiUrls.account.children.admin.children.memberMonth,
        {
          userId: member.id,
        }
      ),
      search: queryParams,
    };
  };

  return (
    <WidgetCard
      className="MemberDayTimeRecordTable"
      ContextProvider={DRProvider}
    >
      {(!setDate || embeded) && (
        <WidgetCard.TopExtras>
          <ExpandButton expandLink={getDayRecordsLink(date)} />
        </WidgetCard.TopExtras>
      )}
      {!!setDate && !embeded && (
        <WidgetCard.TopExtras>
          <ViewSummaryButton summaryLink={getMonthSummaryLink(date)} />
        </WidgetCard.TopExtras>
      )}
      <WidgetCard.Header>
        <Header />
      </WidgetCard.Header>
      <WidgetCard.Body>
        <RecordsTable />
      </WidgetCard.Body>
      <WidgetCard.Footer>
        <CardFooter/>
      </WidgetCard.Footer>
    </WidgetCard>
  );
}
