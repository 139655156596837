import React from 'react';
import ReactDOM from 'react-dom/client';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/bootstrapAdjustments.scss';
import './assets/styles/bootstraplike_styles.scss';
import App from './App';
import './index.scss';
import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// var routs = createRoutesFromElements(
//   <Route path="/" element={<div>Hello</div>}>
//     {/* <Route path="dashboard" element={<Dashboard />} /> */}
//     {/* ... etc. */}
//   </Route>
// )

root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

