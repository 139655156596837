import { error } from 'console';
import { values } from "lodash";
import { DateTime } from "../../utils/DateTime";
import { JSONObject, JSONValue } from "../../utils/JsonType";
import {
  FetchFileServerOptions,
  FetchServerOptions,
  RequestMethods,
  fetchServer,
  fetchFileServer
} from "../../utils/fetchFromEndpoint";
import Ajv, { JSONSchemaType, Schema, ValidateFunction } from "ajv";
import { ValidationError } from "../../utils/Errors";


const ajv = new Ajv();
const draft6MetaSchema = require("ajv/dist/refs/json-schema-draft-06.json");
ajv.addMetaSchema(draft6MetaSchema);

export class ServerFetcher<
  DataType extends { [k: string]: any },
  RespType extends JSONObject
> {
  method: RequestMethods;
  endpoint: string;
  validateRequest: ValidateFunction<DataType>;

  constructor(
    method: RequestMethods,
    endpoint: string,
    validateRequest: ValidateFunction<DataType>
  ) {
    this.method = method;
    this.endpoint = endpoint;
    this.validateRequest = validateRequest;
  }
  fetch(data: DataType, options?: FetchServerOptions<RespType>) {
    let dataJSON: JSONObject = JSON.parse(JSON.stringify(data));
    if (!this.validateRequest(dataJSON)) {
      // console.log("Req Not valid");
      throw Error();
    }
    return fetchServer<RespType>(this.method, this.endpoint, dataJSON, options);
  }
}

export class ServerFetcherUnpacked<
  DataType extends { [k: string]: any },
  RespType extends JSONObject,
  UnpackedRespType
> {
  method: RequestMethods;
  endpoint: string;
  validateRequest: ValidateFunction<DataType>;
  unpackResponce: (resp: RespType) => UnpackedRespType;
  validateResponce: ValidateFunction<RespType>;

  constructor(
    method: RequestMethods,
    endpoint: string,
    validateRequest: ValidateFunction<DataType>,
    validateResponce: ValidateFunction<RespType>,
    unpackResponce: (resp: RespType) => UnpackedRespType
  ) {
    this.method = method;
    this.endpoint = endpoint;
    this.validateRequest = validateRequest;
    this.validateResponce = validateResponce;
    this.unpackResponce = unpackResponce;
  }

  async fetch(
    data: DataType,
    options?: FetchServerOptions<UnpackedRespType>
  ): Promise<UnpackedRespType | undefined> {
    let dataJSON: JSONObject = JSON.parse(JSON.stringify(data));
    if (!this.validateRequest(dataJSON)) {
      // console.log("Req Not valid");
      // console.log("endpoint:", this.endpoint ," DATA:", data, !this.validateResponce(data))
      throw new ValidationError("Fetch request data is invalid");
    }
    let raisedError: any = undefined;
    let success = (data: RespType, status: number) => {
      // validate schema
      if (!this.validateResponce(data)) {
        // console.log("Resp Not valid");
        // console.log(data);
        // console.log("endpoint:", this.endpoint ," DATA:", data, "schema", this.validateResponce.schema, !this.validateResponce(data))
        raisedError = new ValidationError("Fetch responce data is invalid");
        throw raisedError;
      }
      const unpackedData = this.unpackResponce(data);
      options?.success && options.success(unpackedData, status);
    };
    let error = (error: any) => {
      if (error === raisedError) {
        throw raisedError; 
      }
    }
    return await fetchServer<RespType>(this.method, this.endpoint, dataJSON, {
      ...options,
      success: success,
      error: error,
    }).then((data) => {
      if (this.validateResponce(data)) {
        return this.unpackResponce(data);
      }
      return undefined;
    });
  }
}


export class ServerFileFetcher<
  DataType extends { [k: string]: any }
> {
  method: RequestMethods;
  endpoint: string;
  validateRequest: ValidateFunction<DataType>;

  constructor(
    method: RequestMethods,
    endpoint: string,
    validateRequest: ValidateFunction<DataType>
  ) {
    this.method = method;
    this.endpoint = endpoint;
    this.validateRequest = validateRequest;
  }
  fetch(data: DataType, options?: FetchFileServerOptions) {
    let dataJSON: JSONObject = JSON.parse(JSON.stringify(data));
    if (!this.validateRequest(dataJSON)) {
      // console.log("Req Not valid");
      throw Error();
    }
    return fetchFileServer(this.method, this.endpoint, dataJSON, options);
  }
}