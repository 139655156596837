import { ReactNode, useEffect, useRef, useState } from "react";
import { createContext } from "../../../../hooks/useCreateContext";
import { DateTime } from "../../../../utils";
import { useApp } from "../../../../contexts/AppContext";
import { useUsers } from "../../../../contexts/UsersContext";
import { User } from "../../../../utils/Users";
import { MonthStatus } from "../../../../utils/MonthStatus";
import Placeholder from "../../../../components/Placeholder";
import { fetchers } from "../../../../serverApi/fetchers";
import { AuthRoles } from "../../../../utils/AuthRoles";
import alertMessages from "../../../../data/alertMessages.json";
import { createSearchParams, generatePath, To } from "react-router-dom";
import apiUrls from "../../../../data/api.json";
import { useSelectedRecords } from "../useSelected";
import _ from "lodash";
import { OpenExportModalStatus, useExportModal } from "../hooks/useModal";
import { ModalStatus } from "../../../../hooks/useModal";
import { Formatter } from "../../../../utils/Formatter";
import { fetchAll } from "../../../../utils/fetchFromEndpoint";

export type SummaryMonth = {
  status: MonthStatus;
  hasRequests: boolean;
  monthTotalMinutes: number;
  documentedMonthTotalMinutes: number;
} | null;

export type MemberMonthlyTimesheet = {
  member: User;
  month: SummaryMonth;
};

function isMemberMonthlyTimesheetEqual(
  t1: MemberMonthlyTimesheet,
  t2: MemberMonthlyTimesheet
): boolean {
  return _.isEqual(
    [
      t1.member.id,
      t1.month,
      t1.month?.status,
      t1.month?.hasRequests,
      t1.month?.monthTotalMinutes,
      t1.month?.documentedMonthTotalMinutes,
    ],
    [
      t2.member.id,
      t2.month,
      t2.month?.status,
      t2.month?.hasRequests,
      t2.month?.monthTotalMinutes,
      t2.month?.documentedMonthTotalMinutes,
    ]
  );
}

export type MonthlyTimesheetsSummary = MemberMonthlyTimesheet[];

type MonthlyTimesheetsContextVal = {
  month: DateTime;
  setMonth: (month: DateTime) => void;
  monthlyTimesheetsSummary: MonthlyTimesheetsSummary;
  changeMonthStatus: (
    user: User,
    oldStatus: MonthStatus,
    newStatus: MonthStatus
  ) => void;
  getMonthRecordsLink: (member: User) => To;
  selectedRecords: MemberMonthlyTimesheet[];
  toggleSelectRecord: (record: MemberMonthlyTimesheet) => void;
  isSelectedRecord: (record: MemberMonthlyTimesheet) => boolean;
  allAlreadySelected: () => boolean;
  selectAll: () => void;
  unselectAll: () => void;
  selectRecordsExcl: (records: MemberMonthlyTimesheet[]) => void;
  approvedTimesheets: MemberMonthlyTimesheet[];
  modalStatus: ModalStatus<OpenExportModalStatus>;
  openExportSelectedModal: () => void;
  closeModal: () => void;
  fileAnchorRef: React.MutableRefObject<HTMLAnchorElement | null>;
  downloadMergedTimesheetsForSelected: (format: "ZIP" | "PDF") => void,
  downloadTimesheetForMember: (member: User) => void

};
const [MonthlyTimesheetsContext, useMonthlyTimesheetsContext] =
  createContext<MonthlyTimesheetsContextVal>();
export { useMonthlyTimesheetsContext };

type SummaryProviderProps = {
  children: ReactNode;
  month: DateTime;
  setMonth: (month: DateTime) => void;
};

export function MonthlyTimesheetsProvider({
  children,
  month,
  setMonth,
}: SummaryProviderProps) {
  const fileAnchorRef = useRef<HTMLAnchorElement | null>(null);
  const { showAlert } = useApp();
  // const navigate = useNavigate();
  const { allUsersByRole } = useUsers();
  const [monthlyTimesheetsSummary, setMonthlyTimesheetsSummary] = useState<
    MonthlyTimesheetsSummary | undefined
  >(undefined);
  const approvedTimesheetsRef = useRef<MemberMonthlyTimesheet[] | undefined>(monthlyTimesheetsSummary ? monthlyTimesheetsSummary.filter(memberSummary => memberSummary.month && !memberSummary.month.hasRequests && memberSummary.month.status === MonthStatus.completed) : undefined);
  const approvedTimesheets = approvedTimesheetsRef.current;
  const {selectedRecords, isSelectedRecord, toggleSelectRecord, allAlreadySelected, selectAll, unselectAll, selectRecordsExcl} = useSelectedRecords<MemberMonthlyTimesheet>(
    monthlyTimesheetsSummary ? monthlyTimesheetsSummary : [],
    isMemberMonthlyTimesheetEqual
  );
   const refreshSummary = async () => {
    const dataToSend = {
      date: month,
    };
    await fetchers.account.admin.membersMonthlyTimesheets.fetch(dataToSend, {
      success: (data, status) => {
        const summary = allUsersByRole(AuthRoles.member).map((member) => {
          const monthSummary = data.membersMonthSummary.find(
            (monthSummary) => monthSummary.member.id === member.id
          );
          return {
            member: member,
            month: ((memberSummary) => {
              if (memberSummary && memberSummary.month) {
                const { member, month } = memberSummary;
                return month;
              } else {
                return null;
              }
            })(monthSummary),
          };
        });
        approvedTimesheetsRef.current = summary ? summary.filter(memberSummary => memberSummary.month && !memberSummary.month.hasRequests && memberSummary.month.status === MonthStatus.completed) : undefined;
        setMonthlyTimesheetsSummary(summary);
        
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
  };

  useEffect(() => {
    refreshSummary();
  }, [month]);

  const { modalStatus, openExportModal, closeExportModal } = useExportModal(refreshSummary);
  const closeModal = closeExportModal;

  const changeMonthStatus = (
    member: User,
    oldStatus: MonthStatus,
    newStatus: MonthStatus
  ) => {
    if (oldStatus === newStatus) {
      return;
    }
    var valid =
      (oldStatus === MonthStatus.submitted &&
        newStatus === MonthStatus.editable) ||
      (oldStatus === MonthStatus.submitted &&
        newStatus === MonthStatus.completed) ||
      (oldStatus === MonthStatus.completed &&
        newStatus === MonthStatus.submitted);

    if (!valid) {
      showAlert(alertMessages.invalidData);
      return;
    }
    if (
      (oldStatus === MonthStatus.submitted ||
        oldStatus === MonthStatus.completed) &&
      newStatus === MonthStatus.editable
    ) {
      if (
        window.confirm(
          'Are you sure you want to grant "draft" status for this month? This action will allow the member to make changes to this month\'s time records.'
        ) !== true
      ) {
        return;
      }
    }
    setMonthlyTimesheetsSummary(undefined);
    const dataToSend = {
      userId: member.id,
      date: month,
      newStatus: newStatus,
    };
    fetchers.account.admin.changeMonthStatus.fetch(dataToSend, {
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        refreshSummary();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
  };

  const getMonthRecordsLink = (member: User): To => {
    const monthValNum = month.getLocMonth();
    const monthVal = monthValNum.toString().padStart(2, "0");
    const queryParams = createSearchParams({
      month: monthVal + "." + month.getLocYear(),
    }).toString();
    return {
      pathname: generatePath(
        apiUrls.account.children.admin.children.memberMonth,
        {
          userId: member.id,
        }
      ),
      search: queryParams,
    };
  };

//   const myAsynFunction = async (url: string): Promise<T> => {
//     const x = await fetch("google.com")
//     return x
// }
   const openExportSelectedModal = async () => {
    //  await refreshSummary();
     if(selectedRecords.length < 1) {
         return;
       }
    let recordsInfo = ""
    selectedRecords.forEach(record => {
      recordsInfo += record.member.firstName + " " + record.member.lastName + " - " + (record.month ? Formatter.formatDurationInHoursRaw(record.month.documentedMonthTotalMinutes) : "no data" ) + " godz." + "\n";
    }) 
     openExportModal(recordsInfo)
  }

  const downloadMergedTimesheetsForMembers = (members: User[], format: "ZIP" | "PDF", name_extra = '') => {
    const timesheet_pl = 'ewidencja';
    const month_names_pl = {
      "01": "styczen",
      "02": "luty",
      "03": "marzec",
      "04": "kwiecien",
      "05": "maj",
      "06": "czerwiec",
      "07": "lipiec",
      "08": "sierpien",
      "09": "wrzesien",
      "10": "pazdziernik",
      "11": "listopad",
      "12": "grudzien"
    };
    const dataToSend = {
      date: month,
      usersId: members.map(member => member.id),
      format: format
    };
    fetchAll([
          fetchers.account.general.companyInfo.fetch({}),
          fetchers.account.admin.genMergedTimesheetsPDF.fetch(dataToSend) 
    ],{
      success: ([companyInfo, timesheet]) => {
        let url = URL.createObjectURL(timesheet.file);
        const month_name = month_names_pl[month.getLocMonth().toString().padStart(2, "0") as keyof typeof month_names_pl];
        const defaultFilename = companyInfo.company.toLowerCase() + 
          "_" + timesheet_pl + "_" +
          month.getLocYear().toString() +
          "_" +
          month_name +
          "_" +
          name_extra
          +
          new DateTime().toLocIsoShortString() +
          ".pdf";
        const filename = !name_extra && timesheet.filename ? timesheet.filename : defaultFilename;
        // console.log(data.filename);
        if (fileAnchorRef.current) {
          fileAnchorRef.current.href = url;
          fileAnchorRef.current.download = filename;
          fileAnchorRef.current.click();
        }
        URL.revokeObjectURL(url);
        // alert(alertMessages.downloaded + "\n" + "File name: " + filename + "");
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
  }
  const downloadMergedTimesheetsForSelected = (format: "ZIP" | "PDF") => {
    if(selectedRecords.length < 1) {
      return;
    }
    downloadMergedTimesheetsForMembers(selectedRecords.map(record => record.member) , format)
  }
  const downloadTimesheetForMember = (member: User) => {
    downloadMergedTimesheetsForMembers([member], 'PDF', (member.lastName + '_' + member.firstName + '_'))
  }
  return (
    <>
      {monthlyTimesheetsSummary && approvedTimesheets !== undefined && (
        <MonthlyTimesheetsContext.Provider
          value={{
            month,
            setMonth,
            monthlyTimesheetsSummary,
            changeMonthStatus,
            getMonthRecordsLink,
            selectedRecords,
            toggleSelectRecord,
            isSelectedRecord,
            allAlreadySelected,
            selectAll,
            unselectAll,
            selectRecordsExcl,
            approvedTimesheets,
            modalStatus,
            openExportSelectedModal,
            closeModal,
            fileAnchorRef,
            downloadMergedTimesheetsForSelected,
            downloadTimesheetForMember
          }}
        >
          {children}
        </MonthlyTimesheetsContext.Provider>
      )}
      {!monthlyTimesheetsSummary && <Placeholder />}
    </>
  );
}
