import Card from "../../../components/Card";
import { Button, Card as CardBS, Col, Container, Row } from "react-bootstrap";
import Header from "./components/Header";
import { YearRecordsProvider, useYearRecordsTable } from "./context/YearRecordsTableContext";
import { DateTime } from "../../../utils";
import MonthsTable from "./components/MonthsTable";
import "./YearRecordsTable.scss";
import { ReactNode, useRef } from "react";
import WidgetCard from "../../../components/WidgetCard";

// function YearRecordsTableInner() {
//   return (
//     <Card className="YearRecordsTable">
//       <CardBS.Header>
//       <Header />
//       </CardBS.Header>
//       <CardBS.Body>
//         <MonthsTable />
//       </CardBS.Body>
//     </Card>
//   );
// }

// type YearRecordsTableProps = {
//   year: DateTime;
//   setYear: (year: DateTime) => void;
// };

// export function YearRecordsTable0({ year, setYear }: YearRecordsTableProps) {
//   return (
//     <YearRecordsProvider year={year} setYear={setYear}>
//       <YearRecordsTableInner />
//     </YearRecordsProvider>
//   );
// }

type YearRecordsTableProps = {
  year: DateTime;
  setYear: (year: DateTime) => void;
};

export function YearRecordsTable({ year, setYear }: YearRecordsTableProps) {
  // const YRProvider = useRef(({ children }: { children: ReactNode }) => (
  //   <YearRecordsProvider
  //   year={year} setYear={setYear}
  //     children={children}
  //   />
  // ));
  const YRProvider = ({ children }: { children: ReactNode }) => (
    <YearRecordsProvider
    year={year} setYear={setYear}
      children={children}
    />
  );
  return (
    <WidgetCard className="YearRecordsTable" ContextProvider={YRProvider}>
      <WidgetCard.Header>
        <Header/>
      </WidgetCard.Header>
      <WidgetCard.Body>
        <MonthsTable/>
      </WidgetCard.Body>
    </WidgetCard>
  );
}

