import { useState } from "react";
// import { useHotkeys } from "./useHotkeys";
import { useHotkeys } from 'react-hotkeys-hook';

type Status = {
  [prop: string]: any;
} | null;

type OpenedModalStatus = {
  mode: string;
  status: Status;
};
export type ModalStatus<T extends OpenedModalStatus> =
  | (T & { show: true })
  | {
      show: false;
      mode: null;
      status: null;
    };
export function useModal<T extends OpenedModalStatus>() {
  const [modalStatus, setModalStatus] = useState<ModalStatus<T>>({
    show: false,
    mode: null,
    status: null,
  });
  const closeModal = () => {
    setModalStatus({ show: false, mode: null, status: null });
  };
  const openModal = (status: T) => {
    setModalStatus({ ...status, show: true });
  };
  // Hotkeys
  useHotkeys('Escape', () => {
    if(modalStatus.show){
      closeModal();
    }
  }, [modalStatus.show]);
  // useHotkeys([
  //   {
  //     keys: ["Escape"],
  //     callback: ()=>{
  //       if(modalStatus.show){
  //         closeModal()
  //       }
  //     }
  //   }
  // ], [modalStatus.show]);

  return {modalStatus, openModal, closeModal} as const;
}
