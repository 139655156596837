import { ReactNode, useRef } from "react";
import { DateTime } from "../../../utils";
import WidgetCard from "../../../components/WidgetCard";
import { User } from "../../../utils/Users";
import Header from "./components/Header";
import { YearRecordsProvider } from "./context/YearRecordsTableContext";
import MonthsTable from "./components/MonthsTable";
import { Year } from "../../../utils/TimeTypes";
import "./MemberYearTimeRecordTable.scss";

type MemberYearTimeRecordTableProps = {
  member: User;
  setMember: (newMember: User) => void;
  year: DateTime;
  setYear: (year: DateTime) => void;
};

export function MemberYearTimeRecordTable({
  member,
  setMember,
  year,
  setYear,
}: MemberYearTimeRecordTableProps) {
  // const YRProvider = useRef(({ children }: { children: ReactNode }) => (
  //   <YearRecordsProvider
  //     member={member}
  //     setMember={setMember}
  //     year={year}
  //     setYear={setYear}
  //     children={children}
  //   />
  // ));
  const YRProvider = ({ children }: { children: ReactNode }) => (
    <YearRecordsProvider
      member={member}
      setMember={setMember}
      year={year}
      setYear={setYear}
      children={children}
    />
  );
  return (
    <WidgetCard
      className="MemberYearTimeRecordTable"
      ContextProvider={YRProvider}
    >
      <WidgetCard.Header>
        <Header />
      </WidgetCard.Header>
      <WidgetCard.Body>
        <MonthsTable />
      </WidgetCard.Body>
    </WidgetCard>
  );
}
