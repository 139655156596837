import _ from "lodash";
import WidgetCard from "../../../components/WidgetCard";
import { DateTime } from "../../../utils";
import { TimeCount } from "./components/TimeCount";
import "./MonthSubmitReminderBox.scss";
import { useEffect, useState } from "react";
import { MonthStatus } from "../../../utils/MonthStatus";
import { fetchers } from "../../../serverApi/fetchers";
export function MonthSubmitReminderBox(){
    const today = new DateTime();
    const monthToSubmit = today.getLocDay() === 1 ? today.getStartOfPrevMonth() : today.getStartOfMonth();
    const autoSubmit = monthToSubmit.getStartOfNextMonth().resetTime().setLocHours(16);
    const lastDayOfMonth = monthToSubmit.getEndOfMonth();
    const daysLeft = today.getLocYear() !== lastDayOfMonth.getLocYear() ? undefined : today.getLocMonth() === lastDayOfMonth.getLocMonth() ? (lastDayOfMonth.getLocDay() - today.getLocDay()) : today.getLocMonth() === lastDayOfMonth.getLocMonth() + 1 ? (-today.getLocDay()) : undefined;

    const [monthStatus, setMonthStatus] = useState<MonthStatus | undefined>(undefined);
    const refreshMonthStatus = () => {
        const dataToSend = {
            date: monthToSubmit
        }
        fetchers.account.member.monthStatus.fetch(dataToSend, {
            success: (data, status) => {
               setMonthStatus(data.status);
            }
        })
    }
    useEffect(() => {
        refreshMonthStatus();
    }, []);

    if (monthStatus === MonthStatus.editable) {
    return (
        <>
        {(daysLeft === 0 || (daysLeft === -1 && today.getLocDay() === 1)) && autoSubmit.substractInSec(today) > 0 && (<WidgetCard className="MonthSubmitReminderBox attention">
            <WidgetCard.Body>
            <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                <div className="pe-md-1"> Please submit your <span className="fw-bold">{monthToSubmit.getLocMonthName() + ' ' + monthToSubmit.getLocYear()}</span> timesheet. </div>
                <div>You have <span className="fw-bold"><TimeCount deadline={autoSubmit}/></span> left before autosubmit!</div>
                </div>
            </WidgetCard.Body>
        </WidgetCard>)}
        {daysLeft !== undefined && daysLeft >= 1 && daysLeft <= 4 && today.getLocDay() > 20 && (<WidgetCard className="MonthSubmitReminderBox warning text-center">
            <WidgetCard.Body>
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                <div className="pe-md-1">Only <span className="fw-bold">{daysLeft} days</span> left until the month ends. </div>
                <div>Don’t forget to submit your timesheet by {_.padStart(autoSubmit.getLocDay().toString(), 2, "0")}.{_.padStart(autoSubmit.getLocMonth().toString(), 2, "0")}!</div>
                </div>
            </WidgetCard.Body>
        </WidgetCard>)}
        </>
    );
    } else {
        return(<></>);
    }
}