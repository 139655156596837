import {
  Button,
  ButtonGroup,
  Card as CardBS,
  Col,
  Dropdown,
  DropdownButton,
  Row,
} from "react-bootstrap";
import {
  faPlus,
  faPencilAlt,
  faArrowDown,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import "./Header.scss";
import TooltipButton from "../../../../../components/TooltipButton";
import _ from "lodash";
import { TaskRecordGroups } from "../../../../../utils/TaskRecord";
import { useDayRecords } from "../../context/DayRecordsContext";
import { Formatter2 as Formatter } from "../../utils/Formatter2";
// import DatePicker from "react-datepicker";
import { DateTime } from "../../../../../utils";
import DayStatusDropdownnnn from "../../../../../components/member/DayStatusDropdown";
import DatePicker from "../../../../../components/DatePicker";
import { roundMinutesToHours } from "../../../../../utils/TimeMathFormules";
import { DayStatus, renderDayStatus } from "../../../../../utils/DayStatus";
import DayStatusRequested from "../../../../../components/DayStatusRequested";
import { useApp } from "../../../../../contexts/AppContext";
import alertMessages from "../../../../../data/alertMessages.json";
import { fetchers } from "../../../../../serverApi/fetchers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  // faArrowLeft, faMagnifyingGlassChart, faAnglesLeft, faList, faTableList
} from "@fortawesome/free-solid-svg-icons";
// import { faRectangleList} from "@fortawesome/free-regular-svg-icons";
import TimeHoursMinutes from "../../../../../components/TimeHoursMinutes";
// import { useHotkeys } from "../../../../../hooks/useHotkeys";
import { useHotkeys } from 'react-hotkeys-hook';
import { useCallback, useEffect, useRef } from "react";

// function Status() {
//   const {
//     status,
//   } = useDayRecords();
//   return status && (
//     <Button variant="outline-secondary" size="sm" disabled className="status">{status.status}</Button>
//   )
// }

type HeaderProps = {
  simplified: boolean;
};

export function Header({ simplified }: HeaderProps) {
  const { showAlert } = useApp();
  const {
    date,
    setDate,
    dayTimeRecordsInfo,
    changeDayStatus,
    selectedRecord,
    moveSelectedToBillableTimerecords,
    moveSelectedToUnverifiedTimerecords,
    openModal,
    availableDays,
    refreshDay,
  } = useDayRecords();
  var hideEditButton = !selectedRecord;
  var hideMoveToDraftsButton =
    !selectedRecord ||
    selectedRecord.group !== TaskRecordGroups.billableTimerecords;
  var hideMoveToRecordsButton =
    !selectedRecord ||
    selectedRecord.group !== TaskRecordGroups.unverifiedTimerecords;

  var invalidSelected =
    selectedRecord &&
    selectedRecord.group === TaskRecordGroups.unverifiedTimerecords;
  const createRecordClicked = () => {
    openModal(false);
  };
  const editRecordClicked = () => {
    openModal(true);
  };
  const moveToRecordsClicked = () => {
    moveSelectedToBillableTimerecords();
  };
  const moveToDraftsClicked = () => {
    moveSelectedToUnverifiedTimerecords();
  };
  // const countTotal = () => {
  //   return dayTimeRecordsInfo.timeRecords.sumDurationForGroup(TaskRecordGroups.billableTimerecords);
  // };
  // const totalMin: number | undefined = dayTimeRecordsInfo.totalHours;//countTotal();

  const cancelDayChangeStatusRequest = (date: DateTime) => {
    // const endpointCancelRequest = serverApi.cancelChangeDayStatusReuest;
    // const dataToSend = {
    //   date: date.toJSON(),
    // };
    // const doIfSuccess = () => {
    //   refreshDay();
    // }
    // const doIfFailed = () => {
    //   showAlert(alertMessages.somethingWentWrong);
    // }
    // const doAlways = undefined;
    // const doIfError = () => {
    //   showAlert(alertMessages.somethingWentWrong);
    // };
    // fetchPost<{}>(
    //   endpointCancelRequest,
    //   dataToSend,
    //   doIfSuccess,
    //   doIfFailed,
    //   doAlways,
    //   doIfError
    // );
    const dataToSend = { date: date };
    fetchers.account.member.cancelDateStatusRequest.fetch(dataToSend, {
      success: () => {
        refreshDay();
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },                    // leftArrowClassName="d-none d-sm-inline-block"
      // rightArrowClassName="d-none d-sm-inline-block"
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
  };

  // Hotkeys
  useHotkeys('Shift+N', () => {
    createRecordClicked();
  }, [selectedRecord]);
  
  useHotkeys('Shift+E', () => {
    editRecordClicked();
  }, [selectedRecord]);

  useHotkeys('Shift+ArrowDown', () => {
    if(selectedRecord &&
      selectedRecord.group === TaskRecordGroups.billableTimerecords){
        moveToDraftsClicked();
    }
  }, [selectedRecord]);

  useHotkeys('Shift+ArrowUp', () => {
    if(selectedRecord &&
      selectedRecord.group === TaskRecordGroups.unverifiedTimerecords){
        moveToRecordsClicked();
    }
  }, [selectedRecord]);

  // useHotkeys([
  //   {
  //     keys: ["Shift", "N"],
  //     callback: ()=>{createRecordClicked();}
  //   },
  //   {
  //     keys: ["Shift", "E"],
  //     callback: ()=>{editRecordClicked();}
  //   },
  //   {
  //     keys: ["Shift", "ArrowDown"],
  //     callback: useCallback(()=>{
        // console.log('x: ',selectedRecord?.group)
        // if(selectedRecord &&
        //   selectedRecord.group === TaskRecordGroups.billableTimerecords){
        //     console.log('down');moveToDraftsClicked();
        //   }
  //     }, [selectedRecord])
  //   },
  //   {
  //     keys: ["Shift", "ArrowUp"],
  //     callback: useCallback(()=>{
  //       console.log('y: ',selectedRecord?.group)
        // if(selectedRecord &&
        //   selectedRecord.group === TaskRecordGroups.unverifiedTimerecords){
        //     console.log('up');moveToRecordsClicked();
        //   }
  //       }, [selectedRecord])
  //   }
  // ], [selectedRecord])

  // useEffect(()=>{console.log("ss", selectedRecord?.group)}, [selectedRecord])
  return (
    <div className="Header d-flex justify-content-between align-items-center">
        <div
          className={`d-flex ${
            dayTimeRecordsInfo.editMode
              ? "justify-content-start"
              : "justify-content-between"
          } align-items-center`}
        >
          <div
            className={`border-end pe-2 d-flex justify-content-center justify-content-c-sm-start align-items-center data`}
          >
            <div className=" dataInfo">
              <DatePicker
                date={date}
                setDate={setDate}
                format={"dayMonthYear"}
                minDate={availableDays.minDay}
                maxDate={availableDays.maxDay}
                excludedDays={availableDays.excludedDays}
                leftArrow={true}
                rightArrow={true}
                // leftArrowClassName="d-none d-sm-inline-block"
                // rightArrowClassName="d-none d-sm-inline-block"
              />
            </div>

            {dayTimeRecordsInfo.requestedStatus === null && (
              <div className="status-info">
                {(setDate ||
                  (!setDate && !simplified) ||
                  dayTimeRecordsInfo.status !== DayStatus.editable) && (
                  <DayStatusDropdownnnn
                    variant="small"
                    className=""
                    status={dayTimeRecordsInfo.status}
                    onChange={(status) => {
                      changeDayStatus(status);
                    }}
                    disabled={!setDate && simplified}
                  />
                )}
              </div>
            )}
            {dayTimeRecordsInfo.requestedStatus !== null && (
              <div>
                <DayStatusRequested
                  requestedStatus={dayTimeRecordsInfo.requestedStatus}
                  onCancel={() => cancelDayChangeStatusRequest(date)}
                />
              </div>
            )}
          </div>
          <div className="d-flex justify-content-start align-items-center px-2 totalHours">
            <div className="title mx-1 d-none d-c-sm-block">Total time:</div>
            <div className="hours mx-1">
              <TimeHoursMinutes
                totalMinutes={dayTimeRecordsInfo.totalMinutes}
                boldNumbers={true}
                larger={true}
              />
            </div>
          </div>
        </div>
      {dayTimeRecordsInfo.editMode && (
        <div className="h-100 Tools ms-2">
          <div className="d-flex justify-content-end align-items-center">
            <TooltipButton
              tooltipContent="Move to records"
              buttonVariant="outline-primary"
              size="sm"
              className={`mx-1 px-1 invalid ${
                hideMoveToRecordsButton ? "d-none" : ""
              }`}
              style={{ width: "1.7rem", height: "1.7rem" }}
              onClick={moveToRecordsClicked}
            >
              <FontAwesomeIcon
                icon={faArrowUp}
                size="sm"
                style={{ width: "100%", height: "100%" }}
              />
            </TooltipButton>
            <TooltipButton
              tooltipContent="Move to drafts"
              buttonVariant="outline-primary"
              size="sm"
              className={`mx-1 px-1 ${hideMoveToDraftsButton ? "d-none" : ""}`}
              style={{ width: "1.7rem", height: "1.7rem" }}
              onClick={moveToDraftsClicked}
            >
              <FontAwesomeIcon
                icon={faArrowDown}
                size="sm"
                style={{ width: "100%", height: "100%" }}
              />
            </TooltipButton>
            <TooltipButton
              tooltipContent="Edit new record"
              buttonVariant="outline-primary"
              size="sm"
              className={`mx-1 px-1 ${invalidSelected ? "invalid" : ""} ${
                hideEditButton ? "d-none" : ""
              }`}
              style={{ width: "1.7rem", height: "1.7rem" }}
              onClick={editRecordClicked}
            >
              <FontAwesomeIcon
                icon={faPencilAlt}
                size="sm"
                style={{ width: "100%", height: "100%" }}
              />
            </TooltipButton>
            <TooltipButton
              tooltipContent="Create new record"
              buttonVariant="outline-primary"
              size="sm"
              className="mx-1 px-1"
              style={{ width: "1.7rem", height: "1.7rem" }}
              onClick={createRecordClicked}
            >
              <FontAwesomeIcon
                icon={faPlus}
                size="sm"
                style={{ width: "100%", height: "100%" }}
              />
            </TooltipButton>
          </div>
        </div>
      )}
    </div>
  );
}
