import { useRef, useState } from "react";
import { DateTime } from "../../../../utils";
import { DayRecordedData } from "../context/MonthRecordsContext";
// import { useHotkeys } from "../../../../hooks/useHotkeys";
import { useHotkeys } from 'react-hotkeys-hook';

export type Modal = {
  show: boolean;
  date: DateTime | null;
};
export function useModal(month:DateTime, selectedDay: DayRecordedData | null, refreshMonth: () => void) {
  const [modalStatus, setModalStatus] = useState<Modal>({
    show: false,
    date: null,
  });
  const positionY = useRef<number | undefined>(undefined);

  const openModal = (day: DayRecordedData | undefined = undefined) => {
    var dayToOpen: DayRecordedData | undefined = undefined;
    if (day) 
    dayToOpen = day; 
    else if (selectedDay) 
    dayToOpen = selectedDay;
    
    if (!dayToOpen) return;
    if (!dayToOpen.date.getStartOfMonth().equalTo(month.getStartOfMonth())) return;
    // tmp
    // console.log(dayToOpen.date);
    setModalStatus({
      show: true,
      date: dayToOpen.date,
    });
    positionY.current = window.scrollY;
  };
  const closeModal = () => {
    const date = modalStatus.date;
    setModalStatus({
      show: false,
      date: null,
    });
    const doAlways = date ? () => {
      const day = document.getElementById(date.getLocDay().toString());
      // console.log(day)
      if (day) {
        // day.scrollIntoView({behavior: "smooth", block: "center"})
        if(positionY.current != undefined)
        window.scrollTo(0, positionY.current)
      }
    }: undefined;
    refreshMonth();
    doAlways && doAlways();
  };

  const changeModalDate = (date: DateTime) => {
    if (date.getStartOfMonth().equalTo(month.getStartOfMonth())) {
      if (modalStatus.show) {
        setModalStatus({
          show: true,
          date: date,
        });
      }
    }
  }

  // Hotkeys
  useHotkeys('Escape', () => {
    if(modalStatus.show){
      closeModal();
    }
  }, [modalStatus.show]);
  // useHotkeys([
  //   {
  //     keys: ["Escape"],
  //     callback: ()=>{
  //       if(modalStatus.show){
  //         closeModal()
  //       }
  //     }
  //   }
  // ], [modalStatus.show]);

  return [modalStatus, openModal, closeModal, changeModalDate] as const;
}
