import { Button, Card as CardBS, Col, Row } from "react-bootstrap";
import DatePicker from "../../../../../components/DatePicker";
import { useDayRecords } from "../../context/DayRecordsTableContext";
import DayStatusDropdown from "../../../../../components/admin/DayStatusDropdown";
import DayStatusRequested from "../../../../../components/DayStatusRequested";
import { roundMinutesToHours } from "../../../../../utils/TimeMathFormules";
import { TaskRecordGroups } from "../../../../../utils/TaskRecord";
import DayRequestedStatusDropdown from "../../../../../components/admin/DayRequestedStatusDropdown";
import UserSelector from "../../../../../components/admin/UserSelector";
import { useUsers } from "../../../../../contexts/UsersContext";
import { AuthRoles } from "../../../../../utils/AuthRoles";
import { DateTime } from "../../../../../utils";
import { User } from "../../../../../utils/Users";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./Header.scss";
import { DayStatus } from "../../../../../utils/DayStatus";
import TimeHoursMinutes from "../../../../../components/TimeHoursMinutes";

export function Header() {
  const {
    member,
    setMember,
    date,
    setDate,
    dayTimeRecordsInfo,
    changeDayStatus,
    rejectChangeStatusRequest,
    selectedRecord,
    availableDays,
    refreshDay,
    redirectToMonth,
    embeded
  } = useDayRecords();

  const { allUsersByRole } = useUsers();

  const countTotal = () => {
    return dayTimeRecordsInfo.timeRecords.sumDurationForGroup(
      TaskRecordGroups.billableTimerecords
    );
  };
  // const totalMin: number | undefined = countTotal();

  return (
    <div className="Header">
      <div className="h-100 info">
        {!!setDate && !embeded && (
          <div className="mb-3">
            <UserSelector
              users={allUsersByRole(AuthRoles.member).filter((member) =>
                date.graterThanOrEqual(
                  DateTime.createLocDateTime(
                    member.created.getLocYear(),
                    member.created.getLocMonth(),
                    member.created.getLocDay()
                  )
                )
              )}
              value={member}
              onSelect={(member: User) => {
                // redirectToAnotherMember(member)
                setMember(member);
              }}
              variant="dropdown"
            />
          </div>
        )}
        <div className="d-flex justify-content-start align-items-center">
          <div className="d-flex border-end pe-2 justify-content-center justify-content-c-sm-start align-items-center flex-wrap">
            {/* {setDate && (
                  <div className="openMonth">
                    <Button
                      onClick={() => {
                        redirectToMonth(
                          DateTime.createLocDateTime(
                            date.getLocYear(),
                            date.getLocMonth()
                          )
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                  </div>
                )} */}
            <div>
              {/* <DatePicker selected={month.date} dateFormat="MM.yyyy" showMonthYearPicker className="mx-1" readOnly={!setMonth} onChange={(month) => month && setMonth && setMonth(new DateTime(month))} /> */}
              <DatePicker
                date={date}
                setDate={setDate}
                format={"dayMonthYear"}
                minDate={availableDays.minDay}
                maxDate={availableDays.maxDay}
                excludedDays={availableDays.excludedDays}
                leftArrow={true}
                rightArrow={true}
                // leftArrowClassName="d-none d-sm-inline-block"
                // rightArrowClassName="d-none d-sm-inline-block"
              />
            </div>
            {dayTimeRecordsInfo.requestedStatus === null && (
              <div className="status-info">
                {/* <Button variant="outline-secondary" size="sm" disabled className="status">{status.status}</Button> */}
                <DayStatusDropdown
                  variant="small"
                  className=""
                  status={dayTimeRecordsInfo.status}
                  onChange={(status) => {
                    changeDayStatus(dayTimeRecordsInfo.status, status);
                  }}
                />
                {/* <DropdownButton title={status.status} as={ButtonGroup} size="sm" variant="outline-secondary" className="status" drop="down-centered">
                    <Dropdown.Item eventKey="1">{status.status == DayStatus.editable ? 'submit' : (status.status == DayStatus.submitted ? 'complete' : "")}</Dropdown.Item>
                  </DropdownButton> */}
              </div>
            )}
            {dayTimeRecordsInfo.requestedStatus !== null && (
              <div>
                <DayRequestedStatusDropdown
                  currentStatus={dayTimeRecordsInfo.status}
                  requestedStatus={dayTimeRecordsInfo.requestedStatus}
                  onReject={() => {
                    rejectChangeStatusRequest();
                  }}
                  onApprove={() => {
                    changeDayStatus(
                      dayTimeRecordsInfo.status,
                      dayTimeRecordsInfo.requestedStatus as DayStatus
                    );
                  }}
                />
              </div>
            )}
          </div>
          {dayTimeRecordsInfo.totalMinutes != undefined && (
            <div className="d-flex justify-content-start align-items-center px-2 totalHours">
              <div className="title mx-1 d-none d-c-sm-block">Total time:</div>
              <div className="hours mx-1">
                <TimeHoursMinutes
                  totalMinutes={dayTimeRecordsInfo.totalMinutes}
                  boldNumbers={true}
                  larger={true}
                />
              </div>
            </div>
          )}
          {/* <div className="d-flex justify-content-start align-items-center px-2 totalHours">
                <div className="title mx-1 d-none d-c-sm-block">
                  Documented hours:
                </div>
                <div className="hours mx-1">
                  <span
                    className="number"
                    style={{ fontWeight: "bold", fontSize: "1.05rem" }}
                  >
                    {documentedWorkingHours}
                  </span>
                  <span className="tit" style={{ fontSize: "0.85rem" }}>
                    h
                  </span>
                </div>
              </div> */}
        </div>
      </div>
    </div>
  );
}
