import { Col, Container, Row } from "react-bootstrap";
import Table from "../../../../../components/Table";
import { useDayRecords } from "../../context/DayRecordsContext";
import { DateTime } from "../../../../../utils";
import _ from "lodash";

import "./TaskTrackingDataTable.scss";
import { TaskRecordGroups } from "../../../../../utils/TaskRecord";
import Placeholder from "../../../../../components/Placeholder";
import FullTaskName from "../../../../../components/FullTaskName";
import FullTaskName2 from "../../../../../components/FullTaskName2";
import TimeHoursMinutes from "../../../../../components/TimeHoursMinutes";
import { Formatter } from "../../../../../utils/Formatter";
import { useEffect, useRef } from "react";

// type TaskTrackingDataTableProp = {
//   taskTrackingData: TaskTrackingData;
// };
export function TaskTrackingDataTable() {
  const { dayTimeRecordsInfo, selectedRecord, selectRecord, onContentLoad } =
    useDayRecords();

  var billableTimerecords = dayTimeRecordsInfo.timeRecords.groupToArray(
    TaskRecordGroups.billableTimerecords
  );
  var unverifiedTimerecords = dayTimeRecordsInfo.timeRecords.groupToArray(
    TaskRecordGroups.unverifiedTimerecords
  );
  useEffect(()=>{
    if(dayTimeRecordsInfo != undefined){
      onContentLoad();
    }
  }, []);

  const ref = useRef<HTMLDivElement>(null);
  useEffect(()=>{
    if(selectedRecord && ref.current) {
      const recordElement = Array.from(ref.current.querySelectorAll('div .Table .Body')).map(el => el?.querySelector(`[data-id='${selectedRecord.id}']`)).find(el => el !== null);
      if(recordElement){
        const rect = recordElement.getBoundingClientRect();
        const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
        if (!isVisible) {
          if (rect.top < 0) {
            recordElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          } else {
            recordElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }
        }
      }
    }
  }, [selectedRecord]);
  
  return (
    <div className="TaskTrackingDataTable" ref={ref}>
        <Container fluid="lg" className="billableTimerecords">
        <Table>
          <Table.Head>
            <Row className="justify-content-between">
              <Col xs={4} className="text-start align-self-center">
                Task
              </Col>
              <Col xs={5} className="text-center align-self-center">
                Period
              </Col>
              <Col xs={3} className="text-center align-self-center">
                Total
              </Col>
            </Row>
          </Table.Head>
          <Table.Body>
            {billableTimerecords &&
              billableTimerecords.map((billableTimerecord) => (
                <Row
                  className={`justify-content-between align-items-center ${
                    selectedRecord &&
                    selectedRecord.group === TaskRecordGroups.billableTimerecords &&
                    selectedRecord.id === billableTimerecord.id
                      ? "active"
                      : ""
                  }`}
                  data-id={billableTimerecord.id}
                  key={billableTimerecord.id}
                  onClick={() =>
                    selectRecord(
                      TaskRecordGroups.billableTimerecords,
                      billableTimerecord.id
                    )
                  }
                >
                  <Col
                    xs={4}
                    className="text-start taskName"
                    style={{ maxHeight: "50px", 
                    display: "flex"
                   }}
                  >
                    <FullTaskName2
                      categoryName={billableTimerecord.task.category}
                      taskName={billableTimerecord.task.name}
                    />
                  </Col>
                  

                  <Col xs={5} className="text-center">
                    {billableTimerecord.period.endedAt && (
                      <>
                      {Formatter .formatPeriod(billableTimerecord.period.startedAt, billableTimerecord.period.endedAt)}
                      </>
                    )}
                    {!billableTimerecord.period.endedAt && (
                      <span className="label-manual">manual</span>
                    )}
                    {/* {Formatter.formatPeriodOrManual(billableTimerecord.period)} */}
                  </Col>
                  <Col xs={3} className="text-center">
                    {/* {Formatter.formatDuration(billableTimerecord.durationMin)} */}
                    <TimeHoursMinutes totalMinutes={billableTimerecord.durationMin}/>
                  </Col>
                </Row>
              ))}
          </Table.Body>
        </Table>
      </Container>

      <Container
        fluid="lg"
        className="unverifiedTimerecords"
        style={{ position: "relative" }}
      >
        {unverifiedTimerecords && unverifiedTimerecords.length > 0 && (
          <Table className={!dayTimeRecordsInfo.editMode ? "disabled" : ""}>
            <Table.Head>
              <Row>
                {/* <Col>Task</Col>
                <Col>Period</Col>
                <Col>@Total</Col> */}
              </Row>
              <div className="title"><span className="px-2">Verification needed</span></div>
            </Table.Head>
            <Table.Body>
              {unverifiedTimerecords.map((unverifiedTimerecord) => (
                <Row
                  className={`justify-content-between ${
                    selectedRecord &&
                    selectedRecord.group ===
                      TaskRecordGroups.unverifiedTimerecords &&
                    selectedRecord.id === unverifiedTimerecord.id
                      ? "active"
                      : ""
                  }`}
                  data-id={unverifiedTimerecord.id}
                  key={unverifiedTimerecord.id}
                  onClick={() =>
                    dayTimeRecordsInfo.editMode &&
                    selectRecord(
                      TaskRecordGroups.unverifiedTimerecords,
                      unverifiedTimerecord.id
                    )
                  }
                >
                  <Col xs={4} className="text-start taskName">
                    <FullTaskName2
                      categoryName={unverifiedTimerecord.task.category}
                      taskName={unverifiedTimerecord.task.name}
                    />
                  </Col>
                  <Col xs={5} className="text-center">
                  {unverifiedTimerecord.period.endedAt && (
                      <>
                      {Formatter .formatPeriod(unverifiedTimerecord.period.startedAt, unverifiedTimerecord.period.endedAt)}
                      </>
                    )}
                    {!unverifiedTimerecord.period.endedAt && (
                      <span className="label-manual">manual</span>
                    )}
                    {/* {Formatter.formatPeriodOrManual(
                      unverifiedTimerecord.period
                    )} */}
                  </Col>
                  <Col xs={3} className="text-center">
                    {/* {Formatter.formatDuration(
                      unverifiedTimerecord.durationMin
                    )} */}
                    <TimeHoursMinutes totalMinutes={unverifiedTimerecord.durationMin}/>
                  </Col>
                </Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </Container>
    </div>
  );
}
