import { useEffect, useRef, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import {
  Hours,
  Minutes,
  Seconds,
  Time,
  TimeHM,
  TimeHMS,
  isHours,
  isMinutes,
  isSeconds,
} from "../../utils/TimeTypes";
import "./TimeInput.scss";

type InputNumberProps = {
  min: number;
  max: number;
  step: number;
  value?: number;
  onChange?: (value: string) => void;
  onInput?: () => void;
  isInvalid?: boolean;
  preventPlus?: boolean;
  preventMinus?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
};
function InputNumber({
  min,
  max,
  step,
  value,
  onChange,
  onInput,
  isInvalid,
  preventPlus = false,
  preventMinus = false,
  readOnly = false,
  disabled = false,
  autoFocus = false
}: InputNumberProps) {
  const ref = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (ref?.current) {
      ref.current.addEventListener("wheel", (e) => e.stopPropagation());
    }
  }, []);

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const val = event.target.value;
    onChange && onChange(val);
  };

  const handleInput = () => {
    onInput && onInput();
  };
  const onKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (
      (preventPlus && event.key === "+") ||
      (preventMinus && event.key === "-")
    ) {
      event.preventDefault();
    }
  };
  const handlePaste = (
    event: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const clipboardData = event.clipboardData;
    const pastedData = parseInt(clipboardData.getData("text"));
    if (isNaN(pastedData)) {
      event.preventDefault();
    }
    if ((preventMinus && pastedData < 0) || preventPlus) {
      event.preventDefault();
      onChange && onChange(pastedData.toString());
    }
  };
  return (
    <Form.Control
      type="number"
      ref={ref}
      min={min}
      max={max}
      step={step}
      value={value === undefined ? "" : value}
      onChange={handleOnChange}
      onInput={handleInput}
      isInvalid={isInvalid}
      readOnly={readOnly}
      disabled={disabled}
      onKeyDown={onKeyDown}
      onPaste={handlePaste}
      autoFocus={autoFocus}
    ></Form.Control>
  );
}

type TimeInputHMProps = {
  value?: TimeHM;
  onChange?: (time: Time | undefined) => void;
  onInput?: () => void;
  isInvalid?: boolean;
};
export function TimeInputHM({
  value,
  onChange,
  onInput,
  isInvalid,
}: TimeInputHMProps) {
  const [inputValue, setInputValue] = useState<{
    hours: number;
    minutes: number;
  }>({ hours: value ? value.hours : 0, minutes: value ? value.minutes : 0 });
  var time: TimeHM | undefined = value;
  const setTime = (newTime: TimeHM | undefined) => {
    time = newTime;
  };

  useEffect(() => {
    // console.log(inputValue.minutes);
    let newTime: TimeHM | undefined;
    if (
      inputValue.hours < 0 ||
      inputValue.hours > 23 ||
      inputValue.minutes < 0 ||
      inputValue.minutes > 59
    ) {
      newTime = undefined;
    } else {
      newTime = {
        hours: inputValue.hours as Hours,
        minutes: inputValue.minutes as Minutes,
      };
    }
    if (
      !(newTime?.hours === time?.hours && newTime?.minutes == time?.minutes)
    ) {
      setTime(newTime);
      onChange && onChange(newTime);
    }
  }, [inputValue]);

  const onChangeHours = (value: string) => {
    if (value === "") {
      setInputValue({ ...inputValue, hours: 0 });
      return;
    }
    var parsedHours = parseInt(value);
    if (isNaN(parsedHours) || parsedHours < 0) {
      return;
    }
    setInputValue({ ...inputValue, hours: parsedHours });
  };
  const onChangeMinutes = (value: string) => {
    if (value === "") {
      setInputValue({ ...inputValue, minutes: 0 });
      return;
    }
    var parsedMinutes = parseInt(value);
    if (isNaN(parsedMinutes) || parsedMinutes < 0) return;
    setInputValue({ ...inputValue, minutes: parsedMinutes });
  };
  return (
    <Row className="TimeInput">
      <Col>
        <InputGroup>
          <InputNumber
            min={0}
            max={23}
            step={1}
            value={inputValue.hours}
            onChange={(value) => onChangeHours(value)}
            onInput={onInput}
            isInvalid={
              isInvalid || inputValue.hours < 0 || inputValue.hours > 23
            }
          />
          <InputGroup.Text>h</InputGroup.Text>
        </InputGroup>
      </Col>
      <Col>
        <InputGroup>
          <InputNumber
            min={0}
            max={59}
            step={1}
            value={inputValue.minutes}
            onChange={(value) => onChangeMinutes(value)}
            onInput={onInput}
            isInvalid={
              isInvalid || inputValue.minutes < 0 || inputValue.minutes > 59
            }
          />
          <InputGroup.Text>m</InputGroup.Text>
        </InputGroup>
      </Col>
    </Row>
  );
}

type TimeInputHMSProps = {
  value?: TimeHMS;
  secondsReadonly?: boolean;
  onChange?: (time: TimeHMS) => void;
  onInput?: () => void;
  isInvalid?: boolean;
};
export function TimeInputHMS({
  value,
  onChange,
  secondsReadonly,
  onInput,
  isInvalid,
}: TimeInputHMSProps) {
  var time: TimeHMS | undefined = value;
  const setTime = (newTime: TimeHMS) => {
    time = newTime;
  };

  const onChangeHours = (value: string) => {
    var parsedHours = parseInt(value);
    if (isNaN(parsedHours) || parsedHours < 0 || parsedHours > 23) return;
    setTime(
      time
        ? { ...time, hours: parsedHours as Hours }
        : { hours: parsedHours as Hours, minutes: 0, seconds: 0 }
    );
    time && onChange && onChange(time);
  };
  const onChangeMinutes = (value: string) => {
    var parsedMinutes = parseInt(value);
    if (isNaN(parsedMinutes) || parsedMinutes < 0 || parsedMinutes > 59) return;
    setTime(
      time
        ? { ...time, minutes: parsedMinutes as Minutes }
        : { hours: 0, minutes: parsedMinutes as Minutes, seconds: 0 }
    );
    time && onChange && onChange(time);
  };
  const onChangeSeconds = (value: string) => {
    var parsedSeconds = parseInt(value);
    if (isNaN(parsedSeconds) || parsedSeconds < 0 || parsedSeconds > 59) return;
    setTime(
      time
        ? { ...time, seconds: parsedSeconds as Seconds }
        : { hours: 0, minutes: 0, seconds: parsedSeconds as Seconds }
    );
    time && onChange && onChange(time);
  };
  return (
    <Row className="TimeInput">
      <Col>
        <InputGroup>
          <InputNumber
            min={0}
            max={23}
            step={1}
            value={time?.hours}
            onChange={(value) => onChangeHours(value)}
            onInput={onInput}
            isInvalid={isInvalid}
          />
          <InputGroup.Text>h</InputGroup.Text>
        </InputGroup>
      </Col>
      <Col>
        <InputGroup>
          <InputNumber
            min={0}
            max={59}
            step={1}
            value={time?.minutes}
            onChange={(value) => onChangeMinutes(value)}
            onInput={onInput}
            isInvalid={isInvalid}
          />
          <InputGroup.Text>m</InputGroup.Text>
        </InputGroup>
      </Col>

      <Col>
        <InputGroup>
          <InputNumber
            min={0}
            max={59}
            step={1}
            value={time ? time.seconds : 0}
            onChange={
              !secondsReadonly ? (value) => onChangeSeconds(value) : undefined
            }
            onInput={secondsReadonly ? onInput : undefined}
            isInvalid={isInvalid}
            readOnly={secondsReadonly}
            disabled={secondsReadonly}
          />
          <InputGroup.Text>s</InputGroup.Text>
        </InputGroup>
      </Col>
    </Row>
  );
}

// InputTimeHM
type TimeHMVal =
  | TimeHM
  | {
      hours: undefined;
      minutes: Minutes;
    }
  | {
      hours: Hours;
      minutes: undefined;
    }
  | {
      hours: undefined;
      minutes: undefined;
    };
function isTimeHM(time: TimeHM | TimeHMVal): time is TimeHM {
  return !Object.values(time).some((val) => val === undefined);
}
type InputTimeHMProps = {
  defaultValue?: TimeHM;
  onChange?: (time: TimeHM | undefined) => void;
  onInput?: () => void;
  isInvalid?: boolean;
  allowEmpty?: boolean;
  autoFocus?: boolean;
};
export function InputTimeHM({
  defaultValue,
  onChange,
  onInput,
  isInvalid,
  allowEmpty = true,
  autoFocus = false,
}: InputTimeHMProps) {
  const [inputValue, _setInputValue] = useState<TimeHMVal>(
    defaultValue ? defaultValue : { hours: undefined, minutes: undefined }
  );
  const setInputValue = (newInputValue: TimeHMVal) => {
    if (!isTimeHM(newInputValue) && !isTimeHM(inputValue)) return;
    if (
      isTimeHM(newInputValue) &&
      isTimeHM(inputValue) &&
      newInputValue.hours === inputValue.hours &&
      newInputValue.minutes === inputValue.minutes
    ) {
      return;
    }
    _setInputValue(newInputValue);
    onChange && onChange(isTimeHM(newInputValue) ? newInputValue : undefined);
  };

  const onChangeHours = (value: string) => {
    var parsedHours = parseInt(value);
    if (isNaN(parsedHours) || !isHours(parsedHours)) {
      setInputValue({ ...inputValue, hours: undefined });
      return;
    }
    if (!isTimeHM(inputValue)) {
      setInputValue({
        hours: parsedHours,
        minutes: inputValue.minutes !== undefined ? inputValue.minutes : 0,
      });
      return;
    }
    setInputValue({ ...inputValue, hours: parsedHours });
  };
  const onChangeMinutes = (value: string) => {
    var parsedMinutes = parseInt(value);
    if (isNaN(parsedMinutes) || !isMinutes(parsedMinutes)) {
      setInputValue({ ...inputValue, minutes: undefined });
      return;
    }
    if (!isTimeHM(inputValue)) {
      setInputValue({
        hours: inputValue.hours !== undefined ? inputValue.hours : 0,
        minutes: parsedMinutes,
      });
      return;
    }
    setInputValue({ ...inputValue, minutes: parsedMinutes });
  };
  const inputIsInvalid = isInvalid || (!allowEmpty && !isTimeHM(inputValue));
  return (
    <Row className="TimeInput">
      <Col>
        <InputGroup>
          <InputNumber
            min={0}
            max={23}
            step={1}
            value={
              inputValue.hours
            }
            onChange={(value) => onChangeHours(value)}
            onInput={onInput}
            isInvalid={inputIsInvalid}
            preventPlus={true}
            preventMinus={true}
            autoFocus={autoFocus}
          />
          <InputGroup.Text>h</InputGroup.Text>
        </InputGroup>
      </Col>
      <Col>
        <InputGroup>
          <InputNumber
            min={0}
            max={59}
            step={1}
            value={
              inputValue.minutes
            }
            onChange={(value) => onChangeMinutes(value)}
            onInput={onInput}
            isInvalid={inputIsInvalid}
            preventPlus={true}
            preventMinus={true}
          />
          <InputGroup.Text>m</InputGroup.Text>
        </InputGroup>
      </Col>
    </Row>
  );
}

// InputTimeHMS
type TimeHMSVal =
  | TimeHMS
  | {
      hours: undefined;
      minutes: Minutes;
      seconds: Seconds;
    }
  | {
      hours: Hours;
      minutes: undefined;
      seconds: Seconds;
    }
  | {
      hours: Hours;
      minutes: Minutes;
      seconds: undefined;
    }
  | {
      hours: undefined;
      minutes: undefined;
      seconds: Seconds;
    }
  | {
      hours: Hours;
      minutes: undefined;
      seconds: undefined;
    }
  | {
      hours: undefined;
      minutes: Minutes;
      seconds: undefined;
    }
  | {
      hours: undefined;
      minutes: undefined;
      seconds: undefined;
    };
function isTimeHMS(time: TimeHMS | TimeHMSVal): time is TimeHMS {
  return !Object.values(time).some((val) => val === undefined);
}
type InputTimeHMSProps = {
  defaultValue?: TimeHMS;
  secondsReadonly?: boolean;
  onChange?: (time: TimeHMS | undefined) => void;
  onInput?: () => void;
  isInvalid?: boolean;
  allowEmpty?: boolean;
  autoFocus?: boolean;
};
export function InputTimeHMS({
  defaultValue,
  onChange,
  secondsReadonly,
  onInput,
  isInvalid,
  allowEmpty = true,
  autoFocus = false
}: InputTimeHMSProps) {
  const [inputValue, _setInputValue] = useState<TimeHMSVal>(
    defaultValue ? defaultValue : {
      hours: undefined,
      minutes: undefined,
      seconds: undefined
    }
  );
  useEffect(()=>{
    defaultValue && _setInputValue(defaultValue)
  }, [defaultValue])
  const setInputValue = (newInputValue: TimeHMSVal) => {
    if (!isTimeHMS(newInputValue) && !isTimeHMS(inputValue)) return;
    if (
      isTimeHMS(newInputValue) &&
        isTimeHMS(inputValue) &&
      newInputValue.hours === inputValue.hours &&
      newInputValue.minutes === inputValue.minutes &&
      newInputValue.seconds === inputValue.seconds
    ) {
      return;
    }
    _setInputValue(newInputValue);
    onChange && onChange(isTimeHMS(newInputValue) ? newInputValue : undefined);
  };

  const onChangeHours = (value: string) => {
    var parsedHours = parseInt(value);
    if (isNaN(parsedHours) || !isHours(parsedHours)) {
      setInputValue({...inputValue, hours: undefined});
      return;
    }
    if (!isTimeHMS(inputValue)) {
      setInputValue({ hours: parsedHours, minutes: inputValue.minutes !== undefined ? inputValue.minutes : 0, seconds: 0 });
      return;
    }
    setInputValue({
      ...inputValue,
      hours: parsedHours,
      seconds: secondsReadonly ? 0 : inputValue.seconds,
    });
  };

  const onChangeMinutes = (value: string) => {
    var parsedMinutes = parseInt(value);
    if (isNaN(parsedMinutes) || !isMinutes(parsedMinutes)) {
      setInputValue({...inputValue, minutes: undefined});
      return;
    }
    if (!isTimeHMS(inputValue)) {
      setInputValue({ hours: inputValue.hours !== undefined ? inputValue.hours : 0, minutes: parsedMinutes, seconds: inputValue.seconds !== undefined ? inputValue.seconds : 0 });
      return;
    }
    setInputValue({
      ...inputValue,
      minutes: parsedMinutes,
      seconds: secondsReadonly ? 0 : inputValue.seconds,
    });
  };

  const onChangeSeconds = (value: string) => {
    if (secondsReadonly) {
      return;
    }
    var parsedSeconds = parseInt(value);
    if (isNaN(parsedSeconds) || !isSeconds(parsedSeconds)) {
      setInputValue({...inputValue, seconds: undefined});
      return;
    }
    if (!isTimeHMS(inputValue)) {
      setInputValue({ hours: inputValue.hours !== undefined ? inputValue.hours : 0, minutes: inputValue.minutes !== undefined ? inputValue.minutes : 0, seconds: parsedSeconds });
      return;
    }
    setInputValue({ ...inputValue, seconds: parsedSeconds });
  };
  const inputIsInvalid = isInvalid || (!allowEmpty && inputValue === undefined);
  return (
    <Row className="TimeInput">
      <Col>
        <InputGroup>
          <InputNumber
            min={0}
            max={23}
            step={1}
            value={inputValue.hours}
            onChange={onChangeHours}
            onInput={onInput}
            isInvalid={inputIsInvalid}
            preventPlus={true}
            preventMinus={true}
            autoFocus={autoFocus}
          />
          <InputGroup.Text>h</InputGroup.Text>
        </InputGroup>
      </Col>
      <Col>
        <InputGroup>
          <InputNumber
            min={0}
            max={59}
            step={1}
            value={inputValue.minutes}
            onChange={onChangeMinutes}
            onInput={onInput}
            isInvalid={inputIsInvalid}
            preventPlus={true}
            preventMinus={true}
          />
          <InputGroup.Text>m</InputGroup.Text>
        </InputGroup>
      </Col>

      <Col>
        <InputGroup>
          <InputNumber
            min={0}
            max={59}
            step={1}
            value={inputValue.seconds}
            onChange={onChangeSeconds}
            onInput={secondsReadonly ? onInput : undefined}
            isInvalid={inputIsInvalid}
            readOnly={secondsReadonly}
            disabled={secondsReadonly}
            preventPlus={true}
            preventMinus={true}
          />
          <InputGroup.Text>s</InputGroup.Text>
        </InputGroup>
      </Col>
    </Row>
  );
}
