import { Button, Card as CardBS, Col, Container, Row } from "react-bootstrap";
import Card from "../../../components/Card";

import Table from "../../../components/Table";
import { DateTime } from "../../../utils";
import {
  MonthRecordsProvider,
  useMonthRecords,
} from "./context/MonthRecordsContext";
import WorkTimeSummaryTable from "./components/WorkTimeSummaryTable";
import DayRecordsTable from "../DayRecordsTable";
import DayModal from "./components/DayModal";
import Header from "./components/Header";
import "./MonthRecordsTable.scss";
import WidgetCard from "../../../components/WidgetCard";
import { ReactNode, useRef } from "react";
import ViewSummaryButton from "../../../components/ViewSummaryButton";
import { createSearchParams } from "react-router-dom";
import apiUrls from "../../../data/api.json";


type MonthRecordsTableProps = {
  month: DateTime;
  setMonth: (month: DateTime) => void;
};

export function MonthRecordsTable({ month, setMonth }: MonthRecordsTableProps) {
  // const MRProvider = useRef<({ children }: {
  //   children: ReactNode;
  // }) => React.JSX.Element>(({ children }: { children: ReactNode }) => (
  //   <MonthRecordsProvider
  //     month={month}
  //     setMonth={setMonth}
  //     children={children}
  //   />
  // ));

  const MRProvider = ({ children }: { children: ReactNode }) => (
    <MonthRecordsProvider
      month={month}
      setMonth={setMonth}
      children={children}
    />
  );
  const getYearSummaryLink = (month: DateTime) => {
    const year = month.getStartOfYear();
    const queryParams = createSearchParams({
      year: year.getLocYear(),
    }).toString();
    return {
      pathname: apiUrls.account.children.member.children.yearRecords,
      search: queryParams,
    };
  };
  return (
    <WidgetCard
      className="MonthRecordsTable"
      ContextProvider={MRProvider}
      useContext={useMonthRecords}
    >
      {!!setMonth && (<WidgetCard.TopExtras><ViewSummaryButton summaryLink={getYearSummaryLink(month)}/></WidgetCard.TopExtras>)}
      <WidgetCard.Header>
        <Header />
      </WidgetCard.Header>
      <WidgetCard.Body>
        <WorkTimeSummaryTable />
      </WidgetCard.Body>
      <WidgetCard.Modal>
        <DayModal />
      </WidgetCard.Modal>
    </WidgetCard>
  );
}
