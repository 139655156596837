import { useState } from "react";
import { useApp } from "../../../../../../contexts/AppContext";
import { ErrorLog } from "../../../../../../hooks/useFormErrors";
import { useTasksList } from "../../../context/TasksListContext";
import restrictions from "../../../../../../data/restrictions.json";
import alertMessages from "../../../../../../data/alertMessages.json";
import { Col, Container, Form, Row } from "react-bootstrap";
// import CategorySelector from "../../../../../../components/CategorySelector";
import ValidationFeedback from "../../../../../../components/ValidationFeedback";
import TooltipButton from "../../../../../../components/TooltipButton";
import InputText from "../../../../../../components/InputText";
import {
  ValidationResult,
  useFormSubmitDelete,
} from "../../../../../../hooks/useFormSubmitDelete";
import { validateCategoryName } from "../utils/validators";
import { fetchers } from "../../../../../../serverApi/fetchers";
import { error } from "console";
import { fetchPost } from "../../../../../../utils";

type FormErrors = {
  categoryNameError: ErrorLog;
  generalError: ErrorLog;
};

type CategoryInfo = {
  categoryName: string;
};

type ModalCategoryEditionProps = {
  defaultCategory: string;
};
export function ModalCategoryEdition({
  defaultCategory,
}: ModalCategoryEditionProps) {
  const { showAlert } = useApp();
  const { allTasks, closeModal, refreshAllTasks } = useTasksList();

  const [categoryInfo, setCategoryInfo] = useState<CategoryInfo>({
    categoryName: defaultCategory,
  });

  const categoryAlreadyExists = (categoryName: string): boolean => {
    return !!allTasks.tasksByCategory.categories
      .map((category) => category.name)
      .filter((category) => category === categoryName).length;
  };

  const validateSubmitCategory = (categoryName: string): ValidationResult => {
    const validationRes = validateCategoryName(categoryName, allTasks);
    if (!validationRes.isValid) return validationRes;

    if (categoryName.trim() === defaultCategory) {
      const message = "Category was not changed.";
      return { isValid: false, message: message };
    }
    if (categoryAlreadyExists(categoryName.trim())) {
      const message = "Category " + categoryName + " already exists.";
      return { isValid: false, message: message };
    }
    return { isValid: true, message: null };
  };

  const validateDeleteCategory = (categoryName: string): ValidationResult => {
    if (categoryName.trim() !== defaultCategory) {
      const message = "Category was changed.";
      return { isValid: false, message: message };
    }
    return { isValid: true, message: null };
  };

  const validateSubmitData = (): boolean => {
    return validateSubmitCategory(categoryInfo.categoryName.trim()).isValid;
  };
  const validateDeleteData = (): boolean => {
    return validateDeleteCategory(categoryInfo.categoryName.trim()).isValid;
  };
  const sendSubmitData = (_doIfSuccess: () => void, _doAlways: () => void) => {
    const dataToSend = {
      oldCategoryName: defaultCategory,
      newCategoryName: categoryInfo.categoryName.trim(),
    };
    fetchers.account.admin.editPublicTaskCategory.fetch(dataToSend, {
      success: () => {
        _doIfSuccess();
        closeModal();
        refreshAllTasks();
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        _doAlways();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
    // const endpoint = "/admin/tasks/edit_category";
    // const dataToSend = {
    //   oldCategoryName: defaultCategory,
    //   newCategoryName: categoryInfo.categoryName.trim(),
    // };
    // fetchPost(endpoint, dataToSend, {
    //   success: () => {
    //     _doIfSuccess();
    //     closeModal();
    //     refreshAllTasks();
    //   },
    //   fail: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    //   always: () => {
    //     _doAlways();
    //   },
    //   error: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    // });
  };

  const sendDeleteData = (_doIfSuccess: () => void, _doAlways: () => void) => {
    if (
      window.confirm(
        "Are you sure you want to delete this category? All tasks under this category will be deleted."
      ) !== true
    ) {
      _doAlways();
      return;
    }
    const dataToSend = {
      categoryName: categoryInfo.categoryName,
    };
    fetchers.account.admin.deletePublicTaskCategory.fetch(dataToSend, {
      success: () => {
        _doIfSuccess();
        closeModal();
        refreshAllTasks();
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        _doAlways();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
    // const endpoint = "/admin/tasks/delete_category";

    // const dataToSend = {
    //   categoryName: categoryInfo.categoryName,
    // };
    // fetchPost(endpoint, dataToSend, {
    //   success: () => {
    //     _doIfSuccess();
    //     closeModal();
    //     refreshAllTasks();
    //   },
    //   fail: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    //   always: () => {
    //     _doAlways();
    //   },
    //   error: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    // });
  };
  const defaultFormErrors = {
    categoryNameError: null,
    generalError: null,
  };
  const getUpdatedFormErrors = (
    _categoryInfo: CategoryInfo | undefined = categoryInfo
  ): FormErrors => {
    const vDeleteRes = validateDeleteCategory(_categoryInfo.categoryName);
    const error = vDeleteRes.isValid
      ? vDeleteRes
      : validateSubmitCategory(_categoryInfo.categoryName);
    return { ...formErrors, categoryNameError: error.message };
  };
  const [
    formStatus,
    formErrors,
    handleSubmit,
    handleDelete,
    submitIsPossible,
    deleteIsPossible,
    updateFormErrors,
  ] = useFormSubmitDelete<FormErrors, CategoryInfo>(
    defaultFormErrors,
    validateSubmitData,
    validateDeleteData,
    sendSubmitData,
    sendDeleteData,
    getUpdatedFormErrors
  );

  const handleCategoryNameChange = (newCategoryName: string) => {
    const newCategoryNameTrimed = newCategoryName; //.trim();
    const newCategoryInfo = {
      ...categoryInfo,
      categoryName: newCategoryNameTrimed,
    };
    updateFormErrors(newCategoryInfo);
    setCategoryInfo(newCategoryInfo);
  };

  const submitOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && submitIsPossible) {
      handleSubmit();
    }
    if (event.key === 'Delete' && deleteIsPossible) {
      handleDelete();
    }
  }
  return (
    <Container className="w-auto mx-md-3 ModalTaskEdition" onKeyDown={submitOnEnter}>
      <Form.Group className="mb-3">
        <Form.Label>Category:</Form.Label>
        {/* <CategorySelector
          variant="combobox"
          categories={allTasks.tasksByCategory.categories.filter((category) => !category.isPublic).map(
            (category) => category.name
          )}
          defaultValue={categoryInfo.category}
          onSelect={handleCategoryNameChange}
          inputMaxChars={restrictions.maxTaskCategoryNameLength}
        /> */}
        <InputText
          value={categoryInfo.categoryName}
          onChange={handleCategoryNameChange}
          maxLength={restrictions.maxTaskCategoryNameLength}
          isInvalid={false}
        />
        <ValidationFeedback message={formErrors.categoryNameError} />
      </Form.Group>
      <Form.Group as={Row} className="mt-5">
        <Col className="d-flex justify-content-start">
          <TooltipButton
            tooltipContent={`Delete this category`}
            buttonVariant="outline-primary"
            className="px-4 delete"
            onClick={handleDelete}
            disabled={!deleteIsPossible}
          >
            {formStatus.isDeleting ? "Deleting" : "Delete"}
          </TooltipButton>
        </Col>
        <Col className="d-flex justify-content-end">
          <ValidationFeedback
            message={formErrors.generalError}
            className="text-end me-3"
          />
          <TooltipButton
            tooltipContent={`Save changes`}
            className="px-4 submit"
            onClick={handleSubmit}
            disabled={!submitIsPossible}
          >
            {formStatus.isSubmitting ? "Saving" : "Save"}
          </TooltipButton>
        </Col>
      </Form.Group>
    </Container>
  );
}
