import { useState } from "react";
import { MonthData } from "../context/YearRecordsTableContext"
import { DateTime } from "../../../../utils";

export function useSelectedMonth(monthsInfo: MonthData[] | undefined) {
    const [selectedMonth, setSelectedMonth] = useState<MonthData | null>(null);

    const selectMonth = (date: DateTime) => {
        if(!monthsInfo) return;
        const newSelectedMonth = monthsInfo.find(month => month.date.equalTo(date));
        if(newSelectedMonth !== undefined) {
            setSelectedMonth(newSelectedMonth);
        }
    }

    const resetSelecedMonth = () => {
        setSelectedMonth(null);
    }

    const selectNextMonth = () => {
        if(!monthsInfo || monthsInfo.length < 1) return;
        if(selectedMonth === null) {
            selectMonth(monthsInfo[0].date);
            return;
        }
        const currIndex = monthsInfo.findIndex(month => month.date.equalTo(selectedMonth.date));
        if (currIndex === -1 || currIndex === monthsInfo.length - 1){
            return;
        }
        const nextMonth = monthsInfo[currIndex + 1];
        selectMonth(nextMonth.date);
    }

    const selectPrevMonth = () => {
        if(!monthsInfo || monthsInfo.length < 1) return;
        if(selectedMonth === null) {
            selectMonth(monthsInfo[0].date);
            return;
        }
        const currIndex = monthsInfo.findIndex(month => month.date.equalTo(selectedMonth.date));
        if (currIndex === -1 || currIndex === 0){
            return;
        }
        const nextMonth = monthsInfo[currIndex - 1];
        selectMonth(nextMonth.date);
    }
    
    return [selectedMonth, selectMonth, resetSelecedMonth, selectNextMonth, selectPrevMonth] as const;

}