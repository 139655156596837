import { useState } from "react";
import { DateTime } from "../../../../utils";
import { MemberInfo } from "../context/ActiveMembersContext";

export function useSelectedMember(membersInfo: MemberInfo[] | undefined) {
    const [selectedMember, setSelectedMember] = useState<MemberInfo | null>(null);

    const selectMember = (id: string) => {
        if(!membersInfo) return;
        const newSelectedMember = membersInfo.find(member => member.member.id === id);
        if(newSelectedMember !== undefined) {
            setSelectedMember(newSelectedMember);
        }
    }

    const resetSelecedMember = () => {
        setSelectedMember(null);
    }

    const selectNextMember = () => {
        if(!membersInfo || membersInfo.length < 1) return;
        if(selectedMember === null) {
            selectMember(membersInfo[0].member.id);
            return;
        }
        const currIndex = membersInfo.findIndex(member => member.member.id === selectedMember.member.id);
        if (currIndex === -1 || currIndex === membersInfo.length - 1){
            return;
        }
        const nextMember = membersInfo[currIndex + 1];
        selectMember(nextMember.member.id);
    }

    const selectPrevMember = () => {
        if(!membersInfo || membersInfo.length < 1) return;
        if(selectedMember === null) {
            selectMember(membersInfo[0].member.id);
            return;
        }
        const currIndex = membersInfo.findIndex(member => member.member.id === selectedMember.member.id);
        if (currIndex === -1 || currIndex === 0){
            return;
        }
        const nextMember = membersInfo[currIndex - 1];
        selectMember(nextMember.member.id);
    }
    
    return [selectedMember, selectMember, resetSelecedMember, selectNextMember, selectPrevMember] as const;

}