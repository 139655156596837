import React from "react";
import { useTimerContext } from "../../../../../contexts/TimerContext";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import "./Player.scss";
import { useHotkeys } from 'react-hotkeys-hook';


type PayerButtonPropsType = {
  onClick?: React.MouseEventHandler,
  disabled?: boolean
  locked?: boolean
}

export function StartButton({ onClick, disabled = false, locked = false}: PayerButtonPropsType) {
  return (
    <Button variant="outline-primary" className="player_button rounded-circle" onClick={onClick} disabled={disabled || locked}>
      <FontAwesomeIcon icon={faPlay} style={{ marginLeft: "11%" }} />
    </Button>
  );
}
export function StopButton({ onClick, disabled = false, locked = false }: PayerButtonPropsType) {
  return (
    <Button variant="outline-primary" className="player_button rounded-circle" onClick={onClick} disabled={disabled}>
      <FontAwesomeIcon icon={faStop} />
    </Button>
  );
}

type PlayerPropsType = {
  disabled?: boolean;
  locked?: boolean;
}
export function Player({ disabled = false, locked = false }: PlayerPropsType) {
  const { timerStatus, startTimer, stopTimer } = useTimerContext();
  // Hotkeys
  useHotkeys('Shift+T', () => {
    if (!!timerStatus) {
      stopTimer();
    } else {
      startTimer();
    }
  }, [timerStatus]);
  return (
    <div style={{width: "33%"}} className="Player d-flex align-items-center justify-content-center">
        {timerStatus && <StopButton onClick={stopTimer} disabled={disabled} locked={locked}/>}
        {!timerStatus && <StartButton onClick={startTimer} disabled={disabled} locked={locked}/>}
    </div>
  );
}
