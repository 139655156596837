import { ReactNode, useRef } from "react";
import WidgetCard from "../../../components/WidgetCard";
import "./ActiveMembersList.scss";
import { ActiveMembersProvider } from "./context/ActiveMembersContext";
import Header from "./components/Header";
import MembersTable from "./components/MembersTable";

export function ActiveMembersList() {
  // const AMProvider = useRef(({ children }: { children: ReactNode }) => (
  //   <ActiveMembersProvider
  //     children={children}
  //   />
  // ));
  const AMProvider = ({ children }: { children: ReactNode }) => (
    <ActiveMembersProvider
      children={children}
    />
  );

  return (
    <WidgetCard className="ActiveMembersList" ContextProvider={AMProvider}>
      <WidgetCard.Header>
        <Header/>
      </WidgetCard.Header>
      <WidgetCard.Body>
        <MembersTable/>
      </WidgetCard.Body>
    </WidgetCard>
  );
}
