import {
  Button,
  ButtonGroup,
  Card as CardBS,
  Col,
  Dropdown,
  DropdownButton,
  Row,
} from "react-bootstrap";
import { useMonthRecords } from "../../context/MonthRecordsContext";
import { DateTime } from "../../../../../utils";
import { useEffect } from "react";
import DatePicker from "../../../../../components/DatePicker";
import DayStatusDropdownn from "../../../../../components/member/DayStatusDropdown";
import MonthStatusDropdownn from "../../../../../components/MonthStatusDropdown";
import { MonthStatus } from "../../../../../utils/MonthStatus";
import _ from "lodash";
import "./Header.scss";
import {
  sumHours,
  roundUpToNearestHalf,
} from "../../../../../utils/TimeMathFormules";
import MonthStatusUnresolvedButton from "../../../../../components/MonthStatusUnresolvedButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faFileArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import TooltipButton from "../../../../../components/TooltipButton";
import TimeHoursMinutes from "../../../../../components/TimeHoursMinutes";
import TimeMinutesInHours from "../../../../../components/TimeMinutesInHours";

export function Header() {
  const {
    month,
    setMonth,
    monthTimeRecordsInfo,
    changeMonthStatus,
    availableMonths,
    downloadTimesheet,
    fileAnchorRef,
    modalStatus
  } = useMonthRecords();

  var requestsUnresolved =
    monthTimeRecordsInfo.monthDaysInfo.filter(
      (day) => day.requestedStatus !== null
    ).length > 0;
  return (
    <div className="Header d-flex justify-content-start align-items-center">
      {/* Month & Status*/}
      <div className="d-flex border-end px-2 justify-content-center justify-content-c-sm-start align-items-center month-status">
        <div>
          {/* <DatePicker selected={month.date} dateFormat="MM.yyyy" showMonthYearPicker className="mx-1" readOnly={!setMonth} onChange={(month) => month && setMonth && setMonth(new DateTime(month))} /> */}
          <DatePicker
            date={month}
            setDate={setMonth}
            format={"monthOnly"}
            minDate={availableMonths.minMonth}
            maxDate={availableMonths.maxMonth}
            leftArrow={true}
            rightArrow={true}
            hotkeys = {!modalStatus.show}
            // leftArrowClassName="d-none d-sm-inline-block"
            // rightArrowClassName="d-none d-sm-inline-block"
          />
        </div>
        {!requestsUnresolved && (
          <div>
            {/* TO DO */}
            <MonthStatusDropdownn
              variant="small"
              status={monthTimeRecordsInfo.status}
              onChange={(status) => {
                changeMonthStatus(status);
              }}
            />
          </div>
        )}
        {requestsUnresolved && (
          <div>
            <MonthStatusUnresolvedButton variant="small" disabled={true} />
          </div>
        )}
      </div>

      <div className="d-flex justify-content-center justify-content-c-sm-start align-items-center time-totals">
      {/* Total Time */}
      <div className="d-flex justify-content-start align-items-center border-end px-2 total-time">
        <div className="title mx-1">T<span className="name d-c-md-none">otal</span><span className="d-none d-c-md-inline">otal time</span>:</div>
        <div className="hours mx-1">
          <TimeHoursMinutes
            totalMinutes={monthTimeRecordsInfo.totalMinutes}
            boldNumbers={true}
            larger={true}
          />
        </div>
      </div>


      {/* Documented Time */}
      <div className="d-flex justify-content-start align-items-center px-2 doc-time">
        <div className="title mx-1">D<span className="name d-c-md-none">oc.</span><span className="d-none d-c-md-inline d-c-lg-none">oc. time</span><span className="d-none d-c-lg-inline">ocumented time</span>:</div>
        <div className="hours mx-1">
        <TimeHoursMinutes
          totalMinutes={monthTimeRecordsInfo.documentedTotalMinutes}
          boldNumbers={true}
          larger={true}
        />
        </div>
        <div className="hours mx-1 ms-2 d-none d-c-md-block">
          <TimeMinutesInHours
            totalMinutes={monthTimeRecordsInfo.documentedTotalMinutes}
            inParens={true}
          />
        </div>
      </div>
      </div>

      {/* Download Timesheet */}
      {monthTimeRecordsInfo.status === MonthStatus.completed && !requestsUnresolved && (
        <div className="d-flex flex-fill justify-content-end align-items-center  downloadButton">
          <TooltipButton
            tooltipContent="Download Timesheet"
            buttonVariant="light"
            className="downloadTimesheet"
            onClick={() => {
              downloadTimesheet();
            }}
          >
            <span className="d-none d-lg-inline me-1">Download</span>
            <FontAwesomeIcon icon={faFileArrowDown} size="lg" />
          </TooltipButton>
          <a href="" download="" ref={fileAnchorRef} />
        </div>
      )}
    </div>
  );
}
