import { ReactNode, useRef } from "react";
import { DateTime } from "../../../utils";
import WidgetCard from "../../../components/WidgetCard";
import { User } from "../../../utils/Users";
// import Header from "./components/Header";
// import { MonthRecordsProvider } from "./context/MonthRecordsTableContext";
// import MonthsTable from "./components/MonthsTable";
import { Month, Year } from "../../../utils/TimeTypes";
import { MonthRecordsProvider, useMonthRecordsTable } from "./context/MonthRecordsTableContext";
import Header from "./components/Header";
import DaysTable from "./components/DaysTable";
import DayModal from "./components/DayModal";
import "./MemberMonthTimeRecordTable.scss";
import ViewSummaryButton from "../../../components/ViewSummaryButton";
import { createSearchParams, generatePath } from "react-router-dom";
import apiUrls from "../../../data/api.json";

type MemberMonthTimeRecordTableProps = {
  member: User;
  setMember: (newMember: User) => void;
  month: DateTime;
  setMonth: (month: DateTime) => void;
};

export function MemberMonthTimeRecordTable({
  member,
  setMember,
  month,
  setMonth
}: MemberMonthTimeRecordTableProps) {
  // const MRProvider = useRef(({ children }: { children: ReactNode }) => (
  //   <MonthRecordsProvider
  //     member={member}
  //     setMember={setMember}
  //     month={month}
  //     setMonth={setMonth}
  //     children={children}
  //   />
  // ));

  const MRProvider = ({ children }: { children: ReactNode }) => (
    <MonthRecordsProvider
      member={member}
      setMember={setMember}
      month={month}
      setMonth={setMonth}
      children={children}
    />
  );
  
  
  const getYearSummaryLink = (month: DateTime) => {
    const year = month.getStartOfYear();
    const queryParams = createSearchParams({
      year: year.getLocYear(),
    }).toString();
    return {
      pathname: generatePath(apiUrls.account.children.admin.children.memberYear, {
        userId: member.id,
      }),
      search: queryParams,
    };
  };
  return (
    <WidgetCard
      className="MemberMonthTimeRecordTable"
      ContextProvider={MRProvider}
      useContext={useMonthRecordsTable}
    >
      {!!setMonth && (<WidgetCard.TopExtras><ViewSummaryButton summaryLink={getYearSummaryLink(month)}/></WidgetCard.TopExtras>)}
      <WidgetCard.Header>
        <Header />
      </WidgetCard.Header>
      <WidgetCard.Body>
        <DaysTable />
      </WidgetCard.Body>
      <WidgetCard.Modal>
        <DayModal />
      </WidgetCard.Modal>
    </WidgetCard>
  );
}
