import { useEffect, useRef, useState } from "react";
import DayRecordsTable from "../../../DayRecordsTable";
import { useMonthRecords } from "../../context/MonthRecordsContext";
import "./DayModal.scss";

export function DayModal() {
const ref = useRef<null | HTMLDivElement>(null);
  const {month, modalStatus, changeModalDate} = useMonthRecords();
  const isContentLoaded = useRef<boolean>(false);
  useEffect(()=>{
    if(modalStatus.show) {
        // ref.current?.parentElement?.parentElement?.scrollIntoView({ behavior: 'smooth' });
      // ref.current?.parentElement?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [modalStatus])

  const onContentLoad = ()=>{
    if(!isContentLoaded.current && modalStatus.show){
      if(ref.current?.parentElement){
        const elementTop = ref.current?.parentElement.getBoundingClientRect().top + window.scrollY;
        const offsetTop = window.innerHeight * 0.05;
        window.scrollTo({top: elementTop-offsetTop, behavior: 'smooth'});
      }
      isContentLoaded.current = true;
    }
  }
  return (
    <div className="DayModal" ref={ref}>
    {modalStatus.date && (
          <DayRecordsTable date={modalStatus.date} setDate={changeModalDate} onlyMonth={month} embeded={true} onContentLoad={onContentLoad}/>
          )}
    </div>
)
}