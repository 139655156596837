import { useEffect, useRef } from "react";
import "./DayModal.scss";
import { useMonthRecordsTable } from "../../context/MonthRecordsTableContext";
import MemberDayTimeRecordTable from "../../../MemberDayTimeRecordTable";

export function DayModal() {
  const ref = useRef<null | HTMLDivElement>(null);
  const {month, modalStatus, member,
    setMember, changeModalDate} = useMonthRecordsTable();
  const isContentLoaded = useRef<boolean>(false);

  useEffect(() => {
    if (modalStatus.show) {
      // ref.current?.parentElement?.parentElement?.scrollIntoView({
      //   behavior: "smooth",
      // });
    }
  }, [modalStatus]);

  const onContentLoad = ()=>{
    if(!isContentLoaded.current && modalStatus.show){
      if(ref.current?.parentElement){
        const elementTop = ref.current?.parentElement.getBoundingClientRect().top + window.scrollY;
        const offsetTop = window.innerHeight * 0.05;
        window.scrollTo({top: elementTop-offsetTop, behavior: 'smooth'});
      }
      isContentLoaded.current = true;
    }
  }
  return (
    <div className="DayModal" ref={ref}>
      {modalStatus.status && <MemberDayTimeRecordTable member={member} setMember={setMember} date={modalStatus.status?.date} setDate={changeModalDate} onlyMonth={month} embeded={true} onContentLoad={onContentLoad}/>}
    </div>
  );
}
