import { useState } from "react";
import { TaskRecord, TaskRecordGroups } from "../../../../utils/TaskRecord";
import { TaskRecords } from "../../../../utils/TaskRecords";

export function useSelectedRecord(taskRecords: TaskRecords | undefined) {
  const [selectedRecord, setSelectedRecord] = useState<TaskRecord | null>(null);
 
  const selectRecord = (group: TaskRecordGroups, recordId: string) => {
    if (!taskRecords) return;
    var tasksForGroup = taskRecords.findGroup(group);
    if (!tasksForGroup) return;
    // Select
    if (selectedRecord == null) {
      setSelectedRecord(tasksForGroup[recordId]);
      return;
    }
    var isSameGrSelected = selectedRecord.group.valueOf() === group;
    var isSameIdSelected = selectedRecord.id === recordId;
    if (
      (isSameGrSelected && !isSameIdSelected) ||
      (!isSameGrSelected && isSameIdSelected) ||
      (!isSameGrSelected && !isSameIdSelected)
    ) {
      setSelectedRecord(tasksForGroup[recordId]);
      return;
    }
    // Unselect
    setSelectedRecord(null);
  };

  const resetSelecedRecord = () => {
    setSelectedRecord(null);
  }

  const selectNextRecord = () => {
    if(!taskRecords){
      return;
    }
    const records = taskRecords.allToArray();
    if (records.length < 1) {
      return;
    }
    if (selectedRecord === null) {
      const nextRecord = records[0];
      selectRecord(nextRecord.group, nextRecord.id);
      return;
    }
    const currIndex = records.findIndex(record => record.id === selectedRecord.id);
    if (currIndex === -1 || currIndex === records.length - 1){
      return;
    }
    const nextRecord = records[currIndex + 1];
    selectRecord(nextRecord.group, nextRecord.id);
  }

  const selectPrevRecord = () => {
    if(!taskRecords){
      return;
    }
    const records = taskRecords.allToArray();
    if (records.length < 1) {
      return;
    }
    if (selectedRecord === null) {
      const nextRecord = records[0];
      selectRecord(nextRecord.group, nextRecord.id);
      return;
    }
    const currIndex = records.findIndex(record => record.id === selectedRecord.id);
    if (currIndex === -1 || currIndex === 0){
      return;
    }
    const nextRecord = records[currIndex - 1];
    selectRecord(nextRecord.group, nextRecord.id);
  }

  return [selectedRecord, selectRecord, resetSelecedRecord, selectNextRecord, selectPrevRecord] as const;
}
