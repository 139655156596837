import { Col, Container, Row } from "react-bootstrap";
import Table from "../../../../../components/Table";
import { useDayRecords } from "../../context/DayRecordsTableContext";
import { TaskRecordGroups } from "../../../../../utils/TaskRecord";
import FullTaskName from "../../../../../components/FullTaskName";
import { Formatter2 as Formatter } from "../../../../member/DayRecordsTable/utils/Formatter2";
// import { Formatter2 as Formatter } from "../../../../../utils/Formatter2";
import "./RecordsTable.scss";
import FullTaskName2 from "../../../../../components/FullTaskName2";
import TimeHoursMinutes from "../../../../../components/TimeHoursMinutes";
import { useEffect, useRef } from "react";

export function RecordsTable() {
  const { dayTimeRecordsInfo, selectedRecord, selectRecord, onContentLoad } = useDayRecords();

  var billableTimerecords = dayTimeRecordsInfo.timeRecords.groupToArray(
    TaskRecordGroups.billableTimerecords
  );

  useEffect(()=>{
    if(dayTimeRecordsInfo != undefined){
      onContentLoad();
    }
  }, []);

  const ref = useRef<HTMLDivElement>(null);
  useEffect(()=>{
    if(selectedRecord && ref.current) {
      const recordElement = Array.from(ref.current.querySelectorAll('div .Table .Body')).map(el => el?.querySelector(`[data-id='${selectedRecord.id}']`)).find(el => el !== null);
      if(recordElement){
        const rect = recordElement.getBoundingClientRect();
        const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
        if (!isVisible) {
          if (rect.top < 0) {
            recordElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          } else {
            recordElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }
        }
      }
    }
  }, [selectedRecord]);

  return (
    <div className="RecordsTable" ref={ref}>
      <Container fluid="lg" className="billableTimerecords px-0">
        <Table>
          <Table.Head>
            <Row className="justify-content-between">
              <Col xs={4} className="text-start align-self-center">
                Task
              </Col>
              <Col xs={5} className="text-center align-self-center">
                Period
              </Col>
              <Col xs={3} className="text-center align-self-center">
                Total
              </Col>
            </Row>
          </Table.Head>
          <Table.Body>
            {billableTimerecords &&
              billableTimerecords.map((billableTimerecord) => (
                <Row
                  className={`justify-content-between align-items-center ${
                    selectedRecord &&
                    selectedRecord.group ===
                      TaskRecordGroups.billableTimerecords &&
                    selectedRecord.id === billableTimerecord.id
                      ? "active"
                      : ""
                  }`}
                  data-id={billableTimerecord.id}
                  key={billableTimerecord.id}
                  onClick={() =>
                    selectRecord(
                      TaskRecordGroups.billableTimerecords,
                      billableTimerecord.id
                    )
                  }
                >
                  <Col
                    xs={4}
                    className="text-start taskName"
                    style={{
                      wordBreak: "break-word",
                      hyphens: "auto",
                      maxHeight: "50px",
                      display: "flex",
                    }}
                  >
                    {/* {billableTimerecord.task.name} */}
                    <FullTaskName2
                      categoryName={billableTimerecord.task.category}
                      taskName={billableTimerecord.task.name}
                    />
                    {/* <span className="bold">{billableTimerecord.task.category + ": "}</span>{billableTimerecord.task.name} */}
                  </Col>
                  <Col xs={5} className="text-center">
                    {Formatter.formatPeriodOrManual(billableTimerecord.period)}
                  </Col>
                  <Col xs={3} className="text-center">
                    <TimeHoursMinutes
                      totalMinutes={billableTimerecord.durationMin}
                    />
                  </Col>
                </Row>
              ))}
          </Table.Body>
        </Table>
      </Container>
    </div>
  );
}
