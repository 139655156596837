import { Button, Col, Row } from "react-bootstrap";
import Table from "../../../../../components/Table";
import { useYearRecordsTable } from "../../context/YearRecordsTableContext";
import Placeholder from "../../../../../components/Placeholder";
import { Formatter } from "../../../../../utils/Formatter";
import TooltipButton from "../../../../../components/TooltipButton";
import Tooltip from "../../../../../components/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import MonthStatusDropdown from "../../../../../components/MonthStatusDropdown";
import "./MonthsTable.scss";
import _ from "lodash";
import MonthStatusUnresolvedButton from "../../../../../components/MonthStatusUnresolvedButton";
import TimeHoursMinutes from "../../../../../components/TimeHoursMinutes";
import TimeMinutesInHours from "../../../../../components/TimeMinutesInHours";
import { useEffect, useRef } from "react";

export function MonthsTable() {
  const {
    yearMonths,
    selectedMonth,
    selectMonth,
    resetSelecedMonth,
    // setSelectedMonth,
    changeMonthStatus,
    navigateToMonthRecords,
  } = useYearRecordsTable();

  const ref = useRef<HTMLDivElement>(null);
    useEffect(()=>{
      if(selectedMonth) {
        const monthElement = ref.current?.querySelector('.Table .Body')?.querySelector(`[id='${selectedMonth.date.getLocMonth().toString()}']`);
        if(monthElement){
          const rect = monthElement.getBoundingClientRect();
          const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
          if (!isVisible) {
            if (rect.top < 0) {
              monthElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            } else {
              monthElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
          }
        }
      }
    }, [selectedMonth]);

  return (
    <div className="MonthsTable" ref={ref}>
      <Table>
        <Table.Head>
          <Row className="justify-content-between">
            <Col xs={2} sm={2} md={1} className="text-center align-self-center">
              #
            </Col>
            <Col
              xs={undefined}
              sm={undefined}
              md={3}
              className="text-start text-md-center d-none d-md-block align-self-center"
            >
              Month
            </Col>
            <Col
              xs={4}
              sm={3}
              md={2}
              className="text-center align-self-center p-1"
              style={{ overflowWrap: "normal" }}
            >
              Working Time
            </Col>
            <Col
              xs={4}
              sm={3}
              md={2}
              className="text-center align-self-center p-1"
              style={{ overflowWrap: "normal" }}
            >
              Documented Time
              {/* <span className="d-sm-none">.</span><span className="d-none d-sm-inline">umented</span> */}
            </Col>
            <Col xs={2} sm={1} className="text-center align-self-center">
              <span className="d-none d-lg-block">Open</span>
            </Col>
            <Col
              xs={undefined}
              sm={3}
              className="text-center d-none d-sm-block align-self-center"
            >
              Status
            </Col>
          </Row>
        </Table.Head>
        <Table.Body>
          {yearMonths.map((month) => (
            <Row
              className={`justify-content-between align-items-center ${
                selectedMonth && selectedMonth.date.date === month.date.date
                  ? "active"
                  : ""
              }`}
              key={month.date.date.getTime()}
              id={month.date.getLocMonth().toString()}
              onClick={() => selectedMonth === month ? resetSelecedMonth() : selectMonth(month.date)}
            >
              {/* # */}
              <Col
                xs={2}
                sm={2}
                md={1}
                className="text-center"
                style={{ overflowWrap: "normal" }}
              >
                {month.date.getLocMonth()}
              </Col>
              {/* Month */}
              <Col
                xs={undefined}
                sm={undefined}
                md={3}
                className="text-left pl-1 d-none d-md-block"
              >
                <Row>
                  <Col md={3} className="d-none d-md-block"></Col>
                  <Col xs={12} md={9}>
                    <div className="d-none d-lg-block">
                      {Formatter.formatMonth(month.date, true)}
                    </div>
                    <div className="d-none d-md-block d-lg-none">
                      {Formatter.formatMonth(month.date, false)}
                    </div>
                  </Col>
                </Row>
              </Col>
              {/* Working Time */}
              <Col xs={4} sm={3} md={2} className="text-center">
                <TimeHoursMinutes
                  totalMinutes={month.totalMinutes}
                  lightLetters={true}
                />
              </Col>
              {/* Documented Time */}
              <Col xs={4} sm={3} md={2} className="text-center">
                <TimeHoursMinutes
                  totalMinutes={month.documentedTotalMinutes}
                  lightLetters={true}
                />
                <span className="ms-2 d-none d-sm-inline">
                  <TimeMinutesInHours
                    totalMinutes={month.documentedTotalMinutes}
                    inParens={true}
                  />
                </span>
              </Col>
              {/* Open */}
              <Col xs={2} sm={1} md={1} className="text-center">
                <div>
                  <TooltipButton
                    tooltipContent="Details"
                    buttonVariant="light"
                    className="openMonth"
                    onClick={() => navigateToMonthRecords(month.date)}
                  >
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      size="sm"
                      className="d-md-none"
                    />
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      className="d-none d-md-block"
                    />
                  </TooltipButton>
                </div>
              </Col>

              {/* Status */}
              <Col
                xs={undefined}
                sm={3}
                md={3}
                className="text-center d-none d-sm-block"
              >
                {/* {day.status} */}
                {!month.hasStatusChangesRequests && (
                  <Tooltip tooltipContent="Change status" className="openDay">
                    <span>
                      <MonthStatusDropdown
                        status={month.status}
                        onChange={(newStatus) => {
                          changeMonthStatus(
                            month.date,
                            month.status,
                            newStatus
                          );
                        }}
                      />
                    </span>
                  </Tooltip>
                )}
                {month.hasStatusChangesRequests && (
                  <div>
                    <MonthStatusUnresolvedButton disabled={true} />
                  </div>
                )}
              </Col>
            </Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}
