import _ from "lodash";
import { DateTime } from "../../../../utils"
import { useEffect, useState } from "react";

type TimeCountProps = {
    deadline: DateTime
}
export function TimeCount({deadline}: TimeCountProps) {
    const calcTimeLeft = () => {
        const remainingSeconds =  deadline.substractInSec(new DateTime());
        const hours = _.floor(_.divide(remainingSeconds, 3600));
        const minutes = _.floor(_.divide((remainingSeconds % 3600),60));
        const seconds = _.floor(remainingSeconds % 60);

        return {hours: hours, minutes: minutes, seconds: seconds}
    }
    const [time, setTime] = useState<{
        hours: number;
        minutes: number;
        seconds: number;
    }>(calcTimeLeft());

    useEffect(() => {
        const intervalId = setInterval(() => {
          setTime(calcTimeLeft());
        }, 995);
        return () => clearInterval(intervalId);
      }, []);

    return(<span>{_.padStart(time.hours.toString(), 2, '0')}:{_.padStart(time.minutes.toString(), 2, '0')}:{_.padStart(time.seconds.toString(), 2, '0')}</span>)
}