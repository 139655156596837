import {
  ReactNode,
  useEffect,
  useState,
} from "react";
import { DateTime } from "../../../../utils";
import { useApp } from "../../../../contexts/AppContext";
import { MonthStatus } from "../../../../utils/MonthStatus";
import alertMessages from "../../../../data/alertMessages.json";
import { logValidationError } from "../../../../utils/logError";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import apiUrls from "../../../../data/api.json";
import Placeholder from "../../../../components/Placeholder";
import { roundMinutesToHours, sumHours, roundUpToNearestHalf } from "../../../../utils/TimeMathFormules";
import _ from "lodash";
import { useRoleAuth } from "../../../../contexts/RoleAuthContext/RoleAuthContext";
import { fetchers } from "../../../../serverApi/fetchers";
import { createContext } from "../../../../hooks/useCreateContext";
import { useSelectedMonth } from "../hooks/useSelectedMonth";
import { useHotkeys } from "react-hotkeys-hook";

type MonthDaysTotalWorkTimeMins = { date: DateTime; hours: number }[];

export type MonthData = {
  date: DateTime;
  totalMinutes: number;
  documentedTotalMinutes: number;
  status: MonthStatus;
  hasStatusChangesRequests: boolean;
};
export type YearMonthsData = MonthData[];

type AvailableYears = {
  minYear: DateTime;
  maxYear: DateTime;
};


type YearRecordsTableContextVal = {
  year: DateTime;
  setYear: (year: DateTime) => void;
  availableYears: AvailableYears;
  yearMonths: YearMonthsData;
  selectedMonth: MonthData | null;
  selectMonth: (date: DateTime) => void;
  resetSelecedMonth: () => void;
  selectNextMonth: () => void;
  selectPrevMonth: () => void;
  // setSelectedMonth: (selectedMonth: MonthData | null) => void;
  changeMonthStatus: (
    month: DateTime,
    oldStatus: MonthStatus,
    newStatus: MonthStatus
  ) => void;
  navigateToMonthRecords: (month: DateTime) => void;
};
const [YearRecordsContext, useYearRecordsTable] =
  createContext<YearRecordsTableContextVal>();
export { useYearRecordsTable };

type YearRecordsProviderProps = {
  children: ReactNode;
  year: DateTime;
  setYear: (year: DateTime) => void;
};

export function YearRecordsProvider({
  children,
  year,
  setYear,
}: YearRecordsProviderProps) {
  const { auth } = useRoleAuth();
  const { showAlert } = useApp();
  const navigate = useNavigate();

  const [yearMonths, setYearMonths] = useState<YearMonthsData | undefined>(
    undefined
  );
  const [selectedMonth, selectMonth, resetSelecedMonth, selectNextMonth, selectPrevMonth] = useSelectedMonth(yearMonths);
  // const [selectedMonth, setSelectedMonth] = useState<MonthData | null>(null);

  const availableYears: AvailableYears = {
    minYear: DateTime.createLocDateTime(
      auth.created.getLocYear(),
      auth.created.getLocMonth()
    ),
    maxYear: new DateTime(),
  };

  const yearMonthsRefresh = () => {
    const dataToSend = {
      date: year,
    };
    fetchers.account.member.yearInfo.fetch(dataToSend, {
      success: (data, status) => {
        const newYearMonthsData  = data.months.map((month) => {
          return {
            date: month.monthDate,
            status: month.status,
            hasStatusChangesRequests: month.hasStatusChangesRequests,
            totalMinutes: month.totalMinutes,
            documentedTotalMinutes: month.documentedTotalMinutes,
          }
        })
        setYearMonths(newYearMonthsData);
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      }
    })
  };

  useEffect(() => {
    yearMonthsRefresh();
  }, []);

  const changeMonthStatus = (
    month: DateTime,
    oldStatus: MonthStatus,
    newStatus: MonthStatus
  ) => {
    if (oldStatus === newStatus) {
      return;
    }
    var valid =
      (oldStatus === MonthStatus.completed &&
        newStatus === MonthStatus.submitted) ||
      (oldStatus === MonthStatus.submitted &&
        (newStatus === MonthStatus.completed ||
          newStatus === MonthStatus.editable)) ||
      (oldStatus === MonthStatus.editable && newStatus === MonthStatus.submitted);
    if (!valid) {
      showAlert(alertMessages.invalidData);
      logValidationError("Wrong status.");
      return;
    }

    if (
      oldStatus === MonthStatus.editable &&
      newStatus === MonthStatus.submitted
    ) {
      if (
        window.confirm(
          'Are you sure you want to change the month\'s status to "submitted"? This action means you will no longer be able to make changes to the time records for this month.'
        ) !== true
      ) {
        return;
      }
    }

    setYearMonths(undefined);
    // const endpointChangeMonthStatus = serverApi.changeMonthStatus;
    // const dataToSend = {
    //   date: month.toJSON(),
    //   newStatus: newStatus,
    // };
    // const doIfSuccess = undefined;
    // const doIfFailed = () => {
    //   showAlert(alertMessages.somethingWentWrong);
    // };
    // const doAlways = () => {
    //   yearMonthsRefresh();
    // };
    // const doIfError = () => {
    //   showAlert(alertMessages.somethingWentWrong);
    // };
    // fetchPost<{}>(
    //   endpointChangeMonthStatus,
    //   dataToSend,
    //   doIfSuccess,
    //   doIfFailed,
    //   doAlways,
    //   doIfError
    // );
    const dataToSend = {
      date: month,
      newStatus: newStatus,
    };
    fetchers.account.member.changeMonthStatus.fetch(dataToSend, {
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        yearMonthsRefresh();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      }
    });
  };

  const navigateToMonthRecords = (month: DateTime) => {
    const monthValNum = month.getLocMonth();
    const monthVal = monthValNum < 10 ? "0" + monthValNum : "" + monthValNum;
    const queryParams = createSearchParams({
      month: monthVal + "." + month.getLocYear(),
    }).toString();
    navigate({
      pathname: apiUrls.account.children.member.children.monthRecords,
      search: queryParams,
    });
  };

  // Hotkeys
  useHotkeys('Ctrl+ArrowUp', () => {
    selectPrevMonth();
  }, [selectedMonth, yearMonths]);
  useHotkeys('Ctrl+ArrowDown', () => {
    selectNextMonth();
  }, [selectedMonth, yearMonths]);
  useHotkeys('Ctrl+Space', () => {
    resetSelecedMonth();
  }, [selectedMonth, yearMonths]);
  useHotkeys('Shift+O', () => {
    if(selectedMonth){
      navigateToMonthRecords(selectedMonth.date);
    }
  }, [selectedMonth, yearMonths]);
  
  return (
    <>
      {yearMonths && (
        <YearRecordsContext.Provider
          value={{
            year,
            setYear,
            availableYears,
            yearMonths,
            selectedMonth,
            selectMonth,
            resetSelecedMonth,
            selectNextMonth,
            selectPrevMonth,
            // setSelectedMonth,
            changeMonthStatus,
            navigateToMonthRecords,
          }}
        >
          {children}
        </YearRecordsContext.Provider>
      )}
      {!yearMonths && <Placeholder />}
    </>
  );
}
