import { ReactNode, useEffect, useState } from "react";
import { DateTime } from "../../../../utils";
import alertMessages from "../../../../data/alertMessages.json";
import { TaskRecord, TaskRecordGroups } from "../../../../utils/TaskRecord";
import { TaskRecords } from "../../../../utils/TaskRecords";
import { useSelectedRecord } from "../hooks/useSelectedRecord";
import { moveSelectedTaskToBillableTimerecords } from "../utils/moveSelectedTaskToBillableTimerecords";
import { moveSelectedTaskToUnverifiedTimerecords } from "../utils/moveSelectedTaskToUnverifiedTimerecords";
import { useModal, Modal, OpenModalProps } from "../hooks/useModal";
import { DayStatus } from "../../../../utils/DayStatus";
import { useApp } from "../../../../contexts/AppContext";
import { useTimerContext } from "../../../../contexts/TimerContext";
import { useNavigate } from "react-router-dom";
import apiUrls from "../../../../data/api.json";
import { Placeholder } from "react-bootstrap";
import { useRoleAuth } from "../../../../contexts/RoleAuthContext/RoleAuthContext";
import { fetchers } from "../../../../serverApi/fetchers";
import { createContext } from "../../../../hooks/useCreateContext";
import { usePrevious } from "../../../../hooks/usePreviousValue";
import { Month } from "../../../../utils/TimeTypes";
import { useHotkeys } from "react-hotkeys-hook";

// export enum DayStatus {
//   editable = "editable",
//   submitted = "submitted",
//   completed = "completed"
// }

type Status = {
  status: DayStatus;
  editMode: boolean;
  requestedStatus: DayStatus | null;
};
type DayTimeRecordsInfo = {
  status: DayStatus;
  editMode: boolean;
  requestedStatus: DayStatus | null;
  // totalHours: number;
  totalMinutes: number;
  timeRecords: TaskRecords;
};

type AvailableDays = {
  minDay: DateTime;
  maxDay: DateTime;
  excludedDays: DateTime[];
};

type DayRecordsContextVal = {
  date: DateTime;
  setDate?: (date: DateTime) => void;
  availableDays: AvailableDays;
  // taskTrackingData: TaskRecords;
  refreshDay: () => void;
  // dayStatus: Status;
  dayTimeRecordsInfo: DayTimeRecordsInfo;
  changeDayStatus: (newStatus: DayStatus) => void;

  selectedRecord: TaskRecord | null;
  selectRecord: (group: TaskRecordGroups, recordId: string) => void;

  // manageCreatingRecord: () => void;
  // manageEditingRecord: () => void;

  moveSelectedToBillableTimerecords: () => void;
  moveSelectedToUnverifiedTimerecords: () => void;

  modalStatus: Modal;
  openModal: (isEdition: boolean) => void;
  closeModal: () => void;
  onContentLoad: () => void;
};
const [DayRecordsContext, useDayRecords] =
  createContext<DayRecordsContextVal>();
export { useDayRecords };

type DayRecordsProviderProps = {
  children: ReactNode;
  date: DateTime;
  setDate?: (date: DateTime) => void;
  onlyMonth?: DateTime;
  onContentLoad: ()=>void;
};

export function DayRecordsProvider({
  children,
  date,
  setDate,
  onlyMonth,
  onContentLoad
}: DayRecordsProviderProps) {
  const { showAlert } = useApp();
  const { auth } = useRoleAuth();

  const { childrenRefresh, timerDataRefresh, disableTimer, lockTimer } =
    useTimerContext();
  // const [dayStatus, setDayStatus] = useState<
  //   | {
  //       status: DayStatus;
  //       editMode: boolean;
  //       requestedStatus: DayStatus | null;
  //     }
  //   | undefined
  // >();
  // const [taskTrackingData, setTaskTrackingData] = useState<
  //   TaskRecords | undefined
  // >();
  const [dayTimeRecordsInfo, setDayTimeRecordsInfo] = useState<
    DayTimeRecordsInfo | undefined
  >();
  const [selectedRecord, selectRecord, resetSelecedRecord, selectNextRecord, selectPrevRecord] = useSelectedRecord(
    dayTimeRecordsInfo?.timeRecords
  );
  const [modalStatus, openModal, closeModal] = useModal(selectedRecord);

  const authMonthCreated = new DateTime();
  authMonthCreated.setLocDateTime(
    auth.created.getLocYear(),
    auth.created.getLocMonth(),
    1,
    0,
    0,
    0
  );

  const [availableDays, setAvailableDays] = useState<
    AvailableDays | undefined
  >();

  // const availableDays: AvailableDays = {
  //   minDay: DateTime.createLocDateTime(
  //     auth.created.getLocYear(),
  //     auth.created.getLocMonth()
  //   ),
  //   maxDay: new DateTime(),
  // };

  const taskTrackingDataRefresh = () => {
    const dataToSend = {
      date: date,
    };
    fetchers.account.member.dateInfo.fetch(dataToSend, {
      success: (data, status) => {
        // setTaskTrackingData(data.taskRecords);
        // setDayStatus({
        //   status: data.status,
        //   requestedStatus: data.requestedStatus,
        //   editMode: data.status === DayStatus.editable,
        // });
        setDayTimeRecordsInfo({
          status: data.status,
          requestedStatus: data.requestedStatus,
          editMode: data.status === DayStatus.editable,
          // totalHours: data.totalHours,
          totalMinutes: data.totalMinutes,
          timeRecords: data.taskRecords,
        });
        resetSelecedRecord();
        if (data.status === DayStatus.editable) {
          disableTimer();
        }
        timerDataRefresh();
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
  };
  const checkDateIsAvailable = (date: DateTime, dateStatus: DayStatus) => {
    const lastMonthDay = (new DateTime()).getEndOfMonth();
    const startOfLastMonthWeek = lastMonthDay.addDays(-14);
    if (new DateTime().graterThan(startOfLastMonthWeek)) {
      return true;
    }
    const afterToday = date.graterThan(new DateTime())
    if (!afterToday){
      return true;
    }
    // after today
    if (dateStatus === DayStatus.submitted || dateStatus === DayStatus.completed) {
      return true;
    }
    return false;
  }
  const availableDaysRefresh = () => {
    const dataToSend = {
      date: date,
    };
    fetchers.account.member.monthStatusesInfo.fetch(dataToSend, {
      success: (data, status) => {
        const excludedDays = data.monthDays.filter(
          (day) => !checkDateIsAvailable(day.date, day.status)
        );
        setAvailableDays(onlyMonth ? {
          minDay: onlyMonth.getStartOfMonth(),
          maxDay: onlyMonth.getEndOfMonth(),
          excludedDays: excludedDays ? excludedDays.map((day) => day.date) : [],
        } : {
          minDay: auth.created.getStartOfMonth(),
          maxDay: new DateTime().getEndOfMonth(),
          excludedDays: excludedDays ? excludedDays.map((day) => day.date) : [],
        });
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
  };
  
  const refreshDay = () => {
    availableDaysRefresh();
    taskTrackingDataRefresh();
  };

  useEffect(() => {
    refreshDay();
  }, [date.date, childrenRefresh]);

  const changeDayStatus = (newStatus: DayStatus) => {
    if (!dayTimeRecordsInfo || newStatus === dayTimeRecordsInfo.status) {
      return;
    }
    // setTaskTrackingData(undefined);
    // setDayStatus(undefined);
    setDayTimeRecordsInfo(undefined);
    // const endpointChangeDayStatus = serverApi.changeDayStatus;
    // const dataToSend = {
    //   date: date.toJSON(),
    //   newStatus: newStatus,
    // };
    // const doIfSuccess = undefined;
    // const doIfFailed = () => {
    //   showAlert(alertMessages.somethingWentWrong);
    // };
    // const doAlways = () => {
    //   taskTrackingDataRefresh();
    // };
    // const doIfError = () => {
    //   showAlert(alertMessages.somethingWentWrong);
    // };
    // fetchPost<{}>(
    //   endpointChangeDayStatus,
    //   dataToSend,
    //   doIfSuccess,
    //   doIfFailed,
    //   doAlways,
    //   doIfError
    // );
    // if(dayTimeRecordsInfo.status === DayStatus.editable && newStatus  !== DayStatus.editable){
    //   lockTimer();
    // }
    const dataToSend = {
      date: date,
      newStatus: newStatus,
    };
    fetchers.account.member.changeDateStatus.fetch(dataToSend, {
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        taskTrackingDataRefresh();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
  };

  const moveSelectedToBillableTimerecords = () => {
    console.log("m", selectedRecord)
    moveSelectedTaskToBillableTimerecords(
      date,
      selectedRecord,
      taskTrackingDataRefresh,
      showAlert
    );
  };
  const moveSelectedToUnverifiedTimerecords = () => {
    moveSelectedTaskToUnverifiedTimerecords(
      date,
      selectedRecord,
      taskTrackingDataRefresh,
      showAlert
    );
  };

  // Hotkeys
  useHotkeys('Ctrl+ArrowUp', () => {
    selectPrevRecord();
  }, [selectedRecord, dayTimeRecordsInfo]);
  useHotkeys('Ctrl+ArrowDown', () => {
    selectNextRecord();
  }, [selectedRecord, dayTimeRecordsInfo]);
  useHotkeys('Ctrl+Space', () => {
    resetSelecedRecord();
  }, [selectedRecord, dayTimeRecordsInfo]);

  return (
    <>
      {dayTimeRecordsInfo && availableDays && (
        // taskTrackingData && dayStatus &&
        <DayRecordsContext.Provider
          value={{
            date,
            setDate,
            availableDays,
            // taskTrackingData,
            refreshDay,
            // dayStatus,
            dayTimeRecordsInfo,
            changeDayStatus,

            selectedRecord,
            selectRecord,

            moveSelectedToBillableTimerecords,
            moveSelectedToUnverifiedTimerecords,

            modalStatus,
            openModal,
            closeModal,
            onContentLoad
          }}
        >
          {children}
        </DayRecordsContext.Provider>
      )}
      {(!dayTimeRecordsInfo || !availableDays) && (
        // || !taskTrackingData || !dayStatus
        <Placeholder />
      )}
    </>
  );
}
