import { useRef } from "react";
import { ModalStatus, useModal } from "../../../../hooks/useModal";
import { DateTime } from "../../../../utils";
import { Formatter } from "../../../../utils/Formatter";

export enum ModalDayTimeRecordsModes {
  Readonly = "readonly",
}

export type OpenDayTimeRecordsModalStatus = {
  mode: ModalDayTimeRecordsModes.Readonly;
  status: { date: DateTime };
};

export function useDayTimeRecordsModal(
  month: DateTime,
  refreshMonth: (doAlways?: () => void) => void
): {
  readonly modalStatus: ModalStatus<OpenDayTimeRecordsModalStatus>;
  readonly openDayTimeRecordsModal: (date: DateTime) => void;
  readonly closeDayTimeRecordsModal: () => void;
  readonly changeModalDate: (date: DateTime) => void;
} {
  const { modalStatus, openModal, closeModal } =
    useModal<OpenDayTimeRecordsModalStatus>();
  const positionY = useRef<number | undefined>(undefined);
  const openDayTimeRecordsModal = (date: DateTime) => {
    if (date.getStartOfMonth().equalTo(month.getStartOfMonth())) {
      positionY.current = window.scrollY;
      openModal({
        mode: ModalDayTimeRecordsModes.Readonly,
        status: {
          date: date,
        },
      });
    }
  };
  const closeDayTimeRecordsModal = () => {
    const date = modalStatus.status?.date;
    closeModal();
    const doAlways = date
      ? () => {
          const day = document.getElementById(date.getLocDay().toString());
          if (day) {
            // day.scrollIntoView({ behavior: "smooth", block: "center" });
            if(positionY.current != undefined)
              window.scrollTo(0, positionY.current)
          }
        }
      : undefined;
    refreshMonth(undefined);
    doAlways && doAlways();
  };
  const changeModalDate = (date: DateTime) => {
    if (date.getStartOfMonth().equalTo(month.getStartOfMonth())) {
      if (modalStatus.show && modalStatus.mode) {
        openModal({
          mode: modalStatus.mode,
          status: {
            date: date,
          },
        });
      }
    }
  };
  return {
    modalStatus,
    openDayTimeRecordsModal,
    closeDayTimeRecordsModal,
    changeModalDate,
  } as const;
}
