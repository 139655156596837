import "./InputText.scss"
import { Form } from "react-bootstrap";
type InputTextProps = {
    value?: string,
    defaultValue?: string,
    onChange?: (value: string) => void;
    onInput?: () => void;
    isInvalid?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    maxLength?: number;
    placeholder?: string;
    className?: string;
    spellCheck?: boolean;
    autoFocus?: boolean;
}
export function InputText({value, defaultValue, onChange, onInput, isInvalid, readOnly, disabled, maxLength, placeholder="", className="", autoFocus = false, spellCheck}: InputTextProps){
    return (
        <Form.Control className={`InputText ${className}`} type="text" value={value} defaultValue={defaultValue} maxLength={maxLength} onChange={onChange ? (e) => onChange(e.target.value): undefined} onInput={onInput} isInvalid={isInvalid} readOnly={readOnly} disabled={disabled} placeholder={placeholder} spellCheck={spellCheck} autoFocus={autoFocus}/>
    )
}