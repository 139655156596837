import { Col, Container, Form, Row } from "react-bootstrap";
import {
  InputTimeHM,
  TimeInputHM,
} from "../../../../../../../../../components/TimeInput";
import TooltipButton from "../../../../../../../../../components/TooltipButton";
import { DateTime, Task } from "../../../../../../../../../utils";
import { Tasks } from "../../../../../../../../../utils/Task";
import { useEffect, useRef, useState } from "react";
import {
  Hours,
  Minutes,
  Time,
  TimeHM,
} from "../../../../../../../../../utils/TimeTypes";
import {
  TaskRecord,
  TaskRecordGroups,
} from "../../../../../../../../../utils/TaskRecord";
import "./ModalEditManualTimeRecord.scss";
import _ from "lodash";
import { useDayRecords } from "../../../../../../context/DayRecordsContext";
import alertMessages from "../../../../../../../../../data/alertMessages.json";
import { useApp } from "../../../../../../../../../contexts/AppContext";
import TaskSelector from "../../../../../../../../../components/TaskSelector";
// import ValidationFeedback from "../../../ValidationFeedback";
// import { useFormErrors } from "../../../../hooks/useFormErrors";
import ValidationFeedback from "../../../../../../../../../components/ValidationFeedback";
import { fetchers } from "../../../../../../../../../serverApi/fetchers";
import { useFormSubmitDelete } from "../../../../../../../../../hooks/useFormSubmitDeleteAdv";
import restrictions from "../../../../../../../../../data/restrictions.json";

type TimeRecordInfo = {
  id: string;
  task: Task;
  durationMin: number | undefined;
  group: TaskRecordGroups;
};

type FormInputs = {
  task: Task;
  duration: TimeHM | undefined;
};

type FormErrors = {
  generalError: string | null;
  durationError: string | null;
};

type ModalEditManualTimeRecordProps = {
  timeRecord: TaskRecord;
  deleteRecord: (doIfSuccess: () => void, doAlways: () => void) => void;
  tasks: Tasks;
};
export function ModalEditManualTimeRecord({
  timeRecord,
  deleteRecord,
  tasks,
}: ModalEditManualTimeRecordProps) {
  if (timeRecord.period.endedAt !== null) {
    throw Error();
  }
  const { showAlert } = useApp();
  const { date, closeModal, refreshDay, dayTimeRecordsInfo } = useDayRecords();

  const newTimeRecordInfo = useRef<TimeRecordInfo>({
    id: timeRecord.id,
    task: timeRecord.task,
    durationMin: timeRecord.durationMin,
    group: timeRecord.group,
  });

  const [formInputs, _setFormInputs] = useState<FormInputs>({
    task: timeRecord.task,
    duration: {
      hours: _.floor(_.divide(timeRecord.durationMin, 60)) as Hours,
      minutes: (timeRecord.durationMin % 60) as Minutes,
    },
  });
  const setFormInputs = (newFormInput: FormInputs) => {
    const newDuration =
      newFormInput.duration === undefined
        ? undefined
        : newFormInput.duration.hours * 60 + newFormInput.duration.minutes;
    newTimeRecordInfo.current = {
      ...newTimeRecordInfo.current,
      task: newFormInput.task,
      durationMin: newDuration,
    };
    _setFormInputs(newFormInput);
  };
  const timeRecordUnchanged = (timeRecordInfo: TimeRecordInfo) =>
    _.isEqual(
      [timeRecordInfo.task.id, timeRecordInfo.durationMin],
      [timeRecord.task.id, timeRecord.durationMin]
    );

  const submitValidator = (
    timeRecordInfo: TimeRecordInfo,
    formErrors: FormErrors
  ) => {
    if (timeRecordUnchanged(timeRecordInfo)) {
      formErrors.generalError = "Time record was not changed.";
      return false;
    }
    if (timeRecordInfo.durationMin === undefined) {
      formErrors.durationError = "Wrong duration format.";
      return false;
    }
    if (timeRecordInfo.durationMin < 1) {
      formErrors.durationError =
        "Duration is too short. Minimum 1 min is required.";
      return false;
    }
    if (timeRecordInfo.durationMin > restrictions.impossibleWorkingDurationHours*60) {
      formErrors.durationError = "Duration exceeds " + restrictions.impossibleWorkingDurationHours + " hours.";
      return false;
    }
    if (timeRecordInfo.group === TaskRecordGroups.billableTimerecords) {
      if (timeRecordInfo.durationMin > restrictions.maxPossibleWorkingDurationPerDayHours * 60) {
        formErrors.durationError =
          "Duration is too long. Maximum " + restrictions.maxPossibleWorkingDurationPerDayHours + " hours are allowed.";
        return false;
      }
      var expectedTotalMin = dayTimeRecordsInfo.totalMinutes + timeRecordInfo.durationMin - timeRecord.durationMin;
        // taskTrackingData.sumDurationForGroup(TaskRecordGroups.billableTimerecords) -
        // timeRecord.durationMin +
        // timeRecordInfo.durationMin;
      if (expectedTotalMin > restrictions.maxPossibleWorkingDurationPerDayHours * 60) {
        formErrors.durationError =
          "Total working duration for the date should ne no longer than " + restrictions.maxPossibleWorkingDurationPerDayHours + " hours.";
        return false;
      }
    }
    return true;
  };

  const defaultFormErrorsValues: FormErrors = {
    generalError: null,
    durationError: null,
  };
  Object.freeze(defaultFormErrorsValues);

  const validateSubmitData = (timeRecordInfo: TimeRecordInfo) => {
    let formErrors = { ...defaultFormErrorsValues };
    const isValid = submitValidator(timeRecordInfo, formErrors);
    return { isValid: isValid, formErrors: formErrors };
  };

  const deleteValidator = (
    timeRecordInfo: TimeRecordInfo,
    formErrors: FormErrors
  ) => {
    const unchanged = timeRecordUnchanged(timeRecordInfo);
    if (!unchanged) {
      formErrors.generalError = "Time record was changed.";
    }
    return unchanged;
  };
  const validateDeleteData = (timeRecordInfo: TimeRecordInfo) => {
    let formErrors = { ...defaultFormErrorsValues };
    const isValid = deleteValidator(timeRecordInfo, formErrors);
    return { isValid: isValid, formErrors: formErrors };
  };

  const sendSubmitData = (
    timeRecordInfo: TimeRecordInfo,
    _doIfSuccess: () => void,
    _doAlways: () => void
  ): void => {
    if (timeRecordInfo.durationMin === undefined) {
      showAlert(alertMessages.somethingWentWrong);
      return;
    }
    const dataToSend = {
      date: date,
      recordId: timeRecordInfo.id,
      taskId: timeRecordInfo.task.id,
      durationMin: timeRecordInfo.durationMin,
      group: timeRecordInfo.group,
    };
    fetchers.account.member.editManualTimerecord.fetch(dataToSend, {
      success: () => {
        _doIfSuccess();
        closeModal();
        refreshDay();
      },
      fail: (data, status) => {
        if(status === 443){
          showAlert((alertMessages.changesNotSaved, (alertMessages.timeRecordDurationNoLongerThan.replace("{{maxTimerecordDuration}}", restrictions.maxPossibleWorkingDurationPerDayHours + " hours"))));
          return;
        }
        if(status === 445){
          showAlert((alertMessages.changesNotSaved, (alertMessages.timeRecordDurationIsLongerThan.replace("{{maxTimerecordDuration}}", restrictions.impossibleWorkingDurationHours + " hours"))));
          return;
        }
        if(status === 447){
          showAlert((alertMessages.changesNotSaved, (alertMessages.totalWorkingDurationIsLongerThan.replace("{{maxTotalWorkingDuration}}", restrictions.maxPossibleWorkingDurationPerDayHours + " hours"))));
          return;
        }
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        _doAlways();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
  };
  const sendDeleteData = (
    timeRecordInfo: TimeRecordInfo,
    _doIfSuccess: () => void,
    _doAlways: () => void
  ) => {
    deleteRecord(_doIfSuccess, _doAlways);
  };

  const [
    formStatus,
    formErrors,
    handleSubmit,
    handleDelete,
    submitIsPossible,
    deleteIsPossible,
  ] = useFormSubmitDelete<FormErrors, TimeRecordInfo>(
    defaultFormErrorsValues,
    newTimeRecordInfo.current,
    validateSubmitData,
    validateDeleteData,
    sendSubmitData,
    sendDeleteData
  );

  const handleTaskChange = (newTask: Task) => {
    const newFormInputs = { ...formInputs, task: newTask };
    setFormInputs(newFormInputs);
  };
  const handleDurationChange = (newDuration: TimeHM | undefined) => {
    const duratoinVal =
      // newDuration === undefined
      //   ? { hours: 0 as Hours, minutes: 0 as Minutes }
      //   : 
        newDuration;
    const newFormInputs = { ...formInputs, duration: duratoinVal };
    setFormInputs(newFormInputs);
  };

  const submitOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && submitIsPossible) {
      handleSubmit();
    }
    if (event.key === 'Delete' && deleteIsPossible) {
      handleDelete();
    }
  }
  return (
    <Container
      className={`w-auto mx-md-3 ModalEditManualTimeRecord ${
        timeRecord.group === TaskRecordGroups.unverifiedTimerecords
          ? "Unverified"
          : ""
      }`}
      onKeyDown={submitOnEnter}
    >
      <Form.Group className="mb-3">
        <Form.Label>Task:</Form.Label>
        <TaskSelector
          variant="select"
          tasks={tasks}
          value={formInputs.task}
          onSelect={handleTaskChange}
        ></TaskSelector>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Duration:</Form.Label>
        <InputTimeHM
          defaultValue={formInputs.duration}
          onChange={handleDurationChange}
          isInvalid={!!formErrors.durationError}
          allowEmpty={false}
          autoFocus={true}
        ></InputTimeHM>
        <ValidationFeedback message={formErrors.durationError} />
      </Form.Group>

      <Form.Group as={Row} className="mt-5">
        <Col className="d-flex justify-content-start">
          <TooltipButton
            tooltipContent={`Delete this record`}
            buttonVariant="outline-primary"
            className="px-4 delete"
            onClick={handleDelete}
            disabled={!deleteIsPossible}
          >
            {formStatus.isDeleting ? "Deleting" : "Delete"}
          </TooltipButton>
        </Col>
        <Col className="d-flex justify-content-end">
          <ValidationFeedback
            message={formErrors.generalError}
            className="text-end me-3"
          />
          <TooltipButton
            tooltipContent={`Save changes`}
            className="px-4 submit"
            onClick={handleSubmit}
            disabled={!submitIsPossible}
          >
            {formStatus.isSubmitting ? "Saving" : "Save"}
          </TooltipButton>
        </Col>
      </Form.Group>
    </Container>
  );
}
