import { useState } from "react";
import { useApp } from "../../../../../contexts/AppContext";
import { ErrorLog } from "../../../../../hooks/useFormErrors";
import alertMessages from "../../../../../data/alertMessages.json";
import restrictions from "../../../../../data/restrictions.json";
import ValidationFeedback from "../../../../../components/ValidationFeedback";
import {
  useFormSubmit,
  ValidationResult,
} from "../../../../../hooks/useFormSubmit";
import { fetchers } from "../../../../../serverApi/fetchers";
import { Button, Col, Form, Row } from "react-bootstrap";
import InputEmail from "../../../../../components/InputEmail";
import "./EmailForm.scss";

type FormErrors = {
  generalError: ErrorLog;
  emailError: ErrorLog;
};
type EmailInfo = {
  email: string;
};
export function EmailForm() {
  const { showAlert } = useApp();
  const [emailInfo, setEmailInfo] = useState<EmailInfo>({
    email: "",
  });
  const resetForm = () => {
    setEmailInfo({
      email: "",
    });
  };
  const validateEmail = (email: string): ValidationResult => {
    if (!email) {
      const message = "Email cannot be empty.";
      return { isValid: false, message: message };
    }
    // if (email.length > restrictions.maxEmailLength) {
    //   const message = "Email should not be longer than " + restrictions.maxEmailLength + " characters.";
    //   return { isValid: false, message: message };
    // }
    const emailPattern =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
    if (!emailPattern.test(email)) {
      const message = "Invalid email format.";
      return { isValid: false, message: message };
    }
    // if (restrictions.emailAllowedDomains.length > 0) {
    //   const domainPattern = /^.*?@(.*?\..*)$/;
    //   const domainMatches = email.match(domainPattern);
    //   if (
    //     !domainMatches ||
    //     domainMatches.length < 2 ||
    //     !domainMatches[1] ||
    //     !restrictions.emailAllowedDomains.includes(domainMatches[1])
    //   ) {
    //     const message = "Invalid domain.";
    //     return { isValid: false, message: message };
    //   }
    // }
    return { isValid: true, message: null };
  };
  const validateEmailInfo = (): boolean => {
    return validateEmail(emailInfo.email).isValid;
  };

  const sendEmailInfo = (
    _doIfSuccess: () => void,
    _doAlways: () => void
  ): void => {
    const dataToSend = {
      email: emailInfo.email,
    };
    fetchers.account.general.forgotPassword.fetch(dataToSend, {
      success: () => {
        _doIfSuccess();
        showAlert(alertMessages.emailWithPwdResetLinkIsSent, true);
        resetForm();
      },
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        _doAlways();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
  };
  const getUpdatedFormErrors = (
    _emailInfo: EmailInfo | undefined = emailInfo
  ): FormErrors => {
    const vNewEmailRes = validateEmail(_emailInfo.email);
    return {
      ...formErrors,
      emailError: vNewEmailRes.message,
    };
  };

  const [
    formStatus,
    formErrors,
    handleSubmit,
    submitIsPossible,
    updateFormErrors,
  ] = useFormSubmit<FormErrors, EmailInfo>(
    {
      generalError: null,
      emailError: null,
    },
    validateEmailInfo,
    sendEmailInfo,
    getUpdatedFormErrors
  );

  const handleEmailChange = (email: string) => {
    const newEmailInfo = { ...emailInfo, email: email };
    updateFormErrors(newEmailInfo);
    setEmailInfo(newEmailInfo);
  };

  const submitOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && submitIsPossible) {
      handleSubmit();
    }
  }

  return (
    <Row className="flex-column" onKeyDown={submitOnEnter}>
      <Col className="my-3">
        {/* <div className="mb-4 text"> Please enter your email address to search for your account.</div> */}
        {/* <Form.Label htmlFor="email" className="small">
          Email
        </Form.Label> */}
        <Form.Label htmlFor="email" className="mb-2">
        Please enter your email address to search for your account.
        </Form.Label>
        <InputEmail
          id="email"
          placeholder="Enter your email"
          value={emailInfo.email}
          onChange={handleEmailChange}
        />
        <ValidationFeedback message={formErrors.emailError} />
      </Col>
      <Col className="d-flex align-items-center justify-content-center mt-4">
      <Button
          variant="primary"
          className="py-2 px-5"
          disabled={!submitIsPossible}
          onClick={handleSubmit}
        >
          {formStatus.isSubmitting ? "Searching" : "Search"}
        </Button>
      </Col>
    </Row>
  );
}
