import { Col, Row } from "react-bootstrap";
import Timer from "../../../widgets/member/Timer";
import "./Dashboard.scss";
import DayRecordsTable from "../../../widgets/member/DayRecordsTable";
import { DateTime } from "../../../utils";
import { useState } from "react";
import usePageTitle from "../../../hooks/usePageTitle";
import MonthSubmitReminderBox from "../../../widgets/member/MonthSubmitReminderBox";

export function Dashboard() {
  const pageTitle = {full: "Dashboard", short: "Timer"};
  usePageTitle(pageTitle);
  const [date, setDate] = useState(new DateTime());
  return (
    <div className="Dashboard">
      <div className="d-flex flex-column">
      <MonthSubmitReminderBox/>
      </div>
      <div className="d-flex flex-column flex-md-row">
        <Col>
          <Timer></Timer>
        </Col>
        <Col>
          <DayRecordsTable date={date} simplified={true}></DayRecordsTable>
        </Col>
      </div>
    </div>
  );
}
