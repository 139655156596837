import {
  createSearchParams,
  generatePath,
  useNavigate,
} from "react-router-dom";
import { useApp } from "../../../../contexts/AppContext";
import { DateTime, fetchPost } from "../../../../utils";
import { MonthStatus } from "../../../../utils/MonthStatus";
import { User } from "../../../../utils/Users";
import {
  ReactNode,
  useEffect,
  useState,
} from "react";
import apiUrls from "../../../../data/api.json";
// import { Placeholder } from "react-bootstrap";
import { Year } from "../../../../utils/TimeTypes";
import {
  roundMinutesToHours,
  sumHours,
  roundUpToNearestHalf
} from "../../../../utils/TimeMathFormules";
import _ from "lodash";
import alertMessages from "../../../../data/alertMessages.json";
import { createContext } from "../../../../hooks/useCreateContext";
import { fetchers } from "../../../../serverApi/fetchers";
import { useHotkeys } from "react-hotkeys-hook";
import { useSelectedMonth } from "../hooks/useSelectedMonth";
import Placeholder from "../../../../components/Placeholder";

type MonthDaysTotalWorkTimeMins = { date: DateTime; hours: number }[];

export type MonthData = {
  date: DateTime;
  // totalWorkTimeMin: number;
  totalMinutes: number;
  documentedMinutes: number;
  // daysTotalWorkTimeMins: MonthDaysTotalWorkTimeMins;
  status: MonthStatus;
  hasStatusChangesRequests: boolean;
};
export type YearMonthsData = MonthData[];
type AvailableYears = {
  minYear: DateTime;
  maxYear: DateTime;
};

type YearRecordsTableContextVal = {
  member: User;
  setMember: (member: User) => void;
  year: DateTime;
  setYear: (year: DateTime) => void;
  availableYears: AvailableYears;
  yearMonths: YearMonthsData;
  selectedMonth: MonthData | null;
  selectMonth: (date: DateTime) => void;
  resetSelecedMonth: () => void;
  selectNextMonth: () => void;
  selectPrevMonth: () => void;
  // selectedMonth: MonthData | null;
  // setSelectedMonth: (selectedMonth: MonthData | null) => void;
  navigateToMonthRecords: (month: DateTime) => void;
  solveMonthStatusChangeRequest: (month: DateTime, accept: boolean) => void;
  changeMonthStatus: (
    month: DateTime,
    oldStatus: MonthStatus,
    newStatus: MonthStatus
  ) => void;
};
const [YearRecordsContext, useYearRecordsTable] =
  createContext<YearRecordsTableContextVal>();
export { useYearRecordsTable };

type YearRecordsProviderProps = {
  children: ReactNode;
  member: User;
  setMember: (newMember: User) => void;
  year: DateTime;
  setYear: (year: DateTime) => void;
};

export function YearRecordsProvider({
  children,
  member,
  setMember,
  year,
  setYear,
}: YearRecordsProviderProps) {
  const { showAlert } = useApp();
  const navigate = useNavigate();
  const [yearMonths, setYearMonths] = useState<YearMonthsData | undefined>(
    undefined
  );
  const [selectedMonth, selectMonth, resetSelecedMonth, selectNextMonth, selectPrevMonth] = useSelectedMonth(yearMonths);
  // const [selectedMonth, setSelectedMonth] = useState<MonthData | null>(null);

  const availableYears: AvailableYears = {
    minYear: DateTime.createLocDateTime(
      member.created.getLocYear(),
      member.created.getLocMonth()
    ),
    maxYear: new DateTime(),
  };
  const yearMonthsRefresh = (allow_recursion: boolean = true) => {
    const dataToSend = {
      userId: member.id,
      date: year,
    };
    fetchers.account.admin.yearInfo.fetch(dataToSend, {
      success: (data, status) => {
        let yearInfo = data.months.map(
          (monthInfo) => {
            // let daysTotalWorkTimeMins: MonthDaysTotalWorkTimeMins = [];
            // monthInfo.monthDays.forEach((respDayTotalWorkTime) => {
            //   const dayTotalWorkTime = {
            //     date: respDayTotalWorkTime.date,
            //     hours: roundMinutesToHours(respDayTotalWorkTime.minutes),
            //   };
            //   daysTotalWorkTimeMins.push(dayTotalWorkTime);
            // });
            return {
              date: monthInfo.monthDate,
              totalMinutes: monthInfo.totalMinutes,
              documentedMinutes: monthInfo.documentedTotalMinutes,
              // daysTotalWorkTimeMins: daysTotalWorkTimeMins,
              status: monthInfo.status,
              hasStatusChangesRequests: monthInfo.hasStatusChangesRequests,
            };
          }
        );
        setYearMonths(yearInfo);
      },
      fail: (data, status) => {
        if (status === 445 && allow_recursion) {
          setTimeout(() => yearMonthsRefresh(false), 1000);
          return;
        }
        showAlert(alertMessages.somethingWentWrong);
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    })
  };

  const changeMonthStatus = (
    month: DateTime,
    oldStatus: MonthStatus,
    newStatus: MonthStatus
  ) => {
    if (oldStatus === newStatus) {
      return;
    }
    var valid =
      (oldStatus === MonthStatus.submitted &&
        newStatus === MonthStatus.editable) ||
      (oldStatus === MonthStatus.submitted &&
        newStatus === MonthStatus.completed) ||
      (oldStatus === MonthStatus.completed &&
        newStatus === MonthStatus.submitted);

    if (!valid) {
      showAlert(alertMessages.invalidData);
      return;
    }
    if (
      (oldStatus === MonthStatus.submitted ||
        oldStatus === MonthStatus.completed) &&
      newStatus === MonthStatus.editable
    ) {
      if (
        window.confirm(
          'Are you sure you want to grant "draft" status for this month? This action will allow the member to make changes to this month\'s time records.'
        ) !== true
      ) {
        return;
      }
    }
    setYearMonths(undefined);
    const dataToSend = {
      userId: member.id,
      date: month,
      newStatus: newStatus,
    };
    fetchers.account.admin.changeMonthStatus.fetch(dataToSend, {
      fail: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
      always: () => {
        yearMonthsRefresh();
      },
      error: () => {
        showAlert(alertMessages.somethingWentWrong);
      },
    });
    // const endpoint = "/admin/member-records/change_month_status";
    // const dataToSend = {
    //   userId: member.id,
    //   date: month.toJSON(),
    //   newStatus: newStatus,
    // };
    // fetchPost<{}>(endpoint, dataToSend, {
    //   fail: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    //   always: () => {
    //     yearMonthsRefresh();
    //   },
    //   error: () => {
    //     showAlert(alertMessages.somethingWentWrong);
    //   },
    // });
  };
  useEffect(() => {
    yearMonthsRefresh();
  }, [year]);

  const navigateToMonthRecords = (month: DateTime) => {
    const monthValNum = month.getLocMonth();
    const monthVal = monthValNum.toString().padStart(2, "0");
    const queryParams = createSearchParams({
      month: monthVal + "." + month.getLocYear(),
    }).toString();
    navigate(
      {
        pathname: generatePath(
          apiUrls.account.children.admin.children.memberMonth,
          {
            userId: member.id,
          }
        ),
        search: queryParams,
      }
    );
  };
  const solveMonthStatusChangeRequest = (month: DateTime, accept: boolean) => {
    // console.log(accept);
  };

  // Hotkeys
  useHotkeys('Ctrl+ArrowUp', () => {
    selectPrevMonth();
  }, [selectedMonth, yearMonths]);
  useHotkeys('Ctrl+ArrowDown', () => {
    selectNextMonth();
  }, [selectedMonth, yearMonths]);
  useHotkeys('Ctrl+Space', () => {
    resetSelecedMonth();
  }, [selectedMonth, yearMonths]);
  useHotkeys('Shift+O', () => {
    if(selectedMonth){
      navigateToMonthRecords(selectedMonth.date);
    }
  }, [selectedMonth, yearMonths]);

  return (
    <>
      {yearMonths && (
        <YearRecordsContext.Provider
          value={{
            member,
            setMember,
            year,
            setYear,
            availableYears,
            yearMonths,
            selectedMonth,
            selectMonth,
            resetSelecedMonth,
            selectNextMonth,
            selectPrevMonth,
            // selectedMonth,
            // setSelectedMonth,
            navigateToMonthRecords,
            solveMonthStatusChangeRequest,
            changeMonthStatus,
          }}
        >
          {children}
        </YearRecordsContext.Provider>
      )}
      {!yearMonths && <Placeholder />}
    </>
  );
}
