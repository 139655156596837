import { format } from "date-fns-tz";
import _ from "lodash";
import { error } from "console";
import { JSONObject } from "./../utils/JsonType";
import { Category, Task } from "./../utils/Task";
import { DateTime } from "../utils";
import { DayStatus } from "../utils/DayStatus";
import { MonthStatus } from "../utils/MonthStatus";
import { TaskRecordGroups } from "../utils/TaskRecord";
import { TaskRecords } from "../utils/TaskRecords";
import { RequestMethods } from "../utils/fetchFromEndpoint";
import {
  ServerFetcher,
  ServerFetcherUnpacked,
  ServerFileFetcher,
} from "./utils/ServerFetcher";
import dateSchema from "./schemas/common/date.json";
import dateStatusSchema from "./schemas/common/dateStatus.json";
import monthStatusSchema from "./schemas/common/monthStatus.json";
import positiveIntegerSchema from "./schemas/common/positiveInteger.json";
import positiveFloatSchema from "./schemas/common/positiveFloat.json";
import stringifiedNumberSchema from "./schemas/common/stringifiedNumber.json";
import timeRecordGroupsSchema from "./schemas/common/timeRecordGroups.json";
import taskNameSchema from "./schemas/common/taskName.json";
import taskCategoryNameSchema from "./schemas/common/taskCategoryName.json";
import optionalDateStatusSchema from "./schemas/common/optionalDateStatus.json";
import optionalDateSchema from "./schemas/common/optionalDate.json";
import emailSchema from "./schemas/common/email.json";
import rolesSchema from "./schemas/common/roles.json";
import authInfo from "./schemas/responceData/general/authInfo.json";
import Ajv from "ajv";
import { AuthRoles, areAuthRoles } from "../utils/AuthRoles";
import { User } from "../utils/Users";
const ajv = new Ajv({
  schemas: [
    dateSchema,
    dateStatusSchema,
    monthStatusSchema,
    positiveIntegerSchema,
    positiveFloatSchema,
    stringifiedNumberSchema,
    timeRecordGroupsSchema,
    taskNameSchema,
    taskCategoryNameSchema,
    optionalDateStatusSchema,
    optionalDateSchema,
    emailSchema,
    authInfo,
    rolesSchema
  ],
});
const draft6MetaSchema = require("ajv/dist/refs/json-schema-draft-06.json");
ajv.addMetaSchema(draft6MetaSchema);

export const fetchers = {
  general: {
    authorize: (() => {
      const endpoint = "/login";
      const method = RequestMethods.POST;
      type DataType = {
        email: string;
        password: string;
      };
      const requestDataSchema = require("./schemas/requestData/general/authorize.json");
      const validateRequestData = ajv.compile<DataType>(requestDataSchema);
      type ErrorResp = JSONObject & {
        msg: string;
      };
      const isErrorResp = (resp: JSONObject): resp is ErrorResp => {
        return !!resp.msg && !resp.authInfo;
      };

      type RespType =
        | ErrorResp
        | {
            authInfo: {
              id: string;
              firstName: string;
              lastName: string;
              roles: string[];
              email: string;
              created: string;
              updated: string;
              deleted: string | null;
              
            };
          };
      type UnpackedRespType = {
        authInfo: {
          id: string;
          firstName: string;
          lastName: string;
          roles: AuthRoles[];
          email: string;
          created: DateTime;
          updated: DateTime;
          deleted: DateTime | null;
        };
      } | null;

      const responceDataSchema = require("./schemas/responceData/general/authorize.json");
      const validateResponceData = ajv.compile<RespType>(responceDataSchema);
      let unpackResponce = (resp: RespType): UnpackedRespType => {
        if (isErrorResp(resp)) {
          return null;
        }
        if (
          !areAuthRoles(resp.authInfo.roles)
        ) {
          throw new Error();
        }

        return {
          authInfo: {
            id: resp.authInfo.id,
            firstName: resp.authInfo.firstName,
            lastName: resp.authInfo.lastName,
            roles: resp.authInfo.roles as AuthRoles[],
            email: resp.authInfo.email,
            created: new DateTime(resp.authInfo.created),
            updated: new DateTime(resp.authInfo.updated),
            deleted: resp.authInfo.deleted
                      ? new DateTime(resp.authInfo.deleted)
                      : null
          },
        };
      };
      return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
        method,
        endpoint,
        validateRequestData,
        validateResponceData,
        unpackResponce
      );
    })(),

    authInfo: (() => {
      const endpoint = "/auth_info";
      const method = RequestMethods.POST;
      type DataType = {};
      const requestDataSchema = {};
      const validateRequestData = ajv.compile<DataType>(requestDataSchema);
      type RespType = {
        id: string;
        firstName: string;
        lastName: string;
        roles: string[];
        email: string;
        created: string;
        updated: string;
        deleted: string | null;
      };
      type UnpackedRespType = {
        id: string;
        firstName: string;
        lastName: string;
        roles: AuthRoles[];
        email: string;
        created: DateTime;
        updated: DateTime;
        deleted: DateTime | null;
      };
      const responceDataSchema = require("./schemas/responceData/general/authInfo.json");
      const validateResponceData = ajv.compile<RespType>(responceDataSchema);
      let unpackResponce = (resp: RespType): UnpackedRespType => {
        if (!areAuthRoles(resp.roles)) {
          throw new Error();
        }
        return {
          id: resp.id,
          firstName: resp.firstName,
          lastName: resp.lastName,
          roles: resp.roles as AuthRoles[],
          email: resp.email,
          created: new DateTime(resp.created),
          updated: new DateTime(resp.updated),
          deleted: resp.deleted
          ? new DateTime(resp.deleted)
          : null
        };
      };
      return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
        method,
        endpoint,
        validateRequestData,
        validateResponceData,
        unpackResponce
      );
    })(),
    logout: (() => {
      const endpoint = "/logout";
      const method = RequestMethods.POST;
      type DataType = {};
      type RespType = {};
      const validateRequestData = ajv.compile<DataType>({});
      return new ServerFetcher<DataType, RespType>(
        method,
        endpoint,
        validateRequestData
      );
    })(),
  },
  account: {
    general: {
      changeEmail: (() => {
        const endpoint = "/account_change_email";
        const method = RequestMethods.POST;
        type DataType = {
          password: string;
          newEmail: string;
        };
        const requestDataSchema = require("./schemas/requestData/account/general/changeEmail.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),
      changePassword: (() => {
        const endpoint = "/account_change_password";
        const method = RequestMethods.POST;
        type DataType = {
          password: string;
          newPassword: string;
        };
        const requestDataSchema = require("./schemas/requestData/account/general/changePassword.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      resetPassword: (() => {
        const endpoint = "/reset-password";
        const method = RequestMethods.POST;
        type DataType = {
          token: string;
          newPassword: string;
        };
        const requestDataSchema = require("./schemas/requestData/account/general/resetPassword.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      forgotPassword: (() => {
        const endpoint = "/forgot-password";
        const method = RequestMethods.POST;
        type DataType = {
          email: string;
        };
        const requestDataSchema = require("./schemas/requestData/account/general/forgotPassword.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),
      validateResetPasswordToken: (() => {
        // OLD
        const endpoint = "/validate-reset-password-token";
        const method = RequestMethods.POST;
        type DataType = {
          token: string;
        };
        const requestDataSchema = require("./schemas/requestData/account/general/validateResetPasswordToken.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),
      confirmEmail: (() => {
        // OLD
        const endpoint = "/confirm-email";
        const method = RequestMethods.POST;
        type DataType = {
          token: string;
        };
        const requestDataSchema = require("./schemas/requestData/account/general/confirmEmail.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),
      supportInfo: (() => {
        const endpoint = "/support-info";
        const method = RequestMethods.GET;
        type DataType = {};
        const requestDataSchema = {};
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {
          domain: string;
          email: string;
        };
        type UnpackedRespType = {
          domain: string;
          email: string;
        };
        const responceDataSchema = require("./schemas/responceData/account/general/supportInfo.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);
        let unpackResponce = (resp: RespType): UnpackedRespType => { return resp; }
        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })(),
      companyInfo: (() => {
        const endpoint = "/company-info";
        const method = RequestMethods.GET;
        type DataType = {};
        const requestDataSchema = {};
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {
          company: string;
        };
        type UnpackedRespType = RespType;
        const responceDataSchema = require("./schemas/responceData/account/general/companyInfo.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);
        let unpackResponce = (resp: RespType): UnpackedRespType => { return resp; }
        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })()
    },
    member: {
      // > Day Records
      dateStatus: (() => {
        const endpoint = "/user/day-records/date-status";
        const method = RequestMethods.POST;
        type DataType = {
          date: DateTime;
        };
        type RespType = {
          status: string;
        };
        type UnpackedRespType = {
          status: DayStatus;
        };
        const requestDataSchema = require("./schemas/requestData/account/member/dateStatus.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);

        const responceDataSchema = require("./schemas/responceData/account/member/dateStatus.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);
        let unpackResponce = (resp: RespType): UnpackedRespType => {
          if (!Object.values(DayStatus).includes(resp.status as DayStatus)) {
            throw new Error();
          }
          return {
            status: resp.status as DayStatus,
          };
        };
        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })(),

      changeDateStatus: (() => {
        const endpoint = "/user/day-records/change-date-status";
        const method = RequestMethods.POST;
        type DataType = {
          date: DateTime;
          newStatus: DayStatus;
        };
        const requestDataSchema = require("./schemas/requestData/account/member/changeDateStatus.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      cancelDateStatusRequest: (() => {
        const endpoint = "/user/day-records/cancel-date-status-request";
        const method = RequestMethods.POST;
        type DataType = {
          date: DateTime;
        };
        const requestDataSchema = require("./schemas/requestData/account/member/cancelDateStatusRequest.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);

        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      dateInfo: (() => {
        const endpoint = "/user/day-records/date-info";
        const method = RequestMethods.POST;
        type DataType = {
          date: DateTime;
        };
        const requestDataSchema = require("./schemas/requestData/account/member/dateInfo.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespRecordData = {
          recordId: string;
          task: {
            id: string;
            category: string;
            name: string;
          };
          period: {
            startedAt: string;
            endedAt: string | null;
          };
          durationMin: number;
          isManual: boolean;
        };
        type RespType = {
          status: string;
          requestedStatus: string | null;
          // totalHours: number;
          totalMinutes: number;
          records: Record<string, RespRecordData[]>;
        };

        type UnpackedRespType = {
          status: DayStatus;
          requestedStatus: DayStatus | null;
          // totalHours: number;
          totalMinutes: number;
          taskRecords: TaskRecords;
        };
        const responceDataSchema = require("./schemas/responceData/account/member/dateInfo.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);

        let unpackResponce = (resp: RespType): UnpackedRespType => {
          if (!Object.values(DayStatus).includes(resp.status as DayStatus)) {
            throw new Error();
          }
          const status = resp.status as DayStatus;
          let requestedStatus: DayStatus | null = null;
          if (resp.requestedStatus) {
            if (
              !Object.values(DayStatus).includes(
                resp.requestedStatus as DayStatus
              )
            ) {
              throw new Error();
            }
            requestedStatus = resp.requestedStatus as DayStatus;
          }
          const taskRecords = TaskRecords.fromResp(resp.records);
          return {
            status: status,
            requestedStatus: requestedStatus,
            // totalHours: resp.totalHours,
            totalMinutes: resp.totalMinutes,
            taskRecords: taskRecords,
          };
        };
        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })(),

      createManualTimerecord: (() => {
        const endpoint = "/user/day-records/create-manual-timerecord";
        const method = RequestMethods.POST;
        type DataType = {
          date: DateTime;
          taskId: string;
          durationMin: number;
        };
        const requestDataSchema = require("./schemas/requestData/account/member/createManualTimerecord.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      deleteTimerecord: (() => {
        const endpoint = "/user/day-records/delete-timerecord";
        const method = RequestMethods.POST;
        type DataType = {
          date: DateTime;
          recordId: string;
          group: TaskRecordGroups;
        };
        type RespType = {};
        const requestDataSchema = require("./schemas/requestData/account/member/deleteTimerecord.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      editManualTimerecord: (() => {
        const endpoint = "/user/day-records/edit-manual-timerecord";
        const method = RequestMethods.POST;
        type DataType = {
          date: DateTime;
          recordId: string;
          taskId: string;
          durationMin: number;
          group: TaskRecordGroups;
        };
        const requestDataSchema = require("./schemas/requestData/account/member/editManualTimerecord.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      editClockedTimerecord: (() => {
        const endpoint = "/user/day-records/edit-clocked-timerecord";
        const method = RequestMethods.POST;
        type DataType = {
          date: DateTime;
          recordId: string;
          taskId: string;
          stratedAt: DateTime;
          endedAt: DateTime;
          group: TaskRecordGroups;
        };
        const requestDataSchema = require("./schemas/requestData/account/member/editClockedTimerecord.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      moveTimerecordToBillable: (() => {
        const endpoint = "/user/day-records/move-timerecord-to-billable";
        const method = RequestMethods.POST;
        type DataType = {
          date: DateTime;
          recordId: string;
        };
        const requestDataSchema = require("./schemas/requestData/account/member/moveTimerecordToBillable.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      moveTimerecordToUnverified: (() => {
        const endpoint = "/user/day-records/move-timerecord-to-unverified";
        const method = RequestMethods.POST;
        type DataType = {
          date: DateTime;
          recordId: string;
        };
        const requestDataSchema = require("./schemas/requestData/account/member/moveTimerecordToUnverified.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      // > Month Records
      monthStatus: (() => {
        const endpoint = "/user/month-records/month-status";
        const method = RequestMethods.POST;
        type DataType = {
          date: DateTime;
        };
        const requestDataSchema = require("./schemas/requestData/account/member/monthStatus.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {
          status: string;
        }
        type UnpackedRespType = {
          status: MonthStatus;
        }
        const responceDataSchema = require("./schemas/responceData/account/member/monthStatus.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);
        const unpackResponce = (resp: RespType): UnpackedRespType => {
          if (
            !Object.values(MonthStatus).includes(resp.status as MonthStatus)
          ) {
            throw new Error();
          }
          const monthStatus = resp.status as MonthStatus;
          return {status: monthStatus}
        };
        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })(),

      monthInfo: (() => {
        const endpoint = "/user/month-records/month-info";
        const method = RequestMethods.POST;
        type DataType = {
          date: DateTime;
        };
        const requestDataSchema = require("./schemas/requestData/account/member/monthInfo.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {
          status: string;
          monthDays: {
            date: string;
            // totalWorkTimeMin: number;
            // totalHours: number;
            totalMinutes: number;
            status: string;
            requestedStatus: string | null;
          }[];
          totalMinutes: number;
          documentedTotalMinutes: number;
        };
        type UnpackedRespType = {
          status: MonthStatus;
          monthDays: {
            date: DateTime;
            // totalWorkTimeMin: number;
            // totalHours: number;
            totalMinutes: number;
            status: DayStatus;
            requestedStatus: DayStatus | null;
          }[];
          totalMinutes: number;
          documentedTotalMinutes: number;
        };
        const responceDataSchema = require("./schemas/responceData/account/member/monthInfo.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);
        const unpackResponce = (resp: RespType): UnpackedRespType => {
          if (
            !Object.values(MonthStatus).includes(resp.status as MonthStatus)
          ) {
            throw new Error();
          }
          const monthStatus = resp.status as MonthStatus;
          const monthDays = resp.monthDays.map((monthDay) => {
            const date = new DateTime(monthDay.date);
            // const totalWorkTimeMin = monthDay.totalWorkTimeMin;
            if (
              !Object.values(DayStatus).includes(monthDay.status as DayStatus)
            ) {
              throw new Error();
            }
            const status = monthDay.status as DayStatus;
            let requestedStatus: DayStatus | null = null;
            if (monthDay.requestedStatus) {
              if (
                !Object.values(DayStatus).includes(
                  monthDay.requestedStatus as DayStatus
                )
              ) {
                throw new Error();
              }
              requestedStatus = monthDay.requestedStatus as DayStatus;
            }
            return {
              date: date,
              totalMinutes: monthDay.totalMinutes,
              status: status,
              requestedStatus: requestedStatus,
            };
          });
          return {
            status: monthStatus,
            monthDays: monthDays,
            totalMinutes: resp.totalMinutes,
            documentedTotalMinutes: resp.documentedTotalMinutes,
          };
        };
        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })(),

      monthStatusesInfo: (() => {
        const endpoint = "/user/month-records/month-statuses-info";
        const method = RequestMethods.POST;
        type DataType = {
          date: DateTime;
        };
        const requestDataSchema = require("./schemas/requestData/account/member/monthStatusesInfo.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {
          status: string;
          monthDays: {
            date: string;
            status: string;
            requestedStatus: string | null;
          }[];
        };
        type UnpackedRespType = {
          status: MonthStatus;
          monthDays: {
            date: DateTime;
            status: DayStatus;
            requestedStatus: DayStatus | null;
          }[];
        };
        const responceDataSchema = require("./schemas/responceData/account/member/monthStatusesInfo.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);
        const unpackResponce = (resp: RespType): UnpackedRespType => {
          if (
            !Object.values(MonthStatus).includes(resp.status as MonthStatus)
          ) {
            throw new Error();
          }
          const monthStatus = resp.status as MonthStatus;
          const monthDays = resp.monthDays.map((monthDay) => {
            const date = new DateTime(monthDay.date);
            // const totalWorkTimeMin = monthDay.totalWorkTimeMin;
            if (
              !Object.values(DayStatus).includes(monthDay.status as DayStatus)
            ) {
              throw new Error();
            }
            const status = monthDay.status as DayStatus;
            let requestedStatus: DayStatus | null = null;
            if (monthDay.requestedStatus) {
              if (
                !Object.values(DayStatus).includes(
                  monthDay.requestedStatus as DayStatus
                )
              ) {
                throw new Error();
              }
              requestedStatus = monthDay.requestedStatus as DayStatus;
            }
            return {
              date: date,
              status: status,
              requestedStatus: requestedStatus,
            };
          });
          return {
            status: monthStatus,
            monthDays: monthDays,
          };
        };
        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })(),

      changeMonthStatus: (() => {
        const endpoint = "/user/month-records/change-month-status";
        const method = RequestMethods.POST;
        type DataType = {
          date: DateTime;
          newStatus: MonthStatus;
        };
        const requestDataSchema = require("./schemas/requestData/account/member/changeMonthStatus.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      generateMonthTimesheetFile: (() => {
        const endpoint = "/user/month-records/generate-month-timesheet-file";
        const method = RequestMethods.POST;
        type DataType = {
          date: DateTime;
        };
        const requestDataSchema = require("./schemas/requestData/account/member/generateMonthTimesheetFile.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        return new ServerFileFetcher<DataType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      // > Year Records
      yearInfo: (() => {
        const endpoint = "/user/year-months/year-info";
        const method = RequestMethods.POST;
        type DataType = {
          date: DateTime;
        };
        const requestDataSchema = require("./schemas/requestData/account/member/yearInfo.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {
          months: {
            monthDate: string;
            totalMinutes: number;
            documentedTotalMinutes: number;
            status: string;
            hasStatusChangesRequests: boolean;
          }[];
        };
        type UnpackedRespType = {
          months: {
            monthDate: DateTime;
            totalMinutes: number;
            documentedTotalMinutes: number;
            status: MonthStatus;
            hasStatusChangesRequests: boolean;
          }[];
        };
        const responceDataSchema = require("./schemas/responceData/account/member/yearInfo.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);
        let unpackResponce = (resp: RespType): UnpackedRespType => {
          return {
            months: resp.months.map((month) => {
              if (
                !Object.values(MonthStatus).includes(
                  month.status as MonthStatus
                )
              ) {
                throw new Error();
              }
              return {
                monthDate: new DateTime(month.monthDate),
                totalMinutes: month.totalMinutes,
                documentedTotalMinutes: month.documentedTotalMinutes,
                status: month.status as MonthStatus,
                hasStatusChangesRequests: month.hasStatusChangesRequests,
              };
            }),
          };
        };
        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })(),

      // > Tasks
      tasks: (() => {
        const endpoint = "/user/tasks";
        const method = RequestMethods.GET;
        type DataType = {};
        const requestDataSchema = {};
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {
          tasks: {
            id: string;
            name: string;
            category: string;
            isPublic: boolean;
            isDeleted: boolean;
          }[];
        };
        type UnpackedRespType = Task[];

        const responceDataSchema = require("./schemas/responceData/account/member/tasks.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);
        const unpackResponce = (resp: RespType): UnpackedRespType => {
          let tasks = resp.tasks.map(
            (task) =>
              new Task(
                task.id,
                task.name,
                task.category,
                task.isPublic,
                task.isDeleted
              )
          );
          tasks = _.sortBy(
            tasks,
            (obj) => (obj.isPublic ? "A" : "B") + obj.category.toLowerCase().padEnd(150, ' ') + obj.name.toLowerCase()
          );
          return tasks;
        };
        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })(),

      createPrivateTask: (() => {
        const endpoint = "/user/tasks/create-private-task";
        const method = RequestMethods.POST;
        type DataType = {
          taskName: string;
          categoryName: string;
        };
        const requestDataSchema = require("./schemas/requestData/account/member/createPrivateTask.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      editPrivateTask: (() => {
        const endpoint = "/user/tasks/edit-private-task";
        const method = RequestMethods.POST;
        type DataType = {
          taskId: string;
          taskName: string;
          categoryName: string;
        };
        const requestDataSchema = require("./schemas/requestData/account/member/editPrivateTask.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      deletePrivateTask: (() => {
        const endpoint = "/user/tasks/delete-private-task";
        const method = RequestMethods.POST;
        type DataType = {
          taskId: string;
        };
        const requestDataSchema = require("./schemas/requestData/account/member/deletePrivateTask.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      editPrivateTaskCategory: (() => {
        const endpoint = "/user/tasks/edit-private-task-category";
        const method = RequestMethods.POST;
        type DataType = {
          oldCategoryName: string;
          newCategoryName: string;
        };
        const requestDataSchema = require("./schemas/requestData/account/member/editPrivateTaskCategory.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      deletePrivateTaskCategory: (() => {
        const endpoint = "/user/tasks/delete-private-task-category";
        const method = RequestMethods.POST;
        type DataType = {
          categoryName: string;
        };
        const requestDataSchema = require("./schemas/requestData/account/member/deletePrivateTaskCategory.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      // > Timer
      changeTimerStatus: (() => {
        const endpoint = "/user/timer/change-timer-status";
        const method = RequestMethods.POST;
        type DataType = {
          taskId: string | null;
        };
        const requestDataSchema = require("./schemas/requestData/account/member/changeTimerStatus.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {
          active: null | {
            startedAt: string;
            task: {
              id: string;
              name: string;
              category: string;
              isPublic: boolean;
              isDeleted: boolean;
            };
          };
        };
        type UnpackedRespType = {
          active: null | {
            startedAt: DateTime;
            task: Task;
          };
        };
        const responceDataSchema = require("./schemas/responceData/account/member/timerStatus.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);
        const unpackResponce = (resp: RespType): UnpackedRespType => {
          const active = !resp.active
            ? null
            : {
                startedAt: new DateTime(resp.active.startedAt),
                task: new Task(
                  resp.active.task.id,
                  resp.active.task.name,
                  resp.active.task.category,
                  resp.active.task.isPublic,
                  resp.active.task.isDeleted
                ),
              };
          return {
            active: active,
          };
        };
        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })(),

      startTimer: (() => {
        const endpoint = "/user/timer/start-timer";
        const method = RequestMethods.POST;
        type DataType = {
          taskId: string;
        };
        const requestDataSchema = require("./schemas/requestData/account/member/startTimer.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {
          active: null | {
            startedAt: string;
            task: {
              id: string;
              name: string;
              category: string;
              isPublic: boolean;
              isDeleted: boolean;
            };
          };
        };
        type UnpackedRespType = {
          active: null | {
            startedAt: DateTime;
            task: Task;
          };
        };
        const responceDataSchema = require("./schemas/responceData/account/member/timerStatus.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);
        const unpackResponce = (resp: RespType): UnpackedRespType => {
          const active = !resp.active
            ? null
            : {
                startedAt: new DateTime(resp.active.startedAt),
                task: new Task(
                  resp.active.task.id,
                  resp.active.task.name,
                  resp.active.task.category,
                  resp.active.task.isPublic,
                  resp.active.task.isDeleted
                ),
              };
          return {
            active: active,
          };
        };
        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })(),

      endTimer: (() => {
        const endpoint = "/user/timer/end-timer";
        const method = RequestMethods.POST;
        type DataType = {};
        const requestDataSchema = {}; //require("./schemas/requestData/account/member/changeTimerStatus.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {
          active: null | {
            startedAt: string;
            task: {
              id: string;
              name: string;
              category: string;
              isPublic: boolean;
              isDeleted: boolean;
            };
          };
        };
        type UnpackedRespType = {
          active: null | {
            startedAt: DateTime;
            task: Task;
          };
        };
        const responceDataSchema = require("./schemas/responceData/account/member/timerStatus.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);
        const unpackResponce = (resp: RespType): UnpackedRespType => {
          const active = !resp.active
            ? null
            : {
                startedAt: new DateTime(resp.active.startedAt),
                task: new Task(
                  resp.active.task.id,
                  resp.active.task.name,
                  resp.active.task.category,
                  resp.active.task.isPublic,
                  resp.active.task.isDeleted
                ),
              };
          return {
            active: active,
          };
        };
        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })(),

      timerStatus: (() => {
        const endpoint = "/user/timer/timer-status";
        const method = RequestMethods.GET;
        type DataType = {};
        const requestDataSchema = {};
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {
          active: null | {
            startedAt: string;
            task: {
              id: string;
              name: string;
              category: string;
              isPublic: boolean;
              isDeleted: boolean;
            };
          };
        };
        type UnpackedRespType = {
          active: null | {
            startedAt: DateTime;
            task: Task;
          };
        };
        const responceDataSchema = require("./schemas/responceData/account/member/timerStatus.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);

        const unpackResponce = (resp: RespType): UnpackedRespType => {
          const active = !resp.active
            ? null
            : {
                startedAt: new DateTime(resp.active.startedAt),
                task: new Task(
                  resp.active.task.id,
                  resp.active.task.name,
                  resp.active.task.category,
                  resp.active.task.isPublic,
                  resp.active.task.isDeleted
                ),
              };
          return {
            active: active,
          };
        };
        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })(),
    },

    // ADMIN
    admin: {
      // > Active Members
      activeMembersInfo: (() => {
        const endpoint = "/admin/active-members/info";
        const method = RequestMethods.GET;
        type DataType = {};
        const requestDataSchema = {};
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {
          date: string;
          info: {
            member: {
              id: string;
              firstName: string;
              lastName: string;
              roles: string[];
              email: string;
              created: string;
              updated: string;
              deleted: string | null;
            };
            timer: null | {
              startedAt: string;
              task: {
                id: string;
                name: string;
                category: string;
                isPublic: boolean;
                isDeleted: boolean;
              };
              durationForDayMinutes: number;
              acceptibility: "fine" | "acceptable" | "wrong";
            };
            totalBillableMinutes: number;
          }[];
        };
        type UnpackedRespType = {
          date: DateTime;
          membersInfo: {
            member: User;
            timer: null | {
              startedAt: DateTime;
              task: Task;
              durationForDayMinutes: number;
              acceptibility: number;
            };
            totalBillableMinutes: number;
          }[];
        };
        const responceDataSchema = require("./schemas/responceData/account/admin/activeMembersInfo.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);
        const unpackResponce = (resp: RespType): UnpackedRespType => {
          let membersInfo = resp.info.map((memberInfo) => {
            if (!areAuthRoles(memberInfo.member.roles)){
              throw new Error();
            }
            return {
              member: {
                id: memberInfo.member.id,
                firstName: memberInfo.member.firstName,
                lastName: memberInfo.member.lastName,
                roles: memberInfo.member.roles as AuthRoles[],
                email: memberInfo.member.email,
                created: new DateTime(memberInfo.member.created),
                updated: new DateTime(memberInfo.member.updated),
                deleted: memberInfo.member.deleted ? new DateTime(memberInfo.member.deleted) : null,
              },
              timer: !memberInfo.timer
                ? null
                : {
                    startedAt: new DateTime(memberInfo.timer.startedAt),
                    task: new Task(
                      memberInfo.timer.task.id,
                      memberInfo.timer.task.name,
                      memberInfo.timer.task.category,
                      memberInfo.timer.task.isPublic,
                      memberInfo.timer.task.isDeleted
                    ),
                    durationForDayMinutes:
                      memberInfo.timer.durationForDayMinutes,
                    acceptibility:
                      memberInfo.timer.acceptibility === "fine"
                        ? 1
                        : memberInfo.timer.acceptibility === "acceptable"
                        ? 0
                        : -1,
                  },
              totalBillableMinutes: memberInfo.totalBillableMinutes,
            };
          });
          const group = (acceptibility: number | null | undefined) => {
            switch (acceptibility) {
              case 1:
                return "B";
              case 0:
                return "B";
              case -1:
                return "A";
              default:
                return "C";
            }
          };
          membersInfo = _.sortBy(
            membersInfo,
            (obj) =>
              group(obj.timer?.acceptibility) + obj.member.lastName.toLowerCase().padEnd(150, ' ') + obj.member.firstName.toLowerCase()
          );
          return { date: new DateTime(resp.date), membersInfo: membersInfo };
        };
        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })(),

      endMemberTimer: (() => {
        const endpoint = "/admin/active-members/end-member-timer";
        const method = RequestMethods.POST;
        type DataType = {
          userId: string;
        };
        const requestDataSchema = require("./schemas/requestData/account/admin/endMemberTimer.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      // > Members Working Summary
      membersWorktimeSummary: (() => {
        const endpoint = "/admin/members-summary";
        const method = RequestMethods.POST;
        type DataType = {
          date: DateTime;
        };
        const requestDataSchema = require("./schemas/requestData/account/admin/membersWorktimeSummary.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {
          membersSummary: {
            member: {
              id: string;
              firstName: string;
              lastName: string;
              roles: string[];
              email: string;
              created: string;
              updated: string;
              deleted: string | null;
            };
            totals: {
              monthTotalMinutes: number;
              dateTotalMinutes: number;
            };
          }[];
        };
        type UnpackedRespType = {
          membersSummary: {
            member: User;
            totals: {
              monthTotalMinutes: number;
              dateTotalMinutes: number;
            };
          }[];
        };
        const responceDataSchema = require("./schemas/responceData/account/admin/membersWorktimeSummary.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);
        const unpackResponce = (resp: RespType): UnpackedRespType => {
          return {
            membersSummary: resp.membersSummary.map((memberSummary) => {
              if(!areAuthRoles(memberSummary.member.roles)){
                throw new Error();
              }
              return {
                member: {
                  id: memberSummary.member.id,
                  firstName: memberSummary.member.firstName,
                  lastName: memberSummary.member.lastName,
                  roles: memberSummary.member.roles as AuthRoles[],
                  email: memberSummary.member.email,
                  created: new DateTime(memberSummary.member.created),
                  updated: new DateTime(memberSummary.member.updated),
                  deleted: memberSummary.member.deleted
                    ? new DateTime(memberSummary.member.deleted)
                    : null,
                },
                totals: memberSummary.totals,
              };
            }),
          };
        };
        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })(),

      // > Members Monthly Timesheets
      membersMonthlyTimesheets: (() => {
        const endpoint = "/admin/members-timesheets/summary";
        const method = RequestMethods.POST;
        type DataType = {
          date: DateTime;
        };
        const requestDataSchema = require("./schemas/requestData/account/admin/membersMonthlyTimesheets.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);

        type RespType = {
          membersMonthSummary: {
            member: {
              id: string;
              firstName: string;
              lastName: string;
              roles: string[];
              email: string;
              created: string;
              updated: string;
              deleted: string | null;
            };
            month: {
              status: string;
              hasStatusChangesRequests: boolean;
              totals: {
                monthTotalMinutes: number;
                documentedMonthTotalMinutes: number;
              };
            };
          }[];
        };
        type UnpackedRespType = {
          membersMonthSummary: {
            member: User;
            month: {
              status: MonthStatus;
              hasRequests: boolean;
              monthTotalMinutes: number;
              documentedMonthTotalMinutes: number;
            };
          }[];
        };
        const responceDataSchema = require("./schemas/responceData/account/admin/membersMonthlyTimesheets.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);
        const unpackResponce = (resp: RespType): UnpackedRespType => {
          return {
            membersMonthSummary: resp.membersMonthSummary.map(
              (monthSummary) => {
                if (
                  !Object.values(MonthStatus).includes(
                    monthSummary.month.status as MonthStatus
                  )
                ) {
                  throw new Error();
                }
                if (!areAuthRoles(monthSummary.member.roles)){
                  throw new Error();
                }
                return {
                  member: {
                    id: monthSummary.member.id,
                    firstName: monthSummary.member.firstName,
                    lastName: monthSummary.member.lastName,
                    roles: monthSummary.member.roles as AuthRoles[],
                    email: monthSummary.member.email,
                    created: new DateTime(monthSummary.member.created),
                    updated: new DateTime(monthSummary.member.updated),
                    deleted: monthSummary.member.deleted
                      ? new DateTime(monthSummary.member.deleted)
                      : null,
                  },
                  month: {
                    status: monthSummary.month.status as MonthStatus,
                    hasRequests: monthSummary.month.hasStatusChangesRequests,
                    monthTotalMinutes:
                      monthSummary.month.totals.monthTotalMinutes,
                    documentedMonthTotalMinutes:
                      monthSummary.month.totals.documentedMonthTotalMinutes,
                  },
                };
              }
            ),
          };
        };

        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })(),

      genMergedTimesheetsPDF: (() => {
        const endpoint =
          "/admin/members-timesheets/gen-merged-members-month-timesheets-pdf-file";
        const method = RequestMethods.POST;
        type DataType = {
          date: DateTime;
          usersId: string[];
          format: string;
        };
        const requestDataSchema = require("./schemas/requestData/account/admin/genMergedTimesheetsPDF.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        return new ServerFileFetcher<DataType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      // > Members Timerecords
      dayInfo: (() => {
        const endpoint = "/admin/member-records/day-info";
        const method = RequestMethods.POST;
        type DataType = {
          userId: string;
          date: DateTime;
        };
        const requestDataSchema = require("./schemas/requestData/account/admin/dayInfo.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespRecordData = {
          recordId: string;
          task: {
            id: string;
            category: string;
            name: string;
          };
          period: {
            startedAt: string;
            endedAt: string | null;
          };
          durationMin: number;
          isManual: boolean;
        };
        type RespType = {
          status: string;
          requestedStatus: string | null;
          totalMinutes: number;
          records: Record<string, RespRecordData[]>;
        };
        type UnpackedRespType = {
          status: DayStatus;
          requestedStatus: DayStatus | null;
          totalMinutes: number;
          taskRecords: TaskRecords;
        };
        const responceDataSchema = require("./schemas/responceData/account/admin/dayInfo.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);
        const unpackResponce = (resp: RespType): UnpackedRespType => {
          if (!Object.values(DayStatus).includes(resp.status as DayStatus)) {
            throw new Error();
          }
          const status = resp.status as DayStatus;
          let requestedStatus: DayStatus | null = null;
          if (resp.requestedStatus) {
            if (
              !Object.values(DayStatus).includes(
                resp.requestedStatus as DayStatus
              )
            ) {
              throw new Error();
            }
            requestedStatus = resp.requestedStatus as DayStatus;
          }
          const taskRecords = TaskRecords.fromResp(resp.records);
          return {
            status: status,
            requestedStatus: requestedStatus,
            totalMinutes: resp.totalMinutes,
            taskRecords: taskRecords,
          };
        };
        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })(),

      changeDateStatus: (() => {
        const endpoint = "/admin/member-records/change-date-status";
        const method = RequestMethods.POST;
        type DataType = {
          userId: string;
          date: DateTime;
          newStatus: DayStatus;
        };
        const requestDataSchema = require("./schemas/requestData/account/admin/changeDateStatus.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      rejectDateStatusRequest: (() => {
        const endpoint = "/admin/member-records/reject-date-status-request";
        const method = RequestMethods.POST;
        type DataType = {
          userId: string;
          date: DateTime;
        };
        const requestDataSchema = require("./schemas/requestData/account/admin/rejectDateStatusRequest.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      resolveAllMonthDateStatusRequests: (() => {
        const endpoint =
          "/admin/member-records/resolve-all-month-day-status-requests";
        const method = RequestMethods.POST;
        type DataType = {
          userId: string;
          date: DateTime;
          grant: boolean;
        };
        const requestDataSchema = require("./schemas/requestData/account/admin/resolveAllMonthDateStatusRequests.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      monthInfo: (() => {
        const endpoint = "/admin/member-records/month-info";
        const method = RequestMethods.POST;
        type DataType = {
          userId: string;
          date: DateTime;
        };
        const requestDataSchema = require("./schemas/requestData/account/admin/monthInfo.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {
          status: string;
          monthDays: {
            date: string;
            totalMinutes: number;
            status: string;
            requestedStatus: string | null;
          }[];
          totalMinutes: number;
          documentedTotalMinutes: number;
        };
        type UnpackedRespType = {
          status: MonthStatus;
          monthDays: {
            date: DateTime;
            totalMinutes: number;
            status: DayStatus;
            requestedStatus: DayStatus | null;
          }[];
          totalMinutes: number;
          documentedTotalMinutes: number;
        };
        const responceDataSchema = require("./schemas/responceData/account/admin/monthInfo.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);
        const unpackResponce = (resp: RespType): UnpackedRespType => {
          if (
            !Object.values(MonthStatus).includes(resp.status as MonthStatus)
          ) {
            throw new Error();
          }
          const monthStatus = resp.status as MonthStatus;
          const monthDays = resp.monthDays.map((monthDay) => {
            const date = new DateTime(monthDay.date);
            const totalMinutes = monthDay.totalMinutes;
            if (
              !Object.values(DayStatus).includes(monthDay.status as DayStatus)
            ) {
              throw new Error();
            }
            const status = monthDay.status as DayStatus;
            let requestedStatus: DayStatus | null = null;
            if (monthDay.requestedStatus) {
              if (
                !Object.values(DayStatus).includes(
                  monthDay.requestedStatus as DayStatus
                )
              ) {
                throw new Error();
              }
              requestedStatus = monthDay.requestedStatus as DayStatus;
            }
            return {
              date: date,
              totalMinutes: totalMinutes,
              status: status,
              requestedStatus: requestedStatus,
            };
          });
          return {
            status: monthStatus,
            monthDays: monthDays,
            totalMinutes: resp.totalMinutes,
            documentedTotalMinutes: resp.documentedTotalMinutes,
          };
        };
        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })(),

      monthStatusesInfo: (() => {
        const endpoint = "/admin/member-records/month-statuses-info";
        const method = RequestMethods.POST;
        type DataType = {
          userId: string;
          date: DateTime;
        };
        const requestDataSchema = require("./schemas/requestData/account/admin/monthStatusesInfo.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {
          status: string;
          monthDays: {
            date: string;
            status: string;
            requestedStatus: string | null;
          }[];
        };
        type UnpackedRespType = {
          status: MonthStatus;
          monthDays: {
            date: DateTime;
            status: DayStatus;
            requestedStatus: DayStatus | null;
          }[];
        };
        const responceDataSchema = require("./schemas/responceData/account/admin/monthStatusesInfo.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);
        const unpackResponce = (resp: RespType): UnpackedRespType => {
          if (
            !Object.values(MonthStatus).includes(resp.status as MonthStatus)
          ) {
            throw new Error();
          }
          const monthStatus = resp.status as MonthStatus;
          const monthDays = resp.monthDays.map((monthDay) => {
            const date = new DateTime(monthDay.date);
            if (
              !Object.values(DayStatus).includes(monthDay.status as DayStatus)
            ) {
              throw new Error();
            }
            const status = monthDay.status as DayStatus;
            let requestedStatus: DayStatus | null = null;
            if (monthDay.requestedStatus) {
              if (
                !Object.values(DayStatus).includes(
                  monthDay.requestedStatus as DayStatus
                )
              ) {
                throw new Error();
              }
              requestedStatus = monthDay.requestedStatus as DayStatus;
            }
            return {
              date: date,
              status: status,
              requestedStatus: requestedStatus,
            };
          });
          return {
            status: monthStatus,
            monthDays: monthDays,
          };
        };
        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })(),

      changeMonthStatus: (() => {
        const endpoint = "/admin/member-records/change-month-status";
        const method = RequestMethods.POST;
        type DataType = {
          userId: string;
          date: DateTime;
          newStatus: MonthStatus;
        };
        const requestDataSchema = require("./schemas/requestData/account/admin/changeMonthStatus.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      generateMemberMonthTimesheetFile: (() => {
        const endpoint =
          "/admin/member-records/generate-member-month-timesheet-file";
        const method = RequestMethods.POST;
        type DataType = {
          userId: string;
          date: DateTime;
        };
        const requestDataSchema = require("./schemas/requestData/account/admin/generateMemberMonthTimesheetFile.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        return new ServerFileFetcher<DataType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      yearInfo: (() => {
        const endpoint = "/admin/member-records/year-info";
        const method = RequestMethods.POST;
        type DataType = {
          userId: string;
          date: DateTime;
        };
        const requestDataSchema = require("./schemas/requestData/account/admin/yearInfo.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {
          months: {
            monthDate: string;
            // monthDays: { date: string; minutes: number }[];
            status: string;
            totalMinutes: number;
            documentedTotalMinutes: number;
            hasStatusChangesRequests: boolean;
          }[];
        };
        type UnpackedRespType = {
          months: {
            monthDate: DateTime;
            // monthDays: {
            //   date: DateTime;
            //   minutes: number;
            // }[];
            status: MonthStatus;
            totalMinutes: number;
            documentedTotalMinutes: number;
            hasStatusChangesRequests: boolean;
          }[];
        };
        const responceDataSchema = require("./schemas/responceData/account/admin/yearInfo.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);
        let unpackResponce = (resp: RespType): UnpackedRespType => {
          return {
            months: resp.months.map((month) => {
              if (
                !Object.values(MonthStatus).includes(
                  month.status as MonthStatus
                )
              ) {
                throw new Error();
              }
              return {
                monthDate: new DateTime(month.monthDate),
                // monthDays: month.monthDays.map((monthDay) => ({
                //   date: new DateTime(monthDay.date),
                //   minutes: monthDay.minutes,
                // })),
                totalMinutes: month.totalMinutes,
                documentedTotalMinutes: month.documentedTotalMinutes,
                status: month.status as MonthStatus,
                hasStatusChangesRequests: month.hasStatusChangesRequests,
              };
            }),
          };
        };
        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })(),

      //> Members' Pending Requests
      membersPendingRequests: (() => {
        const endpoint = "/admin/members-requests";
        const method = RequestMethods.GET;
        type DataType = {};
        const requestDataSchema = {};
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {
          pendingRequests: {
            id: string;
            date: string;
            member: {
              id: string;
              firstName: string;
              lastName: string;
              roles: string[];
              email: string;
              created: string;
              updated: string;
              deleted: string | null;
            };
            oldStatus: string;
            newStatus: string;
            lowAcceptionProbability: boolean;
          }[];
        };
        type UnpackedRespTypeOLD = {
          member: User;
          months: {
            month: DateTime;
            requests: {
              id: string;
              date: DateTime;
              oldStatus: DayStatus;
              newStatus: DayStatus;
            }[];
          }[];
        }[];
        type UnpackedRespType = {
          month: DateTime;
          members: {
            member: User;
            requests: {
              id: string;
              date: DateTime;
              oldStatus: DayStatus;
              newStatus: DayStatus;
              warning: boolean;
            }[];
            warning: boolean;
          }[];
          warning: boolean;
        }[];
        const responceDataSchema = require("./schemas/responceData/account/admin/membersPendingRequests.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);
        // const unpackResponceOLD = (resp: RespType): UnpackedRespTypeOLD => {
        //   let grouped_resp: UnpackedRespType = []
        //   for (let request of resp.pendingRequests) {
        //       const member = {
        //         id: request.member.id,
        //         name: request.member.name,
        //         roles: request.member.roles as AuthRoles[],
        //         email: request.member.email,
        //         created: new DateTime(request.member.created),
        //         updated: new DateTime(request.member.updated),
        //         deleted: null,
        //       };
        //       const date = new DateTime(request.date)
        //       const month = date.getStartOfMonth();
        //       if (!Object.values(DayStatus).includes(request.oldStatus as DayStatus) || !Object.values(DayStatus).includes(request.newStatus as DayStatus)) {
        //         throw new Error();
        //       }
        //       const oldStatus = request.oldStatus as DayStatus;
        //       const newStatus = request.newStatus as DayStatus;
        //       let existingMember = grouped_resp.find(memberInfo => memberInfo.member.id === member.id)
        //       if (existingMember === undefined) {
        //            existingMember = {
        //               member: member,
        //               months: []
        //            }

        //            grouped_resp.push(existingMember)
        //       }
        //       let existingMonth =  existingMember.months.find(monthInfo => monthInfo.month == month)
        //       if (existingMonth === undefined) {
        //           existingMonth = {
        //               month: month,
        //               requests: []
        //           }
        //           existingMember.months.push(existingMonth)
        //       }
        //       const newRequest = {
        //           id: request.id,
        //           date: date,
        //           oldStatus: oldStatus,
        //           newStatus: newStatus

        //       };
        //       existingMonth.requests.push(newRequest);
        //   }
        //   return grouped_resp;
        // };
        const unpackResponce = (resp: RespType): UnpackedRespType => {
          let grouped_resp: UnpackedRespType = [];
          for (let request of resp.pendingRequests) {
            if (!areAuthRoles(request.member.roles)) {
              throw new Error();
            }
            const member = {
              id: request.member.id,
              firstName: request.member.firstName,
              lastName: request.member.lastName,
              roles: request.member.roles as AuthRoles[],
              email: request.member.email,
              created: new DateTime(request.member.created),
              updated: new DateTime(request.member.updated),
              deleted: request.member.deleted ? new DateTime(request.member.deleted) : null,
            };
            const date = new DateTime(request.date);
            const month = date.getStartOfMonth();
            if (
              !Object.values(DayStatus).includes(
                request.oldStatus as DayStatus
              ) ||
              !Object.values(DayStatus).includes(request.newStatus as DayStatus)
            ) {
              throw new Error();
            }
            const oldStatus = request.oldStatus as DayStatus;
            const newStatus = request.newStatus as DayStatus;
            const lowAcceptionProbability = request.lowAcceptionProbability;

            let existingMonth = grouped_resp.find((monthInfo) =>
              monthInfo.month.equalTo(month)
            );
            if (existingMonth === undefined) {
              existingMonth = {
                month: month,
                members: [],
                warning: false
              };
              grouped_resp.push(existingMonth);
            }
            let existingMember = existingMonth.members.find(
              (memberInfo) => memberInfo.member.id === member.id
            );
            if (existingMember === undefined) {
              existingMember = {
                member: member,
                requests: [],
                warning: false
              };

              existingMonth.members.push(existingMember);
            }
            const newRequest = {
              id: request.id,
              date: date,
              oldStatus: oldStatus,
              newStatus: newStatus,
              warning: lowAcceptionProbability
            };
            existingMember.requests.push(newRequest);
            if (newRequest.warning) {
              existingMember.warning = true;
              existingMonth.warning = true;
            }
          }
          grouped_resp = grouped_resp.map(month => ({...month, members: _.sortBy(
            month.members.filter(member => !member.member.deleted), 
            (obj) =>
            obj.member.lastName.toLowerCase().padEnd(150, ' ') + obj.member.firstName.toLowerCase()
          )}))
          return grouped_resp;
        };
        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })(),

      // resolveAllRequests: (() => {
      //   const endpoint = "/admin/members-requests/resolve-all-pending-requests";
      //   const method = RequestMethods.POST;
      //   type DataType = {
      //     grant: boolean;
      //   };
      //   const requestDataSchema = require("./schemas/requestData/account/admin/resolveAllRequests.json");
      //   const validateRequestData = ajv.compile<DataType>(requestDataSchema);
      //   type RespType = {};
      //   return new ServerFetcher<DataType, RespType>(
      //     method,
      //     endpoint,
      //     validateRequestData
      //   );
      // })(),

      // resolveAllMonthRequests: (() => {
      //   const endpoint =
      //     "/admin/members-requests/resolve-all-pending-requests-for-month";
      //   const method = RequestMethods.POST;
      //   type DataType = {
      //     grant: boolean;
      //     date: DateTime;
      //   };
      //   const requestDataSchema = require("./schemas/requestData/account/admin/resolveAllMonthRequests.json");
      //   const validateRequestData = ajv.compile<DataType>(requestDataSchema);
      //   type RespType = {};
      //   return new ServerFetcher<DataType, RespType>(
      //     method,
      //     endpoint,
      //     validateRequestData
      //   );
      // })(),

      resolveRequests: (() => {
        const endpoint =
          "/admin/members-requests/resolve-pending-requests-list";
        const method = RequestMethods.POST;
        type DataType = {
          grant: boolean;
          requests: string[];
        };
        const requestDataSchema = require("./schemas/requestData/account/admin/resolveRequests.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      // > Public Tasks
      publicTasks: (() => {
        const endpoint = "/admin/public-tasks";
        const method = RequestMethods.GET;
        type DataType = {};
        const requestDataSchema = {};
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {
          tasks: {
            id: string;
            name: string;
            category: string;
            isPublic: boolean;
            isDeleted: boolean;
          }[];
        };
        type UnpackedRespType = Task[];
        const responceDataSchema = require("./schemas/responceData/account/admin/publicTasks.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);
        const unpackResponce = (resp: RespType): UnpackedRespType => {
          let tasks = resp.tasks.map(
            (task) =>
              new Task(
                task.id,
                task.name,
                task.category,
                task.isPublic,
                task.isDeleted
              )
          );
          tasks = _.sortBy(tasks, (obj) => obj.category.toLowerCase().padEnd(150, ' ') + obj.name.toLowerCase());
          return tasks;
        };
        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })(),

      createPublicTask: (() => {
        const endpoint = "/admin/public-tasks/create-task";
        const method = RequestMethods.POST;
        type DataType = {
          taskName: string;
          categoryName: string;
        };
        const requestDataSchema = require("./schemas/requestData/account/admin/createPublicTask.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      editPublicTask: (() => {
        const endpoint = "/admin/public-tasks/edit-task";
        const method = RequestMethods.POST;
        type DataType = {
          taskId: string;
          taskName: string;
          categoryName: string;
        };
        const requestDataSchema = require("./schemas/requestData/account/admin/editPublicTask.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      deletePublicTask: (() => {
        const endpoint = "/admin/public-tasks/delete-task";
        const method = RequestMethods.POST;
        type DataType = {
          taskId: string;
        };
        const requestDataSchema = require("./schemas/requestData/account/admin/deletePublicTask.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      editPublicTaskCategory: (() => {
        const endpoint = "/admin/public-tasks/edit-category";
        const method = RequestMethods.POST;
        type DataType = {
          oldCategoryName: string;
          newCategoryName: string;
        };
        const requestDataSchema = require("./schemas/requestData/account/admin/editPublicTaskCategory.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      deletePublicTaskCategory: (() => {
        const endpoint = "/admin/public-tasks/delete-category";
        const method = RequestMethods.POST;
        type DataType = {
          categoryName: string;
        };
        const requestDataSchema = require("./schemas/requestData/account/admin/deletePublicTaskCategory.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      // Users
      users: (() => {
        const endpoint = "/admin/users";
        const method = RequestMethods.GET;
        type DataType = {};
        const requestDataSchema = {};
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {
          users: {
            id: string;
            firstName: string;
            lastName: string;
            roles: string[];
            email: string;
            created: string;
            updated: string;
            deleted: string | null;
          }[];
        };
        type UnpackedRespType = User[];
        const responceDataSchema = require("./schemas/responceData/account/admin/users.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);
        const unpackResponce = (resp: RespType): UnpackedRespType => {
          let users = resp.users.map((user) => {
            if(!areAuthRoles(user.roles)){
              throw new Error();
            }
            return {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            roles: user.roles as AuthRoles[],
            email: user.email,
            created: new DateTime(user.created),
            updated: new DateTime(user.updated),
            deleted: user.deleted ? new DateTime(user.deleted) : null,
          }});
          users = _.sortBy(users, (obj) => obj.lastName.toLowerCase().padEnd(150, ' ') + obj.firstName.toLowerCase());
          return users;
        };
        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })(),

      activeMembers: (() => {
        const endpoint = "/admin/users/active-members";
        const method = RequestMethods.GET;
        type DataType = {};
        const requestDataSchema = {};
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {
          activeMembers: {
            id: string;
            firstName: string;
            lastName: string;
            roles: string[];
            email: string;
            created: string;
            updated: string;
            deleted: string | null;
          }[];
        };
        type UnpackedRespType = User[];
        const responceDataSchema = require("./schemas/responceData/account/admin/activeMembers.json");
        const validateResponceData = ajv.compile<RespType>(responceDataSchema);
        const unpackResponce = (resp: RespType): UnpackedRespType => {
          let members = resp.activeMembers.map((user) => {
            if(!areAuthRoles(user.roles)){
              throw new Error();
            }
            return {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            roles: user.roles as AuthRoles[],
            email: user.email,
            created: new DateTime(user.created),
            updated: new DateTime(user.updated),
            deleted: user.deleted ? new DateTime(user.deleted) : null,
          }});
          members = _.sortBy(members, (obj) => obj.lastName.toLowerCase().padEnd(150, ' ') + obj.firstName.toLowerCase());
          return members;
        };
        return new ServerFetcherUnpacked<DataType, RespType, UnpackedRespType>(
          method,
          endpoint,
          validateRequestData,
          validateResponceData,
          unpackResponce
        );
      })(),

      createUser: (() => {
        const endpoint = "/admin/users/create-user";
        const method = RequestMethods.POST;
        type DataType = {
          firstName: string;
          lastName: string;
          email: string;
          roles: AuthRoles[];
        };
        const requestDataSchema = require("./schemas/requestData/account/admin/createUser.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      editUser: (() => {
        const endpoint = "/admin/users/edit-user";
        const method = RequestMethods.POST;
        type DataType = {
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          roles: AuthRoles[];
        };
        const requestDataSchema = require("./schemas/requestData/account/admin/editUser.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      deleteUser: (() => {
        const endpoint = "/admin/users/delete-user";
        const method = RequestMethods.POST;
        type DataType = {
          id: string;
        };
        const requestDataSchema = require("./schemas/requestData/account/admin/deleteUser.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),

      restoreUser: (() => {
        const endpoint = "/admin/users/restore-user";
        const method = RequestMethods.POST;
        type DataType = {
          id: string;
        };
        const requestDataSchema = require("./schemas/requestData/account/admin/restoreUser.json");
        const validateRequestData = ajv.compile<DataType>(requestDataSchema);
        type RespType = {};
        return new ServerFetcher<DataType, RespType>(
          method,
          endpoint,
          validateRequestData
        );
      })(),
    },
  },
};
