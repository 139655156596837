import { useRef, useState } from "react";
import { CategoryModal, useCategoryModal } from "./useCategoryModal";
import { Task } from "../../../../utils";
import { Category } from "../../../../utils/Task";
// import { useHotkeys } from "../../../../hooks/useHotkeys";
import { useHotkeys } from 'react-hotkeys-hook';

export enum ModalModes {
  TaskCreationModal = "taskCreationModal",
  TaskEditionModal = "taskEditionModal",
  CategoryEditionModal = "categoryEditionModal",
}
export type ModalStatus =
  | {
      show: true;
      mode: ModalModes.TaskCreationModal;
      status: {
        task: Task;
      };
    }
  | {
      show: true;
      mode: ModalModes.TaskEditionModal;
      status: {
        task: Task;
      };
    }
  | {
      show: true;
      mode: ModalModes.CategoryEditionModal;
      status: {
        category: string;
      };
    }
  | {
      show: false;
      mode: null;
      status: null;
    };

export function useModall() {
  const [modalStatus, setModalStatus] = useState<ModalStatus>({
    show: false,
    mode: null,
    status: null,
  });

  const openTaskCreationModal = (category: string | undefined = undefined) => {
    let taskDefault = new Task("", "", category ? category : "");
    setModalStatus({
      show: true,
      mode: ModalModes.TaskCreationModal,
      status: { task: taskDefault },
    });
  };
  const openTaskEditionModal = (task: Task) => {
    setModalStatus({
      show: true,
      mode: ModalModes.TaskEditionModal,
      status: { task: task },
    });
  };
  const openCategoryEditionModal = (category: string) => {
    setModalStatus({
      show: true,
      mode: ModalModes.CategoryEditionModal,
      status: { category: category },
    });
  };

  const closeModal = () => {
    setModalStatus({ show: false, mode: null, status: null });
  };

  // Hotkeys
  useHotkeys('Escape', () => {
    if(modalStatus.show){
      closeModal();
    }
  }, {enableOnFormTags: true}, [modalStatus.show]);
  // useHotkeys([
  //   {
  //     keys: ["Escape"],
  //     callback: ()=>{
  //       if(modalStatus.show){
  //         closeModal()
  //       }
  //     }
  //   }
  // ], [modalStatus.show]);

  return [
    modalStatus,
    openTaskCreationModal,
    openTaskEditionModal,
    openCategoryEditionModal,
    closeModal,
  ] as const;
}
